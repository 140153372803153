<template>
  <div class="scrollContainer font-12" ref="scrollContainer">
    <div class="absolute tp20 left-0 right-0 text-center">
      <div class="font-40">请选择机构登录</div>
      <div class="mt20 font-28 color-666">您的账号存在多个机构</div>
      <div class="opt-line"></div>
    </div>
    <div
      class="absolute bp20 left-0 right-0 pl100 pr100 text-center fn-flex fn-flex-wrap fn-flex-center"
    >
      <router-link
        :to="{
          path:
            '/biz?orgId=' +
            item.orgId +
            '&bizName=' +
            item.bizName +
            '&bizLogo=' +
            item.logo,
        }"
        class="block wp50"
        v-for="(item, index) in list"
        :key="index"
      >
        <img class="inline-block imgsize-210X210 ulib-r750" :src="item.logo" />
      </router-link>
    </div>
  </div>
</template>
<script>
import { hiddenShare } from "../../assets/js/utils/wexinShare.js";
export default {
  data() {
    return {
      list: [],
    };
  },

  created: function () {
    this.getHiddenWxSign();
    this.getOrgLists();
  },

  mounted: function () {},
  methods: {
    //获取微信签名数据
    getHiddenWxSign() {
      let params = {};
      params["url"] = window.location.href;
      this.$get(this.$api.wxSign, params).then((res) => {
        let obj = {
          appId: res.data.signPackage.appid,
          nonceStr: res.data.signPackage.noncestr,
          signature: res.data.signPackage.signature,
          timestamp: res.data.signPackage.timestamp,
        };
        hiddenShare(obj);
      });
    },

    getOrgLists() {
      this.$get(this.$api.bizOrgLists, {}).then((res) => {
        this.list =
          res.data.lists && res.data.lists.length > 0 ? res.data.lists : [];
      });
    },
  },
  // beforeRouteEnter(to, from, next) {
  //   if (from.name == "login") {
  //     to.meta.keepAlive = true;
  //   } else {
  //     to.meta.keepAlive = false;
  //   }
  //   next();
  // },
  // beforeRouteLeave(to, from, next) {
  //   from.meta.keepAlive = true;
  //   next(); //用户离开
  // },
};
</script>
