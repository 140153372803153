<template>
  <div
    class="fixed left-0 bottom-0 right-0 pt15 pb20 text-center font-22 fn-flex bg-size-fullwidth bg-no-repeat bg-pos-top"
    :class="nav.length == 2 ? 'pl90 pr90' : ''"
    :style="'background-image: url(' + bg + ')'"
  >
    <a
      class="fn-flex-item relative"
      @click="pushLink(item.url, item.title)"
      v-for="(item, index) in nav"
      :key="index"
    >
      <div v-if="item.title == '核销'">
        <img
          class="imgsize-80X80 absolute box-center top-0"
          :src="item.icon"
          alt="#"
        />
      </div>
      <div v-else>
        <img
          class="imgsize-50X50"
          :src="NavName == item.name ? item.acicon : item.icon"
          alt="#"
        />
        <div class="space-nowrap con-min60 font-22">{{ item.title }}</div>
      </div>
    </a>
  </div>
</template>

<script>
export default {
  name: "NavBar",
  components: {},
  props: ["name", "orgId", "actId"],
  data() {
    return {
      bg: "",
      userInfo: {},
      nav: [],
      NavName: this.name,
      OrgId: this.orgId,
      ActId: this.actId,
      vnum: 0,
    };
  },
  created() {
    this.userInfo = this.$store.getters.getUserInfo;
    this.setNav();
  },
  methods: {
    setNav() {
      if (this.userInfo.actIsAdmin && this.userInfo.actAdminLevel == 3) {
        this.bg =
          "https://qs.haodian.cn/web/images/project/H5-ShiYu/nav-bg-admin.png";
        this.nav = [
          {
            url: "/stic?orgId=" + this.OrgId + "&actId=" + this.ActId,
            title: "首页",
            name: "stic",
            icon: "https://qs.haodian.cn/web/images/project/H5-ShiYu/icon-home.png",
            acicon:
              "https://qs.haodian.cn/web/images/project/H5-ShiYu/icon-home-ac.png",
          },
          {
            url: "/stic/ranking?orgId=" + this.OrgId + "&actId=" + this.ActId,
            title: "排行",
            name: "ranking",
            icon: "https://qs.haodian.cn/web/images/project/H5-ShiYu/icon-ranking.png",
            acicon:
              "https://qs.haodian.cn/web/images/project/H5-ShiYu/icon-ranking-ac.png",
          },
        ];
      } else if (
        this.userInfo.actIsAdmin &&
        (this.userInfo.actAdminLevel == 1 || this.userInfo.actAdminLevel == 2)
      ) {
        this.bg =
          "https://qs.haodian.cn/web/images/project/H5-ShiYu/nav-bg.png";
        this.nav = [
          {
            url: "/stic?orgId=" + this.OrgId + "&actId=" + this.ActId,
            title: "首页",
            name: "stic",
            icon: "https://qs.haodian.cn/web/images/project/H5-ShiYu/icon-home.png",
            acicon:
              "https://qs.haodian.cn/web/images/project/H5-ShiYu/icon-home-ac.png",
          },
          {
            url: "/customer?orgId=" + this.OrgId + "&actId=" + this.ActId,
            title: "客户",
            name: "customer",
            icon: "https://qs.haodian.cn/web/images/project/H5-ShiYu/icon-kehu.png",
            acicon:
              "https://qs.haodian.cn/web/images/project/H5-ShiYu/icon-kehu-ac.png",
          },
          {
            url: "/order/exchange?orgId=" + this.OrgId + "&actId=" + this.ActId,
            title: "核销",
            name: "exchange",
            icon: "https://qs.haodian.cn/web/images/project/H5-ShiYu/icon-writeOff.png",
            acicon: "",
          },
          {
            url: "/order?orgId=" + this.OrgId + "&actId=" + this.ActId,
            title: "中奖",
            name: "win",
            icon: "https://qs.haodian.cn/web/images/project/H5-ShiYu/icon-winning.png",
            acicon:
              "https://qs.haodian.cn/web/images/project/H5-ShiYu/icon-winning-ac.png",
          },
          {
            url: "/stic/ranking?orgId=" + this.OrgId + "&actId=" + this.ActId,
            title: "排行",
            name: "ranking",
            icon: "https://qs.haodian.cn/web/images/project/H5-ShiYu/icon-ranking.png",
            acicon:
              "https://qs.haodian.cn/web/images/project/H5-ShiYu/icon-ranking-ac.png",
          },
        ];
      }
    },

    //推送链接
    pushLink(url, title) {
      if (title == "首页") {
        this.vnum = this.vnum + 1;
        this.$router.replace(url + "&vnum=" + this.vnum);
        //this.$router.push({ path: url + "&vnum=" + this.vnum });
      } else {
        this.$router.push({ path: url });
      }
    },
  },
  watch: {
    //监听路由地址的改变
    $route: {
      immediate: true,
      handler() {
        if (this.$route.query.vnum) {
          this.vnum = Number(this.$route.query.vnum);
        }
      },
    },
    "$store.state.userInfo": {
      deep: true, //深度监听设置为 true
      handler: function (newVal, oldVal) {
        console.log(newVal, oldVal);
        this.userInfo = newVal;
        this.setNav();
      },
    },
  },
};
</script>
