<template>
  <div class="scrollContainer">
    <link rel="stylesheet" href="https://qs.haodian.cn/web/images/project/H5-ShiYu-draw/pc.css"></link>
    <link rel="stylesheet" href="https://qs.haodian.cn/web/images/project/H5-ShiYu-draw/vant.css"></link>
    <div class="container" v-if="info.screenDisplay == 1">
      <div
        class="absolute box-center mt50 box-1280-720 bg-no-repeat bg-size-fullwidth bg-pos-top"
        :style="bgImg"
      >
        <div
          class="absolute box-center pt3 font-50 text-bold color-fff text-italic"
        >
          {{ topTitle }}
        </div>
        <div class="maincontent ulib-r20 overflowhidden">
          <div class="box-1200-585 relative overflowhidden">
            <div class="absolute box-center-middle" v-if="draw.prizeUrl">
              <img
                :src="draw.prizeUrl"
                style="max-width: 630px;"
                alt="#"
              />
            </div>
            <div
              class="absolute box-center-middle"
              style="width: 500px"
              v-if="winNum == 1"
            >
              <div
                class="relative pb20 text-center"
                v-if="index < winNum"
                v-for="(item, index) in list"
                :key="index"
              >
                <img
                  class="imgsize-140X140 ulib-r750"
                  :src="item.headimg"
                  alt="#"
                />
                <div class="mt20 text-nowrap font-60">{{ item.name }}</div>
                <div class="mt10 font-40 color-666">{{ item.tel }}</div>
              </div>
            </div>
            <div
              class="absolute box-center-middle fn-flex"
              style="width: 900px"
              v-else-if="winNum == 2"
            >
              <div
                class="relative fn-flex-item wp50 text-center overflowhidden"
                v-if="index < winNum"
                v-for="(item, index) in list"
                :key="index"
              >
                <img
                  class="imgsize-140X140 ulib-r750"
                  :src="item.headimg"
                  alt="#"
                />
                <div class="mt20 text-nowrap font-50">{{ item.name }}</div>
                <div class="mt10 font-30 color-666">{{ item.tel }}</div>
              </div>
            </div>
            <div
              class="absolute box-center-middle"
              style="width: 1000px"
              v-else-if="winNum == 5"
            >
              <div class="fn-flex text-center">
                <div
                  class="relative fn-flex-item wp33 text-center overflowhidden"
                  v-if="index < 3"
                  v-for="(item, index) in list"
                  :key="index"
                >
                  <img
                    class="imgsize-90X90 ulib-r750"
                    :src="item.headimg"
                    alt="#"
                  />
                  <div class="mt15 text-nowrap font-30">{{ item.name }}</div>
                  <div class="mt5 font-20 color-666">{{ item.tel }}</div>
                </div>
              </div>
              <div class="mt70 fn-flex pl80 pr80 text-center">
                <div
                  class="relative fn-flex-item wp50 text-center overflowhidden"
                  v-if="index > 2 && index < 5"
                  v-for="(item, index) in list"
                  :key="index"
                >
                  <img
                    class="imgsize-90X90 ulib-r750"
                    :src="item.headimg"
                    alt="#"
                  />
                  <div class="mt15 text-nowrap font-30">{{ item.name }}</div>
                  <div class="mt5 font-20 color-666">{{ item.tel }}</div>
                </div>
              </div>
            </div>
            <div
              class="absolute box-center-middle"
              style="width: 50%"
              v-else-if="winNum == 10"
            >
              <div class="fn-flex fn-flex-wrap">
                <div
                  class="wp50 relative"
                  style="height: 100px"
                  v-if="index < winNum"
                  v-for="(item, index) in list"
                  :key="index"
                >
                  <img
                    class="absolute box-middle left-0 ml50 imgsize-60X60 ulib-r750"
                    :src="item.headimg"
                    alt="#"
                  />
                  <div class="absolute box-middle left-0 right-0 pl130">
                    <div class="text-nowrap font-18">{{ item.name }}</div>
                    <div class="font-14 color-666">{{ item.tel }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="absolute box-center-middle"
              style="width: 100%"
              v-else-if="winNum == 20"
            >
              <div class="fn-flex fn-flex-wrap">
                <div
                  class="wp25 relative"
                  style="height: 100px"
                  v-if="index < winNum"
                  v-for="(item, index) in list"
                  :key="index"
                >
                  <img
                    class="absolute box-middle left-0 ml50 imgsize-60X60 ulib-r750"
                    :src="item.headimg"
                    alt="#"
                  />
                  <div class="absolute box-middle left-0 right-0 pl130">
                    <div class="text-nowrap font-18">{{ item.name }}</div>
                    <div class="font-14 color-666">{{ item.tel }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="absolute box-center-middle"
              style="width: 100%"
              v-else-if="winNum == 30"
            >
              <div class="fn-flex fn-flex-wrap">
                <div
                  class="wp20 relative"
                  style="height: 90px"
                  v-if="index < winNum"
                  v-for="(item, index) in list"
                  :key="index"
                >
                  <img
                    class="absolute box-middle left-0 ml50 imgsize-60X60 ulib-r750"
                    :src="item.headimg"
                    alt="#"
                  />
                  <div class="absolute box-middle left-0 right-0 pl130">
                    <div class="text-nowrap font-18">{{ item.name }}</div>
                    <div class="font-14 color-666">{{ item.tel }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="absolute box-center-middle"
              style="width: 100%"
              v-else-if="winNum == 40"
            >
              <div class="fn-flex fn-flex-wrap">
                <div
                  class="wp20 relative"
                  style="height: 70px"
                  v-if="index < winNum"
                  v-for="(item, index) in list"
                  :key="index"
                >
                  <img
                    class="absolute box-middle left-0 ml50 imgsize-45X45 ulib-r750"
                    :src="item.headimg"
                    alt="#"
                  />
                  <div class="absolute box-middle left-0 right-0 pl110">
                    <div class="text-nowrap font-16">{{ item.name }}</div>
                    <div class="font-12 color-666">{{ item.tel }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="fn-flex fn-flex-wrap roll-50-1" v-else>
              <div
                class="wp20 relative"
                style="height: 56px"
                v-if="index < winNum"
                v-for="(item, index) in list"
                :key="index"
              >
                <img
                  class="absolute box-middle left-0 ml50 imgsize-36X36 ulib-r750"
                  :src="item.headimg"
                  alt="#"
                />
                <div class="absolute box-middle left-0 right-0 pl95">
                  <div class="text-nowrap font-14">{{ item.name }}</div>
                  <div class="font-12 color-666">{{ item.tel }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="fixed left-0 bottom-0 right-0 bg-1a1a1a pt40 pb40 text-center fn-clear"
      >
        <select
          id="win_type_id"
          class="fn-fl text-middle ml40 select-more pl20 pr20 ulib-r5 font-18"
          style="height: 50px"
          @change="changeTitle"
        >
          <option
            :value="item.value"
            v-for="(item, index) in info.winTypeAry"
            :key="index"
          >
            {{ item.title }}
          </option>
        </select>
        <span class="fn-fl text-middle ml10 mt10 font-18 color-fff"
          >已中奖{{ winMsgTip }}人</span
        >
        <span v-if="!isStartAc">
          <span class="text-middle font-18 color-fff">中奖人数：</span>
          <select
            class="text-middle mr10 select-more pl20 pr20 ulib-r5 font-18"
            style="height: 50px"
            @change="changeType"
          >
            <option
              :value="item.value"
              v-for="(item, index) in info.winNumAry"
              :key="index"
            >
              {{ item.title }}
            </option>
          </select>
          <a
            class="text-middle inline-block bg-1282f4 ml10 mr10 pt10 pb10 pl20 pr20 text-center font-18 color-fff ulib-r5"
            v-if="draw.list.length > 0"
            href="javascript:;"
            @click="start"
            >开始抽奖</a
          >
          <a
            class="text-middle inline-block bg-1282f4 ml10 mr10 pt10 pb10 pl20 pr20 text-center font-18 color-fff ulib-r5"
            href="javascript:;"
            @click="postDrawInfo"
            >{{ draw.list.length > 0 ? "更新" : "获取" }}抽奖名单</a
          >
        </span>
        <span v-else>
          <a
            class="text-middle inline-block bg-1282f4 ml10 mr10 pt10 pb10 pl20 pr20 text-center font-18 color-fff ulib-r5"
            href="javascript:;"
            @click="postDrawWin"
            >立即开奖</a
          >
          <a
            v-if="!isShowReset"
            class="text-middle inline-block bg-aaa ml10 mr10 pt10 pb10 pl20 pr20 text-center font-18 color-fff ulib-r5"
            href="javascript:;"
            @click="isShowReset = true"
            >放弃开奖</a
          >
          <a
            v-else
            class="text-middle inline-block bg-aaa ml10 mr10 pt10 pb10 pl20 pr20 text-center font-18 color-fff ulib-r5"
            href="javascript:;"
            @click="reset"
            >确认放弃开奖</a
          >
        </span>
        <div class="absolute left-0 right-0 top-0 pt10 font-14 color-eb5359">
          {{ msgTip }}
        </div>
      </div>
      <!--隐藏加载数据-->
      <div
        class="absolute left-0 bottom-0 op0 overflowhidden"
        style="width: 50px; height: 50px"
      >
        <div v-for="item in draw.list" :key="item.headimg">
          <img style="width: 1px; height: 1px" :src="item.headimg" alt="#" />
        </div>
      </div>
    </div>
    <div class="container" v-else-if="info.screenDisplay == 2">
      <div
        class="absolute box-center mt20 box-450-800 bg-no-repeat bg-size-fullwidth bg-pos-top"
        :style="bgImg"
      >
        <div
          class="absolute box-center font-28 text-bold space-nowrap color-fa000e"
          style="top: 250px"
        >
          {{ topTitle }}
        </div>
        <div class="maincontent-330-420 ulib-r20 overflowhidden">
          <div class="box-330-380 relative overflowhidden">
            <div
              class="absolute top-0 left-0 right-0 pt40 text-center"
              v-if="draw.prizeUrl"
            >
              <img :src="draw.prizeUrl" style="max-width: 220px" alt="#" />
            </div>
            <div
              class="absolute box-center-middle"
              style="width: 100%"
              v-if="winNum == 1"
            >
              <div
                class="relative pb20 text-center"
                v-if="index < winNum"
                v-for="(item, index) in list"
                :key="index"
              >
                <img
                  class="imgsize-100X100 ulib-r750"
                  :src="item.headimg"
                  alt="#"
                />
                <div class="mt10 text-nowrap font-48">{{ item.name }}</div>
                <div class="mt5 font-30 color-666">{{ item.tel }}</div>
              </div>
            </div>
            <div
              class="absolute box-center-middle"
              style="width: 100%"
              v-else-if="winNum == 10"
            >
              <div class="fn-flex fn-flex-wrap">
                <div
                  class="wp40 relative"
                  style="height: 70px"
                  v-if="index < winNum"
                  v-for="(item, index) in list"
                  :key="index"
                >
                  <img
                    class="absolute box-middle left-0 ml45 imgsize-34X34 ulib-r750"
                    :src="item.headimg"
                    alt="#"
                  />
                  <div
                    class="absolute box-middle left-0 right-0 pl85 line-height-15"
                  >
                    <div class="text-nowrap font-14">{{ item.name }}</div>
                    <div class="font-12 color-666">{{ item.tel }}</div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="absolute box-center-middle"
              style="width: 100%"
              v-if="winNum == 5"
            >
              <div class="fn-flex pt30 fn-flex-wrap">
                <div
                  class="wp100 relative overflowhidden"
                  style="height: 70px"
                  v-if="index < winNum"
                  v-for="(item, index) in list"
                  :key="index"
                >
                  <img
                    class="absolute box-middle left-0 ml110 imgsize-40X40 ulib-r750"
                    :src="item.headimg"
                    alt="#"
                  />
                  <div
                    class="absolute box-middle left-0 right-0 ml30 pl130 line-height-15"
                  >
                    <div class="text-nowrap font-14">{{ item.name }}</div>
                    <div class="font-12 color-666">{{ item.tel }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="fixed left-0 bottom-0 right-0 bg-1a1a1a pt30 pb15 text-center fn-clear"
      >
        <select
          id="win_type_id"
          class="fn-fl text-middle ml40 select-more pl20 pr20 ulib-r5 font-18"
          style="height: 50px"
          @change="changeTitle"
        >
          <option
            :value="item.value"
            v-for="(item, index) in info.winTypeAry"
            :key="index"
          >
            {{ item.title }}
          </option>
        </select>
        <span class="fn-fl text-middle ml10 mt10 font-18 color-fff"
          >已中奖{{ winMsgTip }}人</span
        >
        <span v-if="!isStartAc">
          <span class="text-middle font-18 color-fff">中奖人数：</span>
          <select
            class="text-middle mr10 select-more pl20 pr20 ulib-r5 font-18"
            style="height: 50px"
            @change="changeType"
          >
            <option
              :value="item.value"
              v-for="item in info.winNumAry"
              :key="item.title"
            >
              {{ item.title }}
            </option>
          </select>
          <a
            class="text-middle inline-block bg-1282f4 ml10 mr10 pt10 pb10 pl20 pr20 text-center font-18 color-fff ulib-r5"
            v-if="draw.list.length > 0"
            href="javascript:;"
            @click="start"
            >开始抽奖</a
          >
          <a
            class="text-middle inline-block bg-1282f4 ml10 mr10 pt10 pb10 pl20 pr20 text-center font-18 color-fff ulib-r5"
            href="javascript:;"
            @click="postDrawInfo"
            >{{ draw.list.length > 0 ? "更新" : "获取" }}抽奖名单</a
          >
        </span>
        <span v-else>
          <a
            class="text-middle inline-block bg-1282f4 ml10 mr10 pt10 pb10 pl20 pr20 text-center font-18 color-fff ulib-r5"
            href="javascript:;"
            @click="postDrawWin"
            >立即开奖</a
          >
          <a
            v-if="!isShowReset"
            class="text-middle inline-block bg-aaa ml10 mr10 pt10 pb10 pl20 pr20 text-center font-18 color-fff ulib-r5"
            href="javascript:;"
            @click="isShowReset = true"
            >放弃开奖</a
          >
          <a
            v-else
            class="text-middle inline-block bg-aaa ml10 mr10 pt10 pb10 pl20 pr20 text-center font-18 color-fff ulib-r5"
            href="javascript:;"
            @click="reset"
            >确认放弃开奖</a
          >
        </span>
        <div class="absolute left-0 right-0 top-0 pt5 font-14 color-eb5359">
          {{ msgTip }}
        </div>
      </div>
      <!--隐藏加载数据-->
      <div
        class="absolute left-0 bottom-0 op0 overflowhidden"
        style="width: 50px; height: 50px"
      >
        <div v-for="item in draw.list" :key="item.headimg">
          <img style="width: 1px; height: 1px" :src="item.headimg" alt="#" />
        </div>
      </div>
    </div>
    <div
      class="msg fn-hide"
      :style="isShowLogin ? 'display:block' : 'display:none'"
      v-if="isShowLogin"
    >
      <div class="msgBg"></div>
      <div class="msgMain">
        <div class="content">
          <div class="word text-center">
            <div class="relative bg-f6 ulib-r10">
              <input
                class="wp100 inner10 font-28 bg-f6 bds-1-ccc ulib-r10"
                type="text"
                v-model="drawCode"
                placeholder="请输入抽奖码"
              />
            </div>
          </div>
          <div class="mt30">
            <a
              class="block pt10 pb10 bg-1a1a1a text-center font-20 color-fff ulib-r10"
              href="javascript:;"
              @click="postDrawCode"
              >确认</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from "jquery";
import axios from 'axios';
import Config from '../../config.js';
export default {
  data() {
    return {
      title: "私域直播抽奖",
      bgImg: "",
      submitFlag: false,
      topTitle: "",
      winNum: 0,
      winType: 0,
      winMsgTip: 0,
      activityId: 0,
      info: {
        winNumAry: [],
        winTypeAry: [],
        screenDisplay: 1,
      },
      draw: {
        list: [],
        result: [],
        type: 0,
        prizeUrl: "",
      },
      list: [],
      timer: null,
      isStartAc: false,
      isOpen: false,
      isShowLogin: true,
      isShowReset: false,
      drawCode: "",
      msgTip: "",
    };
  },
  created() {
    $("style").remove();
  },
  mounted() {},
  beforeDestroy() {
    this.list = [];
    clearTimeout(this.timer);
  },
  methods: {
    postDrawCode() {
      let that = this;
      if (that.submitFlag) return;
      if (!that.drawCode) {
        that.$toast({
          message: "请输入抽奖码",
          duration: 1500,
        });
        return;
      }
      that.submitFlag = true
      let params = {};
      params["drawCode"] = that.drawCode;
      //that.$post(that.$api.drawCode, params).then(() => {
      axios.post(Config.baseURL+that.$api.drawCode, params).then((res) => {
        that.submitFlag = false;
        that.$toast({
          message: res.data.msg,
          duration: 1500,
        });
        if (res.data.code == 200) {
          window.document.title = res.data.data.title;
          that.bgImg = res.data.data.bgImg;
          that.isShowLogin = false;
          that.activityId = res.data.data.activityId;
          that.info = res.data.data.info;
        }
      })
      .catch(() => {
        that.submitFlag = false;
      });
    },
    //更新抽奖名单
    postDrawInfo() {
      let that = this;
      if (that.submitFlag) return;
      if (!that.winType) {
        that.msgTip = "请选择抽奖类型";
        return;
      }
      if (!that.winNum) {
        that.msgTip = "请选择中奖人数";
        return;
      }
      if (that.isOpen) return;
      clearTimeout(that.timer);
      that.msgTip = "";
      that.isShowReset = false;
      that.draw = { list: [], result: [], type: 0, prizeUrl: "" };
      that.list = [];

      that.submitFlag = true;
      let params = {};
      params["activityId"] = Number(that.activityId);
      params["winType"] = Number(that.winType);
      params["winNum"] = Number(that.winNum);
      axios.post(Config.baseURL+that.$api.drawInfo, params).then((res) => {
        that.submitFlag = false;
        if (res.data.code == 200) {
          that.msgTip = res.data.msg;
          that.topTitle = $("#win_type_id").find("option:selected").text();
          that.draw = {
            list: res.data.data.list,
            result: res.data.data.result,
            type: res.data.data.type,
            prizeUrl: res.data.data.prizeUrl
          };
        } else {
          that.msgTip = res.data.msg;
        }
      })
      .catch((re) => {
        that.msgTip = re.data.msg
        that.submitFlag = false;
      });
    },
    //中奖人数
    postDrawWin_num() {
      let that = this;
      if (that.submitFlag) return;
      if (!that.winType || !that.activityId) return;

      that.submitFlag = true;
      let params = {};
      params["activityId"] = Number(that.activityId);
      params["winType"] = Number(that.winType);
      that
        .$post(that.$api.drawWin_num, params)
        .then((res) => {
          that.submitFlag = false;
          that.winMsgTip = res.data.winMsgTip;
        })
        .catch(() => {
          that.submitFlag = false;
        });
    },
    //选择中奖类型
    changeTitle(e) {
      if (this.winType != e.target.value) {
        this.winType = e.target.value;
        this.draw = { list: [], result: [], type: 0, prizeUrl: "" };
        this.list = [];
        this.msgTip = "";
        this.postDrawWin_num();
      }
    },
    //选择中奖人数
    changeType(e) {
      this.isOpen = false;
      if (this.winNum != e.target.value) {
        this.winNum = e.target.value;
        this.draw = { list: [], result: [], type: 0, prizeUrl: "" };
        this.list = [];
        this.msgTip = "";
      }
    },
    //开始抽奖
    start() {
      this.msgTip = "";
      this.draw.prizeUrl = "";
      this.isStartAc = true;
      this.timer = setInterval(() => {
        //随机排序
        let arr = this.draw.list.slice(0);
        arr.sort(function () {
          return Math.random() - 0.5;
        });
        this.list = arr;
      }, 20);
    },
    //立即开奖
    postDrawWin() {
      let that = this;
      if (that.submitFlag) return;
      clearTimeout(that.timer);
      that.list = that.draw.result;
      that.isStartAc = false;
      that.isOpen = true;

      that.submitFlag = true;
      let params = {};
      params["activityId"] = Number(that.activityId);
      params["type"] = Number(that.draw.type);
      params["result"] = that.draw.result;
      axios.post(Config.baseURL+that.$api.drawWin, params).then((res) => {
          that.submitFlag = false;
          that.msgTip = res.data.msg;
          that.winMsgTip = res.data.data.winMsgTip;
          that.list = res.data.data.result;
          that.draw = { list: [], result: [], type: 0, prizeUrl: "" };
        })
        .catch((re) => {
          that.msgTip = re.data.msg
          that.submitFlag = false;
        });
    },
    //放弃开奖
    reset() {
      //提交中奖数据
      clearTimeout(this.timer);
      this.list = [];
      this.draw = { list: [], result: [], type: 0, prizeUrl: "" };
      this.isStartAc = false;
      this.isOpen = true;
      this.winNum = 0;
    },
  },
};
</script>
