<template>
  <div
    class="scrollContainer font-12"
    id="scrollContainer"
    ref="scrollContainer"
  >
    <div
      class="container relative bg-no-repeat bg-size-fullwidth bg-pos-top pt30 pb50"
    >
      <div
        class="relative bg-fff ml30 mr30 inner30 ulib-r20 box-shadow-darkGray"
        style="min-height: 90vh"
      >
        <div class="pt10 pb20 text-center">
          <b class="font-40" v-html="title"></b>
        </div>
        <div v-if="bizId">
          <div v-if="tabFixed">
            <div class="height-60"></div>
            <div
              class="fixed top-0 left-0 right-0 z-index-10 bg-fff pl30 pr30 pt20 pb20"
            >
              <div class="relative height-70 ml50 mr50 fn-flex text-center">
                <div
                  class="fn-flex-item relative tab-menu2 pt10 pb10"
                  :class="[tabid == item.id ? 'font-36 active' : 'font-28']"
                  v-for="(item, index) in tab"
                  :key="index"
                >
                  <a
                    class="relative inline-block"
                    :class="[tabid == item.id ? '' : 'pt10']"
                    @click="changeTab(item.id)"
                    href="javascript:"
                    >{{ item.title }}</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div class="relative height-70 ml50 mr50 fn-flex text-center">
              <div
                class="fn-flex-item relative tab-menu2 pt10 pb10"
                :class="[tabid == item.id ? 'font-36 active' : 'font-28']"
                v-for="(item, index) in tab"
                :key="index"
              >
                <a
                  class="relative inline-block"
                  :class="[tabid == item.id ? '' : 'pt10']"
                  @click="changeTab(item.id)"
                  href="javascript:"
                  >{{ item.title }}</a
                >
              </div>
            </div>
          </div>
        </div>

        <div class="fn-flex pt30">
          <select
            class="select-more box-shadow-lightGray ulib-r10 font-22 ml20"
            @change="handleChange($event, 'goods')"
            v-if="bizId && kpi == 'order'"
          >
            <option
              :value="index"
              v-for="(item, index) in goodsList"
              :key="index"
              :selected="goodsIndex == index"
            >
              {{ item.title }}
            </option>
          </select>
        </div>

        <div v-if="tabid == 2">
          <div
            class="pt30 pb20 fn-flex font-28 color-666"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="fn-flex-item text-nowrap" flexsize="3">
              <img
                class="imgsize-32X32 text-middle ulib-r750 bds-1-eee"
                :src="item.headImg"
                alt="#"
              />
              <span class="text-middle">{{ item.title }}</span>
            </div>
            <div class="fn-flex-item text-right" flexsize="5">
              {{ item.num }}
            </div>
          </div>
        </div>
        <div v-else-if="kpi == 'order'">
          <div v-for="(item, index) in list" :key="index">
            <div class="pt30 pb20 fn-flex font-28 color-666">
              <div class="fn-flex-item text-nowrap" flexsize="3">
                <img
                  class="imgsize-32X32 text-middle ulib-r750 bds-1-eee"
                  :src="item.headImg"
                  alt="#"
                />
                <span class="text-middle">{{ item.nickname }}</span>
              </div>
              <div class="fn-flex-item text-right" flexsize="5">
                {{ item.cfUname }}
              </div>
            </div>
            <div class="pb20 fn-flex font-28 color-666">
              <div class="fn-flex-item text-nowrap" flexsize="5">
                {{ item.name }} {{ item.mobile }}
              </div>
              <div class="fn-flex-item text-right" flexsize="3">
                {{ item.time2 }}
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div
            class="pt30 pb20 fn-flex font-28 color-666"
            v-for="(item, index) in list"
            :key="index"
          >
            <div class="fn-flex-item text-nowrap" flexsize="3">
              <img
                class="imgsize-32X32 text-middle ulib-r750 bds-1-eee"
                :src="item.headImg"
                alt="#"
              />
              <span class="text-middle">{{ item.nickname }}</span>
            </div>
            <div class="fn-flex-item text-right" flexsize="5">
              {{ item.cfUname }} {{ item.time2 }}
            </div>
          </div>
        </div>
        <mugenscroll
          :handler="fetchData"
          :should-handle="!loading"
          scroll-container="scrollContainer"
        >
          <div class="pt100 pb100 text-center color-ccc" v-if="isNoData">
            <span class="text-middle font-22">暂无数据</span>
          </div>
          <div class="pt20 pb20 text-center color-ccc" v-else-if="loading">
            <i class="iconfont icon-jiazai text-middle"></i
            ><span class="text-middle font-22">请稍等...</span>
          </div>
          <div
            class="pt20 pb20 text-center font-22 color-ccc"
            v-else-if="isDataEnd && list.length > 10"
          >
            我们是有底线的
          </div>
        </mugenscroll>
      </div>
    </div>
    <backHome></backHome>
  </div>
</template>
<script>
import backHome from "../../components/backHome";
import mugenscroll from "vue-mugen-scroll";
import { hiddenShare } from "../../assets/js/utils/wexinShare.js";
import $ from "jquery";
export default {
  components: { mugenscroll, backHome },
  data() {
    return {
      title: this.$route.query.title,
      orgId: this.$route.query.orgId,
      actId: this.$route.query.actId,
      kpi: this.$route.query.kpi,
      type: this.$route.query.type ? this.$route.query.type : "",
      bizId: this.$route.query.bizId ? this.$route.query.bizId : "",
      itemId: "",

      loading: false,
      isDataEnd: false,
      isNoData: false,
      page: 1,
      size: 20,
      list: [],

      goodsList: [],
      goodsIndex: 0,

      tabFixed: false,
      tab: [
        {
          id: "1",
          title: "客户列表",
        },
        {
          id: "2",
          title: "顾问数据",
        },
      ],
      tabid: "1", //1客户列表  /2顾问数据
    };
  },

  created: function () {
    this.getHiddenWxSign();
    this.getKpiLists();
    if (this.kpi == "order") {
      this.getItemLists();
    }
  },

  mounted: function () {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    //获取微信签名数据
    getHiddenWxSign() {
      let params = {};
      params["url"] = window.location.href;
      this.$get(this.$api.wxSign, params).then((res) => {
        let obj = {
          appId: res.data.signPackage.appid,
          nonceStr: res.data.signPackage.noncestr,
          signature: res.data.signPackage.signature,
          timestamp: res.data.signPackage.timestamp,
        };
        hiddenShare(obj);
      });
    },

    //拉取数据
    fetchData: function () {
      this.getKpiLists();
    },

    //判断导航是否需要吸顶
    handleScroll() {
      let scrollTop = document.querySelector("#scrollContainer").scrollTop;
      let mainoffsetTop = document.querySelector("#main").offsetTop;
      if (scrollTop > mainoffsetTop) {
        this.tabFixed = true;
      } else {
        this.tabFixed = false;
      }
    },

    //获取商品列表
    getItemLists() {
      let params = {};
      params["actId"] = this.actId;
      params["page"] = 1;
      params["size"] = 100;
      this.$get(this.$api.itemLists, params).then((res) => {
        let goodsList = [
          {
            itemId: "",
            title: "全部",
          },
        ];
        this.goodsList =
          res.data.lists && res.data.lists.length > 0
            ? goodsList.concat(res.data.lists)
            : goodsList;
      });
    },

    //获取kpi数据列表
    getKpiLists() {
      let that = this;
      if (!that.isNoData && !that.isDataEnd && !that.loading) {
        that.loading = true;
        let params = {};
        params["orgId"] = that.orgId;
        params["actId"] = that.actId;
        params["kpi"] = that.kpi;
        if (that.type) {
          params["type"] = that.type;
        }
        if (that.bizId) {
          params["bizId"] = that.bizId;
        }
        if (that.itemId) {
          params["itemId"] = that.itemId;
        }
        params["page"] = that.page;
        params["size"] = that.size;
        that.$get(that.$api.bizKpiLists, params).then((res) => {
          that.loading = false;
          that.page = that.page + 1;

          let list = res.data.lists;
          if (list && list.length > 0) {
            list.forEach((item) => {
              let date = new Date(item.time);
              let year = date.getFullYear();
              let month = date.getMonth() + 1;
              if (month < 10) {
                month = "0" + month;
              }
              let date1 = date.getDate();
              if (date1 < 10) {
                date1 = "0" + date1;
              }
              let hour = date.getHours();
              if (hour < 10) {
                hour = "0" + hour;
              }
              let minutes = date.getMinutes();
              if (minutes < 10) {
                minutes = "0" + minutes;
              }
              item.time2 =
                year + "." + month + "." + date1 + " " + hour + ":" + minutes;
            });
            that.list = that.list.concat(list);
          }
          if (res.data.total == 0) {
            that.isNoData = true;
          } else if (that.list.length == res.data.total) {
            that.isDataEnd = true;
          }
        });
      }
    },

    //获取统计排行榜
    getStaticLists() {
      let that = this;
      if (!that.isNoData && !that.isDataEnd && !that.loading) {
        that.loading = true;
        let params = {};
        params["orgId"] = that.orgId;
        params["actId"] = that.actId;
        params["kpi"] = that.kpi;
        if (that.type) {
          params["type"] = that.type;
        }
        if (that.bizId) {
          params["bizId"] = that.bizId;
        }
        if (that.itemId) {
          params["itemId"] = that.itemId;
        }
        params["page"] = that.page;
        params["size"] = that.size;
        that.$get(that.$api.bizStaticLists, params).then((res) => {
          that.loading = false;
          that.page = that.page + 1;
          that.list =
            res.data.lists && res.data.lists.length > 0
              ? that.list.concat(res.data.lists)
              : that.list;
          if (res.data.total == 0) {
            that.isNoData = true;
          } else if (that.list.length == res.data.total) {
            that.isDataEnd = true;
          }
        });
      }
    },

    //tab切换
    changeTab(id) {
      if (id != this.tabid && !this.loading) {
        if (this.tabFixed) {
          let mainoffsetTop = document.querySelector("#main").offsetTop;
          $(".scrollContainer").animate({ scrollTop: mainoffsetTop + 5 }, 500);
        }
        this.tabid = id;
        this.loading = false;
        this.isDataEnd = false;
        this.isNoData = false;
        this.page = 1;
        this.list = [];
        if (this.tabid == 1) {
          this.getKpiLists();
        } else if (this.tabid == 2) {
          this.getStaticLists();
        }
      }
    },

    //选择条件
    handleChange(e, key) {
      switch (key) {
        case "goods":
          if (this.goodsIndex != e.target.value) {
            this.goodsIndex = e.target.value;
            this.itemId = this.goodsList[e.target.value].itemId;
            this.loading = false;
            this.isDataEnd = false;
            this.isNoData = false;
            this.page = 1;
            this.list = [];
            if (this.tabid == 1) {
              this.getKpiLists();
            } else if (this.tabid == 2) {
              this.getStaticLists();
            }
          }
          break;
      }
    },
  },
};
</script>
