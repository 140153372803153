<template>
  <div class="scrollContainer font-12" ref="scrollContainer">
    <div
      class="container relative pb130 wp100 overflowhidden"
      v-if="info.title"
    >
      <!--轮播-->
      <div
        class="goods-banner relative imgsize-750X680 z-index-0"
        v-if="info.banner && info.banner.length > 1"
      >
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="item in info.banner" :key="item">
              <img class="imgsize-750X680" :src="item" alt="#" />
            </div>
          </div>
        </div>
        <div class="swiper-pagination ulib-rl750 font-22 color-fff"></div>
      </div>
      <div v-else>
        <img class="imgsize-750X680" :src="info.banner[0]" alt="#" />
      </div>
      <!--end 轮播-->
      <div class="relative pb30 z-index-1 goods-con">
        <div
          class="relative bg-fff mb30 ml30 mr30 inner40 pr220 ulib-r20 box-shadow-darkGray"
        >
          <div class="font-32 line-height-13">{{ info.title }}</div>
          <div class="mt10 font-22 color-999">{{ info.validity }}</div>
          <div class="absolute right-0 box-middle mr30 color-c4302c">
            <template v-if="info.price > 0">
              <span class="font-24">¥</span>
              <span class="font-48 text-bold">{{ info.price }}</span>
            </template>
            <template v-else>
              <span class="font-48 text-bold">免费</span>
            </template>
          </div>
        </div>
        <div
          class="relative bg-fff mb30 ml30 mr30 inner40 ulib-r20 box-shadow-darkGray"
        >
          <div class="pb20 text-center">
            <div class="inline-block relative pl10 pr10 introTitle font-36">
              <span class="relative z-index-1">权益说明</span>
            </div>
          </div>
          <div
            class="line-height-17 font-28 color-666 text-break space-pre-line rich-text-img"
            v-html="info.content"
          ></div>
        </div>
      </div>

      <div class="fixed left-0 bottom-0 right-0 inner30 bg-fff z-index-2">
        <template v-if="info.sTime > 0">
          <a
            class="block bg-999 pt20 pb20 text-center color-fff ulib-r10"
            href="javascript:void(0)"
          >
            <div class="font-32" v-if="info.price > 0">
              立即支付<span>{{ info.price }}</span
              >元
            </div>
            <div class="font-32" v-else>立即领取</div>
            <div class="mt10 font-20 color-fff" v-html="showTime"></div>
          </a>
        </template>
        <template v-else-if="info.eTime > 0">
          <template v-if="info.itemStatus == 0">
            <a
              class="block bg-1a1a1a pt20 pb20 text-center color-fff ulib-r10"
              v-if="info.stock > 0"
              @click="showReg()"
              href="javascript:void(0)"
            >
              <div class="font-32" v-if="info.price > 0">
                立即支付<span>{{ info.price }}</span
                >元
              </div>
              <div class="font-32" v-else>立即领取</div>
              <div class="mt10 font-20 color-888" v-html="showTime"></div>
            </a>
            <div
              class="bg-999 pt30 pb30 text-center font-32 color-fff ulib-r10"
              v-else
            >
              已售罄
            </div>
          </template>
          <template v-else>
            <div
              class="bg-999 pt30 pb30 text-center font-32 color-fff ulib-r10"
            >
              商品已下架
            </div>
          </template>
        </template>
        <div
          class="bg-999 pt30 pb30 text-center font-32 color-fff ulib-r10"
          v-else
        >
          活动已结束
        </div>
      </div>
    </div>

    <div
      class="msg fn-hide"
      :style="isShowReg ? 'display:block' : 'display:none'"
      v-if="isShowReg"
    >
      <div class="msgBg" @click="closeReg"></div>
      <div class="msgMain">
        <div class="content">
          <div class="word">
            <div class="relative bg-f6 ulib-r10">
              <input
                class="wp100 inner30 font-30 bg-f6 border-none ulib-r10"
                type="text"
                v-model="name"
                placeholder="请输入您的姓名"
              />
            </div>
            <div class="mt30 relative bg-f6 ulib-r10">
              <input
                class="wp100 inner30 font-30 bg-f6 border-none ulib-r10"
                type="tel"
                oninput="if(value.length>11)value=value.slice(0,11)"
                v-model="telPhone"
                placeholder="请输入手机号"
              />
            </div>
            <div class="mt30 relative bg-f6 ulib-r10 pr200">
              <div>
                <input
                  class="wp100 inner30 font-30 bg-f6 border-none ulib-r10"
                  type="number"
                  oninput="if(value.length>4)value=value.slice(0,4)"
                  v-model="vCode"
                  placeholder="请输入验证码"
                />
              </div>
              <a
                :class="[
                  codeState ? 'color-333' : 'color-999',
                  'absolute right-0 mr20 box-middle font-30',
                ]"
                @click="getSmsItem()"
                >{{ codeTx }}</a
              >
            </div>
            <template v-if="info.ifAddress > 0">
              <div class="mt30 relative bg-f6 ulib-r10">
                <input
                  id="region"
                  class="wp100 inner30 font-28 bg-f6 border-none ulib-r10"
                  type="text"
                  readonly=""
                  v-model="region"
                  placeholder="选择省/市/区"
                />
                <input id="regionvalue" type="hidden" v-model="regionvalue" />
              </div>
              <div class="mt30 relative bg-f6 ulib-r10">
                <input
                  class="wp100 inner30 font-28 bg-f6 border-none ulib-r10"
                  type="text"
                  v-model="address"
                  placeholder="请输入详细地址"
                />
              </div>
            </template>
            <div class="mt30 pl5 font-26 color-666">
              <label class="text-middle"
                ><input
                  class="text-middle"
                  type="checkbox"
                  v-model="isXYChecked"
                />
                <span class="text-middle ml5">我已阅读并同意</span>
              </label>
              <router-link
                class="text-middle color-ff0000"
                :to="{
                  path: '/item/agreement?skey=' + skey,
                }"
                >《{{ info.protocolTitle }}》
              </router-link>
            </div>
          </div>
          <div class="mt30">
            <a
              class="block pt25 pb25 bg-1a1a1a text-center font-32 color-fff ulib-r10"
              @click="toPay()"
              >确定支付</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Swiper from "swiper";
import { wexinShare } from "../../assets/js/utils/wexinShare.js";
import Config from "../../config.js";
import $ from "jquery";
var Timer;
export default {
  data() {
    return {
      skey: this.$route.query.skey,
      toPath: "", //授权用户信息返回时使用
      info: {},
      bundleIntervalEvent: "", //倒计时
      isShowReg: false,
      name: "",
      telPhone: "",
      isSubmiting: false,
      vCode: "",
      codeState: true,
      codeTx: "立即获取",
      isXYChecked: false,
      region: "",
      regionvalue: "",
      address: "",
    };
  },

  created() {
    this.getUserInfo();

    let LArea1 = document.createElement("script");
    LArea1.type = "text/javascript";
    LArea1.src = "https://qs.haodian.cn/web/javascript/LArea/js/LAreaData1.js";
    document.getElementsByTagName("head")[0].appendChild(LArea1);
    let LArea2 = document.createElement("script");
    LArea2.type = "text/javascript";
    LArea2.src = "https://qs.haodian.cn/web/javascript/LArea/js/LAreaData2.js";
    document.getElementsByTagName("head")[0].appendChild(LArea2);
    let LArea = document.createElement("script");
    LArea.type = "text/javascript";
    LArea.src = "https://qs.haodian.cn/web/javascript/LArea/js/LArea.js";
    document.getElementsByTagName("head")[0].appendChild(LArea);
  },
  computed: {
    //用计算属性显示结果
    showTime() {
      //剩余秒数<=0
      if (this.info.sTime > 0) {
        let day = Math.floor(this.info.sTime / 86400);
        let hour = Math.floor((this.info.sTime % 86400) / 3600);
        let min = Math.floor(((this.info.sTime % 86400) % 3600) / 60);
        let sec = Math.floor(((this.info.sTime % 86400) % 3600) % 60);
        if (day > 0) {
          return (
            "<span class='text-middle'>距抢购开始还有</span><em class='text-middle pl5 pr5'>" +
            day +
            "</em><em class='text-middle'>天</em><em class='text-middle pl5 pr5'>" +
            (hour < 10 ? "0" : "") +
            hour +
            "</em><em class='text-middle'>时</em><em class='text-middle pl5 pr5'>" +
            (min < 10 ? "0" : "") +
            min +
            "</em><em class='text-middle'>分</em><em class='text-middle pl5 pr5'>" +
            (sec < 10 ? "0" : "") +
            sec +
            "</em><em class='text-middle'>秒</em>"
          );
        } else if (hour > 0) {
          return (
            "<span class='text-middle'>距抢购开始还有</span><em class='text-middle color-ff7200 pl5 pr5'>" +
            (hour < 10 ? "0" : "") +
            hour +
            "</em><em class='text-middle'>时</em><em class='text-middle color-ff7200 pl5 pr5'>" +
            (min < 10 ? "0" : "") +
            min +
            "</em><em class='text-middle'>分</em><em class='text-middle color-ff7200 pl5 pr5'>" +
            (sec < 10 ? "0" : "") +
            sec +
            "</em><em class='text-middle'>秒</em>"
          );
        } else if (min > 0) {
          return (
            "<span class='text-middle'>距抢购开始还有</span><em class='text-middle color-ff7200 pl5 pr5'>" +
            (min < 10 ? "0" : "") +
            min +
            "</em><em class='text-middle'>分</em><em class='text-middle color-ff7200 pl5 pr5'>" +
            (sec < 10 ? "0" : "") +
            sec +
            "</em><em class='text-middle'>秒</em>"
          );
        } else if (sec > 0) {
          return (
            "<span class='text-middle'>距抢购开始还有</span><em class='text-middle color-ff7200 pl5 pr5'>" +
            (sec < 10 ? "0" : "") +
            sec +
            "</em><em class='text-middle'>秒</em>"
          );
        }
      } else if (this.info.eTime <= 0) {
        return "活动已结束";
      } else {
        // 剩余秒数>0
        let day = Math.floor(this.info.eTime / 86400);
        let hour = Math.floor((this.info.eTime % 86400) / 3600);
        let min = Math.floor(((this.info.eTime % 86400) % 3600) / 60);
        let sec = Math.floor(((this.info.eTime % 86400) % 3600) % 60);
        if (day > 0) {
          return (
            "<span class='text-middle'>距抢购结束还有</span><em class='text-middle pl5 pr5'>" +
            day +
            "</em><em class='text-middle'>天</em><em class='text-middle pl5 pr5'>" +
            (hour < 10 ? "0" : "") +
            hour +
            "</em><em class='text-middle'>时</em><em class='text-middle pl5 pr5'>" +
            (min < 10 ? "0" : "") +
            min +
            "</em><em class='text-middle'>分</em><em class='text-middle pl5 pr5'>" +
            (sec < 10 ? "0" : "") +
            sec +
            "</em><em class='text-middle'>秒</em>"
          );
        } else if (hour > 0) {
          return (
            "<span class='text-middle'>距抢购结束还有</span><em class='text-middle color-ff7200 pl5 pr5'>" +
            (hour < 10 ? "0" : "") +
            hour +
            "</em><em class='text-middle'>时</em><em class='text-middle color-ff7200 pl5 pr5'>" +
            (min < 10 ? "0" : "") +
            min +
            "</em><em class='text-middle'>分</em><em class='text-middle color-ff7200 pl5 pr5'>" +
            (sec < 10 ? "0" : "") +
            sec +
            "</em><em class='text-middle'>秒</em>"
          );
        } else if (min > 0) {
          return (
            "<span class='text-middle'>距抢购结束还有</span><em class='text-middle color-ff7200 pl5 pr5'>" +
            (min < 10 ? "0" : "") +
            min +
            "</em><em class='text-middle'>分</em><em class='text-middle color-ff7200 pl5 pr5'>" +
            (sec < 10 ? "0" : "") +
            sec +
            "</em><em class='text-middle'>秒</em>"
          );
        } else if (sec > 0) {
          return (
            "<span class='text-middle'>距抢购结束还有</span><em class='text-middle color-ff7200 pl5 pr5'>" +
            (sec < 10 ? "0" : "") +
            sec +
            "</em><em class='text-middle'>秒</em>"
          );
        }
      }
    },
  },

  mounted() {},
  beforeDestroy() {
    clearInterval(this.bundleIntervalEvent);
  },
  methods: {
    //更新用户信息
    getUserInfo() {
      let params = {};
      params["skey"] = this.skey;
      this.$get(this.$api.userInfo, params).then((res) => {
        this.$store.dispatch("setUserInfo", res.data);
        this.getItem();
        this.getActivity();
      });
    },

    //获取商品详情
    getItem() {
      let params = {};
      params["skey"] = this.skey;
      this.$get(this.$api.item, params).then((res) => {
        if (res.data.isPay) {
          this.$router.replace("/ucenter/orders?skey=" + this.skey);
        }
        this.info = res.data;
        if (sessionStorage.getItem("isShowReg")) {
          this.showReg();
        }

        setTimeout(() => {
          this.myswiper();
        }, 100);

        //倒计时
        this.bundleIntervalEvent = setInterval(this.intervalEvent, 1000);
      });
    },

    //获取活动信息
    getActivity() {
      let params = {};
      params["skey"] = this.skey;
      this.$get(this.$api.activity, params).then((res) => {
        this.$store.dispatch("setSkey", res.data.userSkey);
        this.getWxSign();
      });
    },

    //获取微信签名数据
    getWxSign() {
      let params = {};
      params["skey"] = this.$store.getters.getSkey;
      params["url"] = window.location.href;
      this.$get(this.$api.wxSign, params).then((res) => {
        let obj = {
          appId: res.data.signPackage.appid,
          nonceStr: res.data.signPackage.noncestr,
          signature: res.data.signPackage.signature,
          timestamp: res.data.signPackage.timestamp,
        };

        let shareData = {
          title: res.data.title, // 分享标题
          desc: res.data.desc,
          link:
            "https://" +
            document.domain +
            "/act?skey=" +
            this.$store.getters.getSkey,
          imgUrl: res.data.img, // 分享图标
        };
        //引用
        wexinShare(obj, shareData);
      });
    },

    //轮播
    myswiper() {
      new Swiper(".goods-banner .swiper-container", {
        //loop: true,
        //autoHeight: true,
        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
        },
      });
    },

    showReg: function () {
      sessionStorage.setItem("isShowReg", "");
      if (!sessionStorage.getItem("isauth")) {
        sessionStorage.setItem("isShowReg", 1);
        sessionStorage.setItem("userUedirectPath", this.toPath);
        let redirectURL = encodeURIComponent(
          window.location.href.split("&code")[0]
        ); //获取地址
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${Config.appId}&redirect_uri=${redirectURL}&response_type=code&scope=snsapi_userinfo&state=syauthuserinfo#wechat_redirect`;
      } else {
        this.isShowReg = true;

        let that = this;
        if (that.info.ifAddress > 0) {
          //选择省市初始化
          setTimeout(function () {
            let area = new LArea();
            area.init({
              trigger: "#region", //触发选择控件的文本框，同时选择完毕后name属性输出到该位置
              valueTo: "#regionvalue", //选择完毕后id属性输出到该位置
              keys: {
                id: "value",
                name: "text",
              }, //绑定数据源相关字段 id对应valueTo的value属性输出 name对应trigger的value属性输出
              type: 2,
              data: [provs_data, citys_data, dists_data],
            });

            if (that.region != "") {
              for (var i in provs_data) {
                if (provs_data[i].text == that.region.split(",")[0]) {
                  var x = i;
                  var city = citys_data[provs_data[i].value];
                  for (var j in city) {
                    if (city[j].text == that.region.split(",")[1]) {
                      var y = j;
                      var district = dists_data[city[j].value];
                      for (var k in district) {
                        if (district[k].text == that.region.split(",")[2]) {
                          var z = k;
                        }
                      }
                    }
                  }
                }
              }
              area.value = [x, y, z]; //控制初始位置，注意：该方法并不会影响到input的value
            } else {
              area.value = [12, 1, 1]; //控制初始位置，注意：该方法并不会影响到input的value
            }
            area.success = function () {
              that.regionvalue = $("#regionvalue").val();
            };
          }, 200);
        }
      }
    },
    closeReg: function () {
      this.isShowReg = false;
    },

    countDownTime(time) {
      let that = this;
      if (time > 1) {
        time--;
        this.codeState = false;
        this.codeTx = time + "s后重新获取";
        Timer = setTimeout(function () {
          that.countDownTime(time);
        }, 1000);
      } else {
        clearTimeout(Timer);
        that.codeState = true;
        that.codeTx = "立即获取";
      }
    },

    //获取验证码
    getSmsItem() {
      if (!this.codeState) return;
      if (!/^1[3456789]\d{9}$/.test(this.telPhone)) {
        this.$toast({
          message: "请输入正确手机号",
          duration: 1500,
        });
      } else {
        let params = {};
        params["skey"] = this.skey;
        params["mobile"] = this.telPhone;
        this.$get(this.$api.smsItem, params).then(() => {
          //按钮倒计时
          this.countDownTime(61);
        });
      }
    },

    //去支付
    toPay() {
      if (this.isSubmiting) return;

      if (!this.name) {
        this.$toast({
          message: "请输入您的姓名",
          duration: 1500,
        });
      } else if (!/^1[3456789]\d{9}$/.test(this.telPhone)) {
        this.$toast({
          message: "请输入正确手机号",
          duration: 1500,
        });
      } else if (!this.vCode) {
        this.$toast({
          message: "请输入验证码",
          duration: 1500,
        });
      } else if (this.info.ifAddress > 0 && !this.region) {
        this.$toast({
          message: "请选择所在地区",
          duration: 1500,
        });
      } else if (this.info.ifAddress > 0 && !this.address) {
        this.$toast({
          message: "请输入详细地址",
          duration: 1500,
        });
      } else if (!this.isXYChecked) {
        this.$toast({
          message: "请阅读并同意《" + this.info.protocolTitle + "》",
          duration: 1500,
        });
      } else {
        let that = this;
        that.isSubmiting = true;
        let params = {};
        params["skey"] = that.skey;
        params["name"] = that.name;
        params["mobile"] = that.telPhone;
        params["code"] = that.vCode;
        if (this.info.ifAddress > 0 && !this.region) {
          params["region"] = that.region;
        }
        if (this.info.ifAddress > 0 && !this.address) {
          params["detail"] = that.address;
        }
        that
          .$post(that.$api.postItem, params)
          .then((res) => {
            const pay_params = res.data;
            if (typeof WeixinJSBridge === "undefined") {
              if (document.addEventListener) {
                document.addEventListener(
                  "WeixinJSBridgeReady",
                  that.onBridgeReady(pay_params),
                  false
                );
              } else if (document.attachEvent) {
                document.attachEvent(
                  "WeixinJSBridgeReady",
                  that.onBridgeReady(pay_params)
                );
                document.attachEvent(
                  "onWeixinJSBridgeReady",
                  that.onBridgeReady(pay_params)
                );
              }
            } else {
              that.onBridgeReady(pay_params);
            }
          })
          .catch(() => {
            that.isSubmiting = false;
          });
      }
    },

    //微信支付
    onBridgeReady(params) {
      let that = this;
      WeixinJSBridge.invoke("getBrandWCPayRequest", params, function (res) {
        if (res.err_msg === "get_brand_wcpay_request:ok") {
          that.$toast({
            message: "支付成功！",
            duration: 1500,
          });
          that.isSubmiting = false;
          that.isShowReg = false;
          that.name = "";
          that.telPhone = "";
          that.vCode = "";
          that.isXYChecked = false;
          setTimeout(function () {
            that.$router.replace("/ucenter/orders?skey=" + that.skey);
          }, 1500);
        } else if (res.err_msg === "get_brand_wcpay_request:fail") {
          that.$toast({
            message: "支付失败！",
            duration: 1500,
          });
          that.isSubmiting = false;
        }
      });
    },

    //商品倒计时
    intervalEvent() {
      if (this.info.sTime > 0) {
        this.info.sTime--;
      } else if (this.info.eTime > 0) {
        this.info.eTime--;
      } else {
        clearInterval(this.bundleIntervalEvent);
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      //这里的vm指的就是vue实例，可以用来当做this使用
      vm.toPath = to.fullPath;
    });
  },
};
</script>
<style>
@import "../../assets/css/LArea.css";
</style>
