<template>
  <div class="scrollContainer font-12" ref="scrollContainer">
    <div
      class="container relative bg-no-repeat bg-size-fullwidth bg-pos-top"
      v-if="info.actId"
    >
      <img class="block wp100" :src="info.bgImg" alt="#" v-if="info.bgImg" />
      <div
        class="fixed top-0 right-0 bg-fff mt40 mr25 pl20 pr20 ulib-r750 fn-flex overflowhidden font-20"
        v-if="userInfo.actIsAdmin && userInfo.actAdminLevel > 0"
      >
        <a
          class="fn-flex-item pt10 pb10 pl20 pr20"
          @click="
            pushLink('/stic?orgId=' + userInfo.orgId + '&actId=' + info.actId)
          "
        >
          <img
            class="imgsize-40X40"
            src="https://qs.haodian.cn/web/images/project/H5-ShiYu/icon-shuju.png"
            alt="#"
          />
          <div class="text-middle">数据</div>
        </a>
        <a
          class="fn-flex-item pt10 pb10 pl20 pr20"
          @click="
            pushLink(
              '/stic/ranking?orgId=' + userInfo.orgId + '&actId=' + info.actId
            )
          "
        >
          <img
            class="imgsize-40X40"
            src="https://qs.haodian.cn/web/images/project/H5-ShiYu/icon-paihang.png"
            alt="#"
          />
          <div class="text-middle">排行</div>
        </a>
        <a
          class="fn-flex-item pt10 pb10 pl20 pr20"
          @click="pushLink('/act/share?skey=' + skey)"
        >
          <img
            class="imgsize-40X40"
            src="https://qs.haodian.cn/web/images/project/H5-ShiYu/icon-fenxiang.png"
            alt="#"
          />
          <div class="text-middle">分享</div>
        </a>
      </div>

      <div
        class="fixed left-0 bottom-0 right-0 inner20 pb40 bg-fff ulib-rt20 z-index-4 fn-flex text-center"
      >
        <div
          class="relative fn-flex-item pl20 pr20"
          flexsize="0"
          v-for="(item, index) in info.bottoms"
          :key="index"
        >
          <a v-if="item.type == 1" @click="pushLink(item.url)">
            <img class="imgsize-50X50" :src="item.icon" alt="#" />
            <div class="space-nowrap con-min60 font-22">{{ item.title }}</div>
          </a>
          <div v-else-if="item.type == 2">
            <img class="imgsize-50X50" :src="item.icon" alt="#" />
            <div class="space-nowrap con-min60 font-22">{{ item.title }}</div>
            <div
              class="absolute top-0 left-0 right-0 bottom-0 overflowhidden z-index-1"
            >
              <wx-open-launch-weapp
                id="launch-btn"
                :username="item.wechatGhId"
                :path="item.url"
              >
                <template>
                  <button
                    class="btn"
                    style="width: 100px; height: 60px; opacity: 0"
                  ></button>
                </template>
                <script type="text/wxtag-template">
                  <button class="btn" style="width:100px;height:60px;opacity:0"></button>
                </script>
              </wx-open-launch-weapp>
            </div>
          </div>
        </div>
        <a
          class="fn-flex-item pl20 pr20"
          flexsize="0"
          @click="pushLink('/ucenter?skey=' + skey)"
        >
          <img
            class="imgsize-50X50"
            src="https://qs.haodian.cn/web/images/project/H5-ShiYu/icon-wode.png"
            alt="#"
          />
          <div class="space-nowrap con-min60 font-22">我的</div>
        </a>

        <div class="fn-flex-item pl20" flexsize="1">
          <template v-if="info.liveStatus < 2">
            <a
              class="block bg-1a1a1a pt20 pb20 text-center font-36 color-fff ulib-r20"
              v-if="info.liveStatus == 1"
              href="javascript:;"
              @click="postKpiData"
              >进入直播间</a
            >
            <a
              class="block bg-1a1a1a pt20 pb20 text-center font-36 color-fff ulib-r20"
              v-else
              href="javascript:;"
              @click="notStarted"
            >
              <!--<i class="iconfont icon-tixing mr10"></i>-->
              {{ info.btnText }}
            </a>
          </template>
          <template v-else>
            <a
              class="block bg-1a1a1a pt20 pb20 text-center font-36 color-fff ulib-r20"
              href="javascript:;"
              >活动已结束</a
            >
          </template>
        </div>
      </div>

      <!--直播字幕-->
      <div>
        <div class="notice-shadow"></div>
        <div
          class="notice bg-000-op10 pl20 pr20 line-height-17 font-28 ulib-r750 z-index-1"
          :style="
            'animation-delay:' +
            item.delay +
            's;' +
            'color:' +
            info.notice_color
          "
          v-for="item in notice1"
        >
          <span>{{ item.name }}</span>
          <span class="ml10">{{ item.tip }}</span>
        </div>
        <div
          class="notice bg-000-op10 pl20 pr20 line-height-17 font-28 ulib-r750 z-index-1"
          :style="
            'animation-delay:' +
            item.delay +
            's;' +
            'color:' +
            info.notice_color
          "
          v-for="item in notice2"
        >
          <span>{{ item.name }}</span>
          <span class="ml10">{{ item.tip }}</span>
        </div>
      </div>
    </div>

    <div
      class="msg fn-hide"
      :style="msgisShowCode ? 'display:block' : 'display:none'"
      v-if="msgisShowCode"
    >
      <div class="msgBg" @click="closeCode"></div>
      <div class="msgMain">
        <div class="content">
          <div class="word text-center">
            <div class="mt20 font-22 line-height-15 color-666">
              <div class="mt10">看直播，好礼享不停！</div>
              <div class="mt10">添加【东风EV官方企微号】</div>
              <div class="mt10">了解更多惊喜内幕！</div>
            </div>
            <div class="mt20 pt10 text-center">
              <img class="inline-block imgsize-360X360" :src="info.code" />
            </div>
            <div
              class="pt10 pb20 pl20 pr20 font-24 color-fe9538 line-height-15"
            >
              <img
                class="imgsize-24X24 text-middle"
                src="https://qs.haodian.cn/web/images/project/H5-ShiYu/icon-finger2.png"
                alt="#"
              />
              <span class="text-middle">长按识别二维码添加</span>
            </div>
          </div>
          <!-- <div class="opt pl40 pr40">
                  <a class="block bg-1a1a1a pt25 pb25 text-center font-32 color-fff ulib-r750" @click="closeCode" href="javascript:" >知道了</a>
                </div> -->
        </div>
      </div>
    </div>

    <div
      class="msg fn-hide"
      :style="msgisShowReg ? 'display:block' : 'display:none'"
      v-if="msgisShowReg"
    >
      <div class="msgBg" @click="msgisShowReg = false"></div>
      <div class="msgMain">
        <div class="content">
          <div class="word">
            <div class="pt10 pb10 text-center font-36">{{ info.btn_text }}</div>
            <div class="mt20 relative bg-f6 ulib-r10">
              <input
                class="wp100 inner30 font-30 bg-f6 border-none ulib-r10"
                type="tel"
                v-model="telPhone"
                placeholder="请输入手机号"
              />
            </div>
            <div class="mt30 relative bg-f6 ulib-r10 pr200">
              <div>
                <input
                  class="wp100 inner30 font-30 bg-f6 border-none ulib-r10"
                  type="number"
                  v-model="vCode"
                  placeholder="请输入验证码"
                />
              </div>
              <a
                :class="[
                  codeState ? 'color-333' : 'color-999',
                  'absolute right-0 mr20 box-middle font-30',
                ]"
                @click="getSms()"
                >{{ codeTx }}</a
              >
            </div>
          </div>
          <div class="opt mt30 pl20 pr20 fn-flex">
            <a
              class="fn-flex-item bg-fff bds-2-1a1a1a mr15 pt20 pb20 text-center font-32 ulib-r750"
              @click="msgisShowReg = false"
              href="javascript:"
              >取消</a
            >
            <a
              class="fn-flex-item bg-1a1a1a ml15 pt20 pb20 text-center font-32 color-fff ulib-r750"
              @click="postAppoint()"
              href="javascript:"
              >确定</a
            >
          </div>
        </div>
      </div>
    </div>

    <div
      class="msg fn-hide"
      :style="msgisShowSuccess ? 'display:block' : 'display:none'"
      v-if="msgisShowSuccess"
    >
      <div class="msgBg" @click="msgisShowSuccess = false"></div>
      <div class="msgMain">
        <img
          class="absolute top-0 right-0 mt30 mr25 imgsize-32X32"
          @click="msgisShowSuccess = false"
          src="https://qs.haodian.cn/web/images/project/H5-ShiYu/icon-colse.png"
          alt="关闭"
        />
        <div class="content">
          <div>
            <div class="pt10 pb10 text-center font-36">
              {{ succesTx.title }}
            </div>
            <div
              class="inner30 text-break font-22 line-height-17 color-666"
              v-html="succesTx.content"
            ></div>
          </div>
          <div class="opt pl20 pr20 text-center" v-if="!info.isSubscribe">
            <a
              class="inline-block wp80 bg-1a1a1a pt20 pb20 text-center font-32 color-fff ulib-r750"
              @click="goSubscribe"
              href="javascript:"
              ><i class="iconfont icon-tixing mr10"></i>开播提醒</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Config from "../../config.js";
import { wexinShare } from "../../assets/js/utils/wexinShare.js";
var Timer;
export default {
  data() {
    return {
      skey: this.$route.query.skey,
      toPath: "", //授权用户信息返回时使用
      userInfo: "",
      info: "",
      page: 1,
      notice1: [],
      notice2: [],
      code: "",
      msgisShowCode: false,
      noticeTime: null,

      msgisShowReg: false,
      telPhone: "",
      isSubmiting: false,
      vCode: "",
      codeState: true,
      codeTx: "立即获取",

      msgisShowSuccess: false, //报名成功
      succesTx: "", //报名成功提示
    };
  },

  created() {
    this.getUserInfo();
  },
  computed: {},
  mounted() {
    this.getActivityEvent();
    if (
      sessionStorage.getItem("isSubscribe") &&
      this.$route.query.action == "confirm"
    ) {
      this.postSubscribe();
    }
  },
  beforeDestroy() {
    this.notice1 = [];
    this.notice2 = [];
    clearTimeout(this.noticeTime);
  },
  methods: {
    //推送链接
    pushLink(url) {
      sessionStorage.setItem("isPushLink", "");
      if (!sessionStorage.getItem("isauth")) {
        sessionStorage.setItem("isPushLink", url);
        sessionStorage.setItem("userUedirectPath", this.toPath);
        let redirectURL = encodeURIComponent(
          window.location.href.split("&code")[0]
        ); //获取地址
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${Config.appId}&redirect_uri=${redirectURL}&response_type=code&scope=snsapi_userinfo&state=syauthuserinfo#wechat_redirect`;
      } else {
        if (url) {
          if (url.indexOf("http") != -1) {
            window.location.href = url;
          } else {
            this.$router.push({ path: url });
          }
        }
      }
    },

    //活动未开操作
    notStarted() {
      sessionStorage.setItem("isNotStarted", "");
      if (!sessionStorage.getItem("isauth")) {
        sessionStorage.setItem("isNotStarted", 1);
        sessionStorage.setItem("userUedirectPath", this.toPath);
        let redirectURL = encodeURIComponent(
          window.location.href.split("&code")[0]
        ); //获取地址
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${Config.appId}&redirect_uri=${redirectURL}&response_type=code&scope=snsapi_userinfo&state=syauthuserinfo#wechat_redirect`;
      } else {
        if (this.info.appointMobile) {
          if (this.info.isAppoint) {
            this.msgisShowSuccess = true;
            this.succesTx = {
              title: this.info.appointTitle,
              content: this.info.appointMsg,
            };
          } else {
            this.msgisShowReg = true;
          }
        } else if (this.info.isSubscribe) {
          this.$toast({
            message: "已订阅直播",
            duration: 1500,
          });
        } else {
          this.goSubscribe();
        }
      }
    },

    //一次性订阅
    goSubscribe() {
      sessionStorage.setItem("isSubscribe", 1);
      let redirectURL = encodeURIComponent(
        "https://" +
          document.domain +
          "/act?skey=" +
          this.$store.getters.getSkey
      ); //获取地址
      window.location.href = `https://mp.weixin.qq.com/mp/subscribemsg?action=get_confirm&appid=${Config.appId}&scene=1000&template_id=${Config.template_id}&redirect_url=${redirectURL}&reserved=subscribe#wechat_redirect`;
    },

    //保存订阅信息
    postSubscribe() {
      let that = this;
      sessionStorage.setItem("isSubscribe", "");
      let params = {};
      params["skey"] = that.$store.getters.getSkey;
      params["scene"] = that.$route.query.scene;
      params["openid"] = that.$route.query.openid;
      params["templateId"] = that.$route.query.template_id;
      that.$post(that.$api.subscribe, params).then(() => {
        that.$toast({
          message: "设置成功",
          duration: 1500,
        });
        that.getActivity();
      });
    },

    //更新用户信息
    getUserInfo() {
      let params = {};
      params["skey"] = this.skey;
      this.$get(this.$api.userInfo, params).then((res) => {
        this.userInfo = res.data;
        this.$store.dispatch("setUserInfo", res.data);
        this.getActivity();
      });
    },

    //获取活动信息
    getActivity() {
      let params = {};
      params["skey"] = this.skey;
      this.$get(this.$api.activity, params).then((res) => {
        window.document.title = res.data.title;
        this.info = res.data;
        this.skey = res.data.userSkey;
        this.$store.dispatch("setSkey", res.data.userSkey);
        window.document.title = res.data.title;
        this.getWxSign();

        if (sessionStorage.getItem("isPushLink")) {
          this.pushLink(sessionStorage.getItem("isPushLink"));
        }
        if (sessionStorage.getItem("isNotStarted")) {
          this.notStarted();
        }
      });
    },

    //获取微信签名数据
    getWxSign() {
      let params = {};
      params["skey"] = this.$store.getters.getSkey;
      params["url"] = window.location.href;
      this.$get(this.$api.wxSign, params).then((res) => {
        let obj = {
          appId: res.data.signPackage.appid,
          nonceStr: res.data.signPackage.noncestr,
          signature: res.data.signPackage.signature,
          timestamp: res.data.signPackage.timestamp,
        };

        let shareData = {
          title: res.data.title, // 分享标题
          desc: res.data.desc,
          link:
            "https://" +
            document.domain +
            "/act?skey=" +
            this.$store.getters.getSkey,
          imgUrl: res.data.img, // 分享图标
        };
        //引用
        wexinShare(obj, shareData);
      });
    },

    //获取底部订阅提示
    getActivityEvent() {
      let that = this;
      let params = {};
      params["skey"] = that.skey;
      params["page"] = 1;
      params["size"] = 20;
      that.$get(that.$api.activityEvent, params).then((res) => {
        that.page = that.page + 1;
        let alldelay = 0;
        let notice = res.data.lists;

        if (notice && notice.length > 0) {
          notice.forEach((item) => {
            item.delay = alldelay + Math.floor(Math.random() * 3) / 10 + 1;
            alldelay = item.delay;
          });

          if (that.page % 2 == 0) {
            that.notice2 = notice;
            setTimeout(function () {
              that.notice1 = [];
            }, 6000);
          } else {
            that.notice1 = notice;
            setTimeout(function () {
              that.notice2 = [];
            }, 6000);
          }

          this.noticeTime = setTimeout(function () {
            that.getActivityEvent();
          }, (alldelay < 20 ? 20 : alldelay) * 1000);
        }
      });
    },

    //获取验证码倒计时
    countDownTime(time) {
      let that = this;
      if (time > 1) {
        time--;
        this.codeState = false;
        this.codeTx = time + "s后重新获取";
        Timer = setTimeout(function () {
          that.countDownTime(time);
        }, 1000);
      } else {
        clearTimeout(Timer);
        that.codeState = true;
        that.codeTx = "立即获取";
      }
    },

    //获取验证码
    getSms() {
      if (!this.codeState) return;
      if (!/^1[3456789]\d{9}$/.test(this.telPhone)) {
        this.$toast({
          message: "请输入正确手机号",
          duration: 1500,
        });
      } else {
        let params = {};
        params["type"] = "sylive_appt";
        params["mobile"] = this.telPhone;
        this.$get(this.$api.sms, params).then(() => {
          //按钮倒计时
          this.countDownTime(61);
        });
      }
    },

    //提交手机号
    postAppoint() {
      if (this.isSubmiting) return;

      if (!/^1[3456789]\d{9}$/.test(this.telPhone)) {
        this.$toast({
          message: "请输入正确手机号",
          duration: 1500,
        });
      } else if (!this.vCode) {
        this.$toast({
          message: "请输入验证码",
          duration: 1500,
        });
      } else {
        let that = this;
        that.isSubmiting = true;
        let params = {};
        params["skey"] = that.$store.getters.getSkey;
        params["code"] = that.vCode;
        params["mobile"] = that.telPhone;
        that
          .$post(that.$api.appoint, params)
          .then(() => {
            that.isSubmiting = false;
            that.msgisShowReg = false;
            that.telPhone = "";
            that.vCode = "";
            that.goSubscribe();
          })
          .catch(() => {
            that.isSubmiting = false;
          });
      }
    },

    //增加观看kpi数据
    postKpiData() {
      if (this.isSubmiting) return;
      let that = this;
      that.isSubmiting = true;
      let params = {};
      params["skey"] = that.$store.getters.getSkey;
      params["kpi"] = "watch";
      that
        .$post(that.$api.kpiData, params)
        .then(() => {
          that.isSubmiting = false;
          window.location.href = that.info.liveUrl;
        })
        .catch(() => {
          that.isSubmiting = false;
        });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      //这里的vm指的就是vue实例，可以用来当做this使用
      vm.toPath = to.fullPath;
    });
  },
};
</script>
