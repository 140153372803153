<template>
  <div class="scrollContainer font-12" ref="scrollContainer">
    <div class="container">
      <div class="inner30 pt0">
        <div
          class="mt30 bg-fff ulib-r20"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="relative overflowhidden ulib-rt20">
            <div
              class="orderTip2 text-center font-24 color-fff bg-ff5a5a"
              v-if="item.tag"
            >
              {{ item.tag }}
            </div>
            <img class="imgsize-690X345" :src="item.img" alt="#" />
          </div>
          <div class="inner30 pt20">
            <div class="relative pr150">
              <div class="font-30 color-333">{{ item.goods }}</div>
              <div class="mt15 font-20 color-bbb">抽奖礼品需提车后才能享受</div>
              <img
                class="absolute box-middle right-0 imgsize-130X100"
                src="https://qs.haodian.cn/web/images/project/H5-ShiYu/icon-hexiao.png"
                alt="已核销"
                v-if="item.ifUsed"
              />
            </div>
            <div
              class="bg-eee mt20 pt10 pb10 pl15 pr15 ulib-rtr20 ulib-rbl20 ulib-rbr20 font-22 color-666"
              v-if="item.courierNo"
              @click="getWuliu(item.courierNo)"
            >
              您的快递已发出 快递单号:{{ item.courierNo }}>
            </div>
          </div>
        </div>
        <mugenscroll
          :handler="fetchData"
          :should-handle="!loading"
          scroll-container="scrollContainer"
        >
          <div class="pt100 pb100 text-center color-ccc" v-if="isNoData">
            <span class="text-middle font-22">暂无数据</span>
          </div>
          <div class="pt20 pb20 text-center color-ccc" v-else-if="loading">
            <i class="iconfont icon-jiazai text-middle"></i
            ><span class="text-middle font-22">请稍等...</span>
          </div>
          <div
            class="pt20 pb20 text-center font-22 color-ccc"
            v-else-if="isDataEnd && list.length > 10"
          >
            我们是有底线的
          </div>
        </mugenscroll>
      </div>
    </div>

    <!--快递-->
    <div
      class="msg fn-hide"
      :style="msgisShowExpress ? 'display:block' : 'display:none'"
      v-if="msgisShowExpress"
    >
      <div class="msgBg" @click="msgisShowExpress = false"></div>
      <div class="msgMain">
        <div class="content">
          <div class="pt20 pb20 fn-clear">
            <div class="fn-fl font-36">{{ expressInfo.expName }}</div>
            <div class="fn-fr mt10 font-24">
              <span>{{ expressInfo.number }}</span>
              <a
                class="J_copy"
                @click="copy"
                :data-clipboard-text="expressInfo.number"
                >复制</a
              >
            </div>
          </div>
          <div class="pl20 pr20 max-height-500 scroll-y">
            <div class="orderDtail-log mt40 relative">
              <div
                class="orderDtail-log-item pl40 relative"
                v-for="(item, index) in expressInfo.list"
                :key="index"
              >
                <div class="orderDtail-log-content font-22">
                  <div :class="index == 0 ? 'color-ff9d47' : ''">
                    {{ item.time }}
                  </div>
                  <div class="color-999 text-break">{{ item.status }}</div>
                </div>
                <span
                  class="absolute orderDtail-log-line2 z-index-0"
                  v-if="index != expressInfo.list.length - 1"
                ></span>
                <span class="absolute orderDtail-log-dot z-index-1"></span>
              </div>
            </div>
          </div>
          <div class="opt pb30 pl20 pr20">
            <a
              class="bg-1a1a1a ml15 pt20 pb20 pl60 pr60 text-center font-32 color-fff ulib-r750"
              @click="msgisShowExpress = false"
              href="javascript:"
              >知道了</a
            >
          </div>
        </div>
      </div>
    </div>

    <backHome></backHome>
  </div>
</template>
<script>
import Clipboard from "clipboard";
import mugenscroll from "vue-mugen-scroll";
import backHome from "../../components/backHome";
import { wexinShare } from "../../assets/js/utils/wexinShare.js";
export default {
  components: { mugenscroll, backHome },
  data() {
    return {
      skey: this.$route.query.skey,
      loading: false,
      isDataEnd: false,
      isNoData: false,
      page: 1,
      size: 20,
      list: [],
      msgisShowExpress: false,
      expressIndex: -1,
      expressInfo: "",
    };
  },

  created: function () {
    this.getWxSign();
    this.getOrderLists();
  },

  mounted: function () {},
  methods: {
    //获取微信签名数据
    getWxSign() {
      let params = {};
      params["skey"] = this.$store.getters.getSkey;
      params["url"] = window.location.href;
      this.$get(this.$api.wxSign, params).then((res) => {
        let obj = {
          appId: res.data.signPackage.appid,
          nonceStr: res.data.signPackage.noncestr,
          signature: res.data.signPackage.signature,
          timestamp: res.data.signPackage.timestamp,
        };

        let shareData = {
          title: res.data.title, // 分享标题
          desc: res.data.desc,
          link:
            "https://" +
            document.domain +
            "/act?skey=" +
            this.$store.getters.getSkey,
          imgUrl: res.data.img, // 分享图标
        };
        //引用
        wexinShare(obj, shareData);
      });
    },

    //复制文字
    copy() {
      let clipboard = new Clipboard(".J_copy");
      clipboard.on("success", () => {
        this.$toast({
          message: "复制成功！",
          duration: 1500,
        });
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 释放内存
        clipboard.destroy();
      });
    },

    //获取订单详情
    getWuliu(express) {
      let params = {};
      params["skey"] = this.skey;
      params["express"] = express;
      this.$get(this.$api.getWuliu, params).then((res) => {
        this.msgisShowExpress = true;
        this.expressInfo = res.data;
      });
    },

    //拉取数据
    fetchData: function () {
      this.getOrderLists();
    },

    //获取用户订单
    getOrderLists() {
      let that = this;
      if (!that.isNoData && !that.isDataEnd && !that.loading) {
        that.loading = true;
        let params = {};
        params["skey"] = that.skey;
        params["isWin"] = 1;
        params["page"] = that.page;
        params["size"] = that.size;
        that.$get(that.$api.orderLists, params).then((res) => {
          that.loading = false;
          that.page = that.page + 1;
          that.list =
            res.data.lists && res.data.lists.length > 0
              ? that.list.concat(res.data.lists)
              : that.list;
          if (res.data.total == 0) {
            that.isNoData = true;
          } else if (that.list.length == res.data.total) {
            that.isDataEnd = true;
          }
        });
      }
    },
  },
};
</script>
