<template>
  <div class="scrollContainer font-12" ref="scrollContainer">
    <div class="container">
      <div class="pt30 pl30 pr30 fn-clear">
        <a
          class="block fn-fl pt10 pb10 pl20 pr20 bg-ccc font-28 ulib-r750"
          href="javascript:;"
          @click="quit()"
          ><i class="iconfont icon-tuichu text-middle"></i
          ><span class="ml5 text-middle">退出</span></a
        >
        <router-link
          class="block fn-fr pt10 pb10 pl20 pr20 bg-333 font-28 color-fff ulib-r750"
          :to="{ path: '/index' }"
          v-if="orgtotal > 1"
          ><i class="iconfont icon-qiehuan1 text-middle"></i
          ><span class="ml5 text-middle">切换机构</span>
        </router-link>
      </div>
      <div class="inner30 pt0">
        <router-link
          class="block mt30 pb30 bg-fff overflowhidden box-shadow-lightGray ulib-r20"
          :to="{
            path: '/stic?orgId=' + orgId + '&actId=' + item.actId,
          }"
          v-for="(item, index) in list"
          :key="index"
        >
          <img class="imgsize-690X310" :src="item.banner" alt="#" />
          <div class="mt20 pl30 pr30 font-30 color-333">{{ item.title }}</div>
          <div class="mt10 pl30 pr30 font-24 color-999">
            {{ item.timeStart }} 至 {{ item.timeEnd }}
          </div>
        </router-link>
        <mugenscroll
          :handler="fetchData"
          :should-handle="!loading"
          scroll-container="scrollContainer"
        >
          <div class="pt100 pb100 text-center color-ccc" v-if="isNoData">
            <span class="text-middle font-22">暂无数据</span>
          </div>
          <div class="pt20 pb20 text-center color-ccc" v-else-if="loading">
            <i class="iconfont icon-jiazai text-middle"></i
            ><span class="text-middle font-22">请稍等...</span>
          </div>
          <div
            class="pt20 pb20 text-center font-22 color-ccc"
            v-else-if="isDataEnd && list.length > 10"
          >
            我们是有底线的
          </div>
        </mugenscroll>
      </div>
    </div>
    <router-link
      class="bottom-opt bg-1a1a1a ulib-r750 text-center color-fff"
      :to="{
        path:
          '/user?orgId=' +
          orgId +
          '&bizName=' +
          bizName +
          '&bizLogo=' +
          bizLogo,
      }"
      v-if="isBiz"
    >
      <div class="absolute box-center-middle line-height-13 font-22">
        <div>顾问</div>
        <div>管理</div>
      </div>
    </router-link>
  </div>
</template>
<script>
import mugenscroll from "vue-mugen-scroll";
import { hiddenShare } from "../../assets/js/utils/wexinShare.js";
export default {
  components: { mugenscroll },
  data() {
    return {
      orgId: this.$route.query.orgId,
      bizName: this.$route.query.bizName,
      bizLogo: this.$route.query.bizLogo,
      loading: false,
      isDataEnd: false,
      isNoData: false,
      page: 1,
      size: 10,
      list: [],
      orgtotal: 1,
      isBiz: 0, // 当前机构是否店长 0否 1是
    };
  },
  created: function () {
    this.getHiddenWxSign();
    this.getOrgLists();
    this.getActLists();
  },
  mounted: function () {},
  methods: {
    //获取微信签名数据
    getHiddenWxSign() {
      let params = {};
      params["url"] = window.location.href;
      this.$get(this.$api.wxSign, params).then((res) => {
        let obj = {
          appId: res.data.signPackage.appid,
          nonceStr: res.data.signPackage.noncestr,
          signature: res.data.signPackage.signature,
          timestamp: res.data.signPackage.timestamp,
        };
        hiddenShare(obj);
      });
    },

    //退出
    quit() {
      let that = this;
      that.$store.dispatch("setToken", "");
      that.$store.dispatch("setIsAdminLogin", false);
      that.$toast({
        message: "退出成功",
        duration: 1500,
      });
      setTimeout(function () {
        that.$router.push({ path: "/login" });
      }, 1500);
    },

    //拉取数据
    fetchData: function () {
      this.getActLists();
    },
    //获取大区列表
    getActLists() {
      let that = this;
      if (!that.isNoData && !that.isDataEnd && !that.loading) {
        that.loading = true;
        let params = {};
        params["orgId"] = that.orgId;
        params["page"] = that.page;
        params["size"] = that.size;
        that.$get(that.$api.bizActLists, params).then((res) => {
          that.isBiz = res.data.isBiz;
          that.loading = false;
          that.page = that.page + 1;
          that.list =
            res.data.lists && res.data.lists.length > 0
              ? res.data.lists
              : that.list;
          if (res.data.total == 0) {
            that.isNoData = true;
          } else if (that.list.length == res.data.total) {
            that.isDataEnd = true;
          }
        });
      }
    },

    //获取机构数量
    getOrgLists() {
      this.$get(this.$api.bizOrgLists, {}).then((res) => {
        this.orgtotal = res.data.total;
      });
    },
  },
};
</script>
