var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"scrollContainer",staticClass:"scrollContainer font-12"},[_vm._m(0),_c('div',{staticClass:"absolute bp20 left-0 right-0 pl100 pr100 text-center fn-flex fn-flex-wrap fn-flex-center"},_vm._l((_vm.list),function(item,index){return _c('router-link',{key:index,staticClass:"block wp50",attrs:{"to":{
        path:
          '/biz?orgId=' +
          item.orgId +
          '&bizName=' +
          item.bizName +
          '&bizLogo=' +
          item.logo,
      }}},[_c('img',{staticClass:"inline-block imgsize-210X210 ulib-r750",attrs:{"src":item.logo}})])}),1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"absolute tp20 left-0 right-0 text-center"},[_c('div',{staticClass:"font-40"},[_vm._v("请选择机构登录")]),_c('div',{staticClass:"mt20 font-28 color-666"},[_vm._v("您的账号存在多个机构")]),_c('div',{staticClass:"opt-line"})])
}]

export { render, staticRenderFns }