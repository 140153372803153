<template>
  <div class="scrollContainer font-12" ref="scrollContainer">
    <div class="container pb130" v-if="skey">
      <div class="inner20">
        <div class="relative pl50 pr200 bg-333 ulib-r750">
          <img
            class="block absolute left-0 box-middle ml10 imgsize-32X32 ulib-r750"
            :src="userInfo.headimg"
          />
          <div class="pt15 pb15 text-nowrap font-22 color-ddd">
            <span class="color-fff">{{ userInfo.nickname }}</span>
            <span class="color-fff" v-if="group">·</span>
            <span class="color-ddd">{{ group }}</span>
          </div>
          <div
            class="block absolute right-0 mr150 box-middle bg-fff imgsize-32X32 ulib-r750"
            @click="quit()"
          >
            <i
              class="absolute box-center-middle iconfont font-24 icon-tuichu1"
            ></i>
          </div>
          <span class="absolute right-0 box-middle mr128 font-28 color-fff"
            >|</span
          >
          <router-link
            class="block absolute right-0 mr80 box-middle bg-fff imgsize-32X32 ulib-r750"
            :to="{ path: '/act?skey=' + skey }"
            ><i
              class="absolute box-center-middle iconfont font-24 icon-qizi"
            ></i
          ></router-link>
          <router-link
            class="absolute right-0 box-middle mr15 font-28 color-fff"
            :to="{
              path:
                '/biz?orgId=' +
                orgId +
                '&bizName=' +
                orgInfo.bizName +
                '&bizLogo=' +
                orgInfo.logo,
            }"
            >活动</router-link
          >
        </div>
      </div>
      <router-link class="block relative" :to="{ path: '/act?skey=' + skey }">
        <div
          class="absolute box-center-middle pt15 pb15 pl30 pr20 bg-000-op80 font-30 color-fff ulib-r750"
        >
          进入活动<i class="iconfont icon-gengduo font-22"></i>
        </div>
        <img class="block imgsize-750X340" :src="theme"
      /></router-link>
      <div id="main" class="inner30" :class="showLive ? '' : 'pt0'">
        <div v-if="showLive">
          <div v-if="tabFixed">
            <div class="height-60"></div>
            <div
              class="fixed top-0 left-0 right-0 z-index-10 bg-fff pl30 pr30 pt20 pb20"
            >
              <div
                class="relative height-70 ml50 mr50 fn-flex text-center"
                v-if="tab.length < 5"
              >
                <div
                  class="fn-flex-item relative tab-menu2 pt10 pb10"
                  :class="[tabid == item.id ? 'font-36 active' : 'font-28']"
                  v-for="(item, index) in tab"
                  :key="index"
                >
                  <a
                    class="relative inline-block"
                    :class="[tabid == item.id ? '' : 'pt10']"
                    @click="changeTab(item.id)"
                    href="javascript:"
                    >{{ item.title }}</a
                  >
                </div>
              </div>
              <div
                class="relative height-70 space-nowrap text-center scroll-x"
                v-else
              >
                <div
                  class="inline-block relative tab-menu2 pt10 pb10 pl25 pr25"
                  :class="[tabid == item.id ? 'font-36 active' : 'font-28']"
                  v-for="(item, index) in tab"
                  :key="index"
                >
                  <a
                    class="relative inline-block"
                    :class="[tabid == item.id ? '' : 'pt10']"
                    @click="changeTab(item.id)"
                    href="javascript:"
                    >{{ item.title }}</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div
              class="relative height-70 ml50 mr50 fn-flex text-center"
              v-if="tab.length < 5"
            >
              <div
                class="fn-flex-item relative tab-menu2 pt10 pb10"
                :class="[tabid == item.id ? 'font-36 active' : 'font-28']"
                v-for="(item, index) in tab"
                :key="index"
              >
                <a
                  class="relative inline-block"
                  :class="[tabid == item.id ? '' : 'pt10']"
                  @click="changeTab(item.id)"
                  href="javascript:"
                  >{{ item.title }}</a
                >
              </div>
            </div>
            <div
              class="relative height-70 space-nowrap text-center scroll-x"
              v-else
            >
              <div
                class="inline-block relative tab-menu2 pt10 pb10 pl25 pr25"
                :class="[tabid == item.id ? 'font-36 active' : 'font-28']"
                v-for="(item, index) in tab"
                :key="index"
              >
                <a
                  class="relative inline-block"
                  :class="[tabid == item.id ? '' : 'pt10']"
                  @click="changeTab(item.id)"
                  href="javascript:"
                  >{{ item.title }}</a
                >
              </div>
            </div>
          </div>
        </div>

        <div
          class="mt30 bg-fff7f1 pt15 pb15 overflowhidden text-center font-22 color-ff9d47 ulib-r20"
          v-if="tabid == 1"
        >
          温馨提示：直播结束后6小时同步数据
        </div>
        <div class="mt30">
          <!--数据第一栏-->
          <div
            class="relative fn-flex fn-flex-wrap text-center bg-fff bts-5-333 bls-1-333 brs-1-333 ulib-rt30"
          >
            <div
              class="pt40 pb40 bbs-1-f4f4f4"
              :class="myStatic.h_lists.length == 4 ? 'wp25' : 'wp33'"
              v-for="item in myStatic.h_lists"
              :key="item.title"
            >
              <div class="font-32">{{ item.num }}</div>
              <div class="mt10 font-22 color-666">{{ item.title }}</div>
            </div>
          </div>
          <!--数据第二栏-->
          <div class="bg-f6 bbs-5-ff9d47 bls-1-ff9d47 brs-1-ff9d47 ulib-rb30">
            <div class="relative bbs-1-fff last-b-none fn-flex text-center">
              <div
                class="absolute top-0 box-center cell-tt pl5 pr5 font-22 color-fff"
              >
                {{ myStatic.mine.title }}
              </div>
              <div
                class="fn-flex-item pt40 pb40"
                v-for="item in myStatic.mine.lists"
                :key="item.title"
              >
                <router-link
                  class=""
                  :to="{
                    path:
                      '/stic/users?orgId=' +
                      orgId +
                      '&actId=' +
                      actId +
                      '&kpi=beforeOrder' +
                      '&title=' +
                      item.title,
                  }"
                  v-if="item.title == '留资用户'"
                >
                  <div class="font-32 color-ff9d47">{{ item.num }}</div>
                  <div class="mt10 font-22 color-666">
                    {{ item.title }}<i class="iconfont icon-gengduo"></i>
                  </div>
                </router-link>
                <router-link
                  class=""
                  :to="{
                    path:
                      '/stic/users?orgId=' +
                      orgId +
                      '&actId=' +
                      actId +
                      '&kpi=browse' +
                      '&title=' +
                      item.title,
                  }"
                  v-else-if="item.title == '访问用户'"
                >
                  <div class="font-32 color-ff9d47">{{ item.num }}</div>
                  <div class="mt10 font-22 color-666">
                    {{ item.title }}<i class="iconfont icon-gengduo"></i>
                  </div>
                </router-link>
                <router-link
                  class=""
                  :to="{
                    path:
                      '/stic/users?orgId=' +
                      orgId +
                      '&actId=' +
                      actId +
                      '&kpi=subscribe' +
                      '&title=' +
                      item.title,
                  }"
                  v-else-if="item.title == '预约用户'"
                >
                  <div class="font-32 color-ff9d47">{{ item.num }}</div>
                  <div class="mt10 font-22 color-666">
                    {{ item.title }}<i class="iconfont icon-gengduo"></i>
                  </div>
                </router-link>
                <router-link
                  class=""
                  :to="{
                    path:
                      '/stic/users?orgId=' +
                      orgId +
                      '&actId=' +
                      actId +
                      '&kpi=watch' +
                      '&title=' +
                      item.title,
                  }"
                  v-else-if="item.title == '观看用户'"
                >
                  <div class="font-32 color-ff9d47">{{ item.num }}</div>
                  <div class="mt10 font-22 color-666">
                    {{ item.title }}<i class="iconfont icon-gengduo"></i>
                  </div>
                </router-link>
                <router-link
                  class=""
                  :to="{
                    path:
                      '/stic/users?orgId=' +
                      orgId +
                      '&actId=' +
                      actId +
                      '&kpi=order' +
                      '&title=' +
                      item.title,
                  }"
                  v-else-if="tabid == 1 && item.title == '下单数'"
                >
                  <div class="font-32 color-ff9d47">{{ item.num }}</div>
                  <div class="mt10 font-22 color-666">
                    {{ item.title }}<i class="iconfont icon-gengduo"></i>
                  </div>
                </router-link>
                <div v-else>
                  <div class="font-32 color-ff9d47">{{ item.num }}</div>
                  <div class="mt10 font-22 color-666">
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </div>
            <!--数据第三栏-->
            <div class="relative bbs-1-f4f4f4 last-b-none fn-flex text-center">
              <div
                class="absolute top-0 box-center cell-tt pl5 pr5 font-22 color-fff"
              >
                {{ staticGroups.title }}
              </div>
              <div
                class="fn-flex-item pt40 pb40"
                v-for="item in staticGroups.lists"
                :key="item.title"
              >
                <router-link
                  class=""
                  :to="{
                    path:
                      '/stic/users?orgId=' +
                      orgId +
                      '&actId=' +
                      actId +
                      '&kpi=beforeOrder&type=1&bizId=' +
                      userInfo.bizId +
                      '&title=' +
                      item.title,
                  }"
                  v-if="
                    (userInfo.actAdminLevel == 1 ||
                      userInfo.actAdminLevel == 2) &&
                    item.title == '留资用户'
                  "
                >
                  <div class="font-32 color-ff9d47">{{ item.num }}</div>
                  <div class="mt10 font-22 color-666">
                    {{ item.title }}<i class="iconfont icon-gengduo"></i>
                  </div>
                </router-link>
                <router-link
                  class=""
                  :to="{
                    path:
                      '/stic/users?orgId=' +
                      orgId +
                      '&actId=' +
                      actId +
                      '&kpi=beforeOrder&type=1&bizId=' +
                      bizId +
                      '&title=' +
                      item.title,
                  }"
                  v-else-if="
                    userInfo.actAdminLevel == 3 &&
                    bizId &&
                    item.title == '留资用户'
                  "
                >
                  <div class="font-32 color-ff9d47">{{ item.num }}</div>
                  <div class="mt10 font-22 color-666">
                    {{ item.title }}<i class="iconfont icon-gengduo"></i>
                  </div>
                </router-link>
                <router-link
                  class=""
                  :to="{
                    path:
                      '/stic/users?orgId=' +
                      orgId +
                      '&actId=' +
                      actId +
                      '&kpi=browse&type=1&bizId=' +
                      userInfo.bizId +
                      '&title=' +
                      item.title,
                  }"
                  v-else-if="
                    (userInfo.actAdminLevel == 1 ||
                      userInfo.actAdminLevel == 2) &&
                    item.title == '访问用户'
                  "
                >
                  <div class="font-32 color-ff9d47">{{ item.num }}</div>
                  <div class="mt10 font-22 color-666">
                    {{ item.title }}<i class="iconfont icon-gengduo"></i>
                  </div>
                </router-link>
                <router-link
                  class=""
                  :to="{
                    path:
                      '/stic/users?orgId=' +
                      orgId +
                      '&actId=' +
                      actId +
                      '&kpi=browse&type=1&bizId=' +
                      bizId +
                      '&title=' +
                      item.title,
                  }"
                  v-else-if="
                    userInfo.actAdminLevel == 3 &&
                    bizId &&
                    item.title == '访问用户'
                  "
                >
                  <div class="font-32 color-ff9d47">{{ item.num }}</div>
                  <div class="mt10 font-22 color-666">
                    {{ item.title }}<i class="iconfont icon-gengduo"></i>
                  </div>
                </router-link>
                <router-link
                  class=""
                  :to="{
                    path:
                      '/stic/users?orgId=' +
                      orgId +
                      '&actId=' +
                      actId +
                      '&kpi=subscribe&type=1&bizId=' +
                      userInfo.bizId +
                      '&title=' +
                      item.title,
                  }"
                  v-else-if="
                    (userInfo.actAdminLevel == 1 ||
                      userInfo.actAdminLevel == 2) &&
                    item.title == '预约用户'
                  "
                >
                  <div class="font-32 color-ff9d47">{{ item.num }}</div>
                  <div class="mt10 font-22 color-666">
                    {{ item.title }}<i class="iconfont icon-gengduo"></i>
                  </div>
                </router-link>
                <router-link
                  class=""
                  :to="{
                    path:
                      '/stic/users?orgId=' +
                      orgId +
                      '&actId=' +
                      actId +
                      '&kpi=subscribe&type=1&bizId=' +
                      bizId +
                      '&title=' +
                      item.title,
                  }"
                  v-else-if="
                    userInfo.actAdminLevel == 3 &&
                    bizId &&
                    item.title == '预约用户'
                  "
                >
                  <div class="font-32 color-ff9d47">{{ item.num }}</div>
                  <div class="mt10 font-22 color-666">
                    {{ item.title }}<i class="iconfont icon-gengduo"></i>
                  </div>
                </router-link>
                <router-link
                  class=""
                  :to="{
                    path:
                      '/stic/users?orgId=' +
                      orgId +
                      '&actId=' +
                      actId +
                      '&kpi=watch&type=1&bizId=' +
                      userInfo.bizId +
                      '&title=' +
                      item.title,
                  }"
                  v-else-if="
                    (userInfo.actAdminLevel == 1 ||
                      userInfo.actAdminLevel == 2) &&
                    item.title == '观看用户'
                  "
                >
                  <div class="font-32 color-ff9d47">{{ item.num }}</div>
                  <div class="mt10 font-22 color-666">
                    {{ item.title }}<i class="iconfont icon-gengduo"></i>
                  </div>
                </router-link>
                <router-link
                  class=""
                  :to="{
                    path:
                      '/stic/users?orgId=' +
                      orgId +
                      '&actId=' +
                      actId +
                      '&kpi=watch&type=1&bizId=' +
                      bizId +
                      '&title=' +
                      item.title,
                  }"
                  v-else-if="
                    userInfo.actAdminLevel == 3 &&
                    bizId &&
                    item.title == '观看用户'
                  "
                >
                  <div class="font-32 color-ff9d47">{{ item.num }}</div>
                  <div class="mt10 font-22 color-666">
                    {{ item.title }}<i class="iconfont icon-gengduo"></i>
                  </div>
                </router-link>
                <router-link
                  class=""
                  :to="{
                    path:
                      '/stic/users?orgId=' +
                      orgId +
                      '&actId=' +
                      actId +
                      '&kpi=order&type=1&bizId=' +
                      userInfo.bizId +
                      '&title=' +
                      item.title,
                  }"
                  v-else-if="
                    (userInfo.actAdminLevel == 1 ||
                      userInfo.actAdminLevel == 2) &&
                    tabid == 1 &&
                    item.title == '下单数'
                  "
                >
                  <div class="font-32 color-ff9d47">{{ item.num }}</div>
                  <div class="mt10 font-22 color-666">
                    {{ item.title }}<i class="iconfont icon-gengduo"></i>
                  </div>
                </router-link>
                <router-link
                  class=""
                  :to="{
                    path:
                      '/stic/users?orgId=' +
                      orgId +
                      '&actId=' +
                      actId +
                      '&kpi=order&type=1&bizId=' +
                      bizId +
                      '&title=' +
                      item.title,
                  }"
                  v-else-if="
                    userInfo.actAdminLevel == 3 &&
                    bizId &&
                    tabid == 1 &&
                    item.title == '下单数'
                  "
                >
                  <div class="font-32 color-ff9d47">{{ item.num }}</div>
                  <div class="mt10 font-22 color-666">
                    {{ item.title }}<i class="iconfont icon-gengduo"></i>
                  </div>
                </router-link>
                <div v-else>
                  <div class="font-32 color-ff9d47">{{ item.num }}</div>
                  <div class="mt10 font-22 color-666">
                    {{ item.title }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="inner30 pt0 fn-hide" id="lineChartBox">
        <div
          class="relative bg-fff inner40 pr30 overflowhidden box-shadow-lightGray ulib-r20 z-index-0"
        >
          <div class="pb20 fn-clear">
            <b
              class="fn-fl font-40 text-italic"
              v-html="lineChartData.title"
            ></b>
          </div>
          <div style="overflow-x: auto">
            <div
              id="lineChart"
              class="height-710"
              ref="lineChart"
              :style="
                'width:' +
                [
                  lineChartData.xAxisData.length > 5
                    ? lineChartData.xAxisData.length * 20 + '%'
                    : '100%',
                ]
              "
            ></div>
          </div>
          <div
            class="mt15 text-center font-22 color-999"
            v-if="lineChartData.xAxisData.length > 5"
          >
            向右拖动图表查看更多
          </div>
        </div>
      </div>
      <!--机构列表-->
      <div
        class="inner30 pt10"
        v-if="userInfo.actAdminLevel == '3' && bizId == ''"
      >
        <div
          class="relative mb30 bg-f6 inner30 ulib-r20 box-shadow-lightGray"
          v-for="(item, index) in list"
          :key="index"
          @click="pickGroup(index)"
        >
          <div class="text-nowrap wp60 font-32">{{ item.title }}</div>
          <div class="absolute right-0 box-middle font-28">
            <span v-if="!item.ifBiz">{{ item.note }}</span>
            <span class="pl15 pr15 color-ff9d47" v-if="!item.ifBiz">{{
              item.num
            }}</span>
            <i class="iconfont icon-gengduo color-666"></i>
          </div>
        </div>
        <mugenscroll
          :handler="fetchData"
          :should-handle="!loading"
          scroll-container="scrollContainer"
        >
          <div class="pt100 pb100 text-center color-ccc" v-if="isNoData">
            <span class="text-middle font-22">暂无数据</span>
          </div>
          <div class="pt20 pb20 text-center color-ccc" v-else-if="loading">
            <i class="iconfont icon-jiazai text-middle"></i
            ><span class="text-middle font-22">请稍等...</span>
          </div>
          <div
            class="pt20 pb20 text-center font-22 color-ccc"
            v-else-if="isDataEnd && list.length > 10"
          >
            我们是有底线的
          </div>
        </mugenscroll>
      </div>

      <!--实时动态-->
      <div
        class="ml30 mr30 mb20 relative bg-fff inner40 pr30 overflowhidden box-shadow-lightGray ulib-r20"
        v-else-if="
          userInfo.actAdminLevel == '1' || userInfo.actAdminLevel == '2'
        "
      >
        <div class="pb20 fn-clear">
          <b class="fn-fl font-40 text-italic" v-html="dynamic.title"></b>
        </div>
        <div class="font-28" v-for="(item, index) in dynamic.list" :key="index">
          <div class="pt20 pb20 fn-clear">
            <div class="fn-fl wp70 text-nowrap">
              <span class="text-middle">{{ item.nickname }}</span>
              <span class="text-middle color-666">{{ item.action }}</span>
            </div>
            <div class="fn-fr">{{ item.time2 }}</div>
          </div>
        </div>
        <mugenscroll
          :handler="fetchData"
          :should-handle="!loading"
          scroll-container="scrollContainer"
        >
          <div class="pt100 pb100 text-center color-ccc" v-if="isNoData">
            <span class="text-middle font-22">暂无数据</span>
          </div>
          <div class="pt20 pb20 text-center color-ccc" v-else-if="loading">
            <i class="iconfont icon-jiazai text-middle"></i
            ><span class="text-middle font-22">请稍等...</span>
          </div>
          <div
            class="pt20 pb20 text-center font-22 color-ccc"
            v-else-if="isDataEnd && dynamic.list.length > 10"
          >
            我们是有底线的
          </div>
        </mugenscroll>
      </div>
      <!-- end 实时动态-->
    </div>

    <navBar name="stic" :orgId="orgId" :actId="actId"></navBar>
  </div>
</template>
<script>
import mugenscroll from "vue-mugen-scroll";
import navBar from "../../components/navBar";
import { hiddenShare } from "../../assets/js/utils/wexinShare.js";
let Echarts = require("echarts/lib/echarts"); //基础实例 注意不要使用import
require("echarts/lib/chart/line");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/legend");
require("echarts/lib/component/grid");
import $ from "jquery";
export default {
  components: { mugenscroll, navBar },
  data() {
    return {
      orgId: this.$route.query.orgId,
      actId: this.$route.query.actId,
      group: "",
      theme: "",
      skey: "",
      tab: [
        {
          id: 0,
          title: "预热阶段",
        },
        {
          id: 1,
          title: "直播阶段",
        },
      ],

      tabFixed: false,
      tabid: 0, //0预热阶段  /1直播阶段

      loading: false,
      isDataEnd: false,
      isNoData: false,
      page: 1, //页数
      size: 20, //每页取多少个数据
      list: [],

      userInfo: {},
      dynamic: {
        title: '实时<span class="color-ff9d47">动态</span>',
        list: [],
      },
      lineChartData: {
        xAxisData: [],
      },
      lineChart: null,
      groupsId: "",
      bizId: "",
      myStatic: {
        mine: {},
      },
      staticGroups: "",
      orgInfo: "",
      showLive: true,
    };
  },

  created: function () {
    this.getHiddenWxSign();
    this.getAct();
    this.getOrg();
  },

  mounted: function () {
    window.addEventListener("scroll", this.handleScroll, true);

    // //浏览器禁止返回
    // if (window.history && window.history.pushState) {
    //   $(window).on("popstate", function () {
    //     // 当点击浏览器的 后退和前进按钮 时才会被触发，
    //     window.history.pushState("forward", null, "");
    //     window.history.forward(1);
    //   });
    // }
    // window.history.pushState("forward", null, ""); //在IE中必须得有这两行
    // window.history.forward(1);
  },
  //离开页面时
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    //获取微信签名数据
    getHiddenWxSign() {
      let params = {};
      params["url"] = window.location.href;
      this.$get(this.$api.wxSign, params).then((res) => {
        let obj = {
          appId: res.data.signPackage.appid,
          nonceStr: res.data.signPackage.noncestr,
          signature: res.data.signPackage.signature,
          timestamp: res.data.signPackage.timestamp,
        };
        hiddenShare(obj);
      });
    },

    //退出
    quit() {
      let that = this;
      that.$store.dispatch("setToken", "");
      that.$store.dispatch("setIsAdminLogin", false);
      that.$toast({
        message: "退出成功",
        duration: 1500,
      });
      setTimeout(function () {
        that.$router.push({ path: "login" });
      }, 1500);
    },

    //判断导航是否需要吸顶
    handleScroll() {
      let scrollTop = document.querySelector(".scrollContainer").scrollTop;
      let mainoffsetTop = document.querySelector("#main").offsetTop;
      if (scrollTop > mainoffsetTop) {
        this.tabFixed = true;
      } else {
        this.tabFixed = false;
      }
    },

    //获取基础信息
    getAct() {
      let params = {};
      params["orgId"] = this.orgId;
      params["actId"] = this.actId;
      this.$get(this.$api.bizAct, params).then((res) => {
        window.document.title = res.data.title;
        if (res.data.banner) {
          this.theme = res.data.banner;
        }
        this.showLive = res.data.showLive;
        this.skey = res.data.skey;
        this.$store.dispatch("setSkey", res.data.skey);

        let timeStart = Date.parse(res.data.timeStart);
        let curTime = new Date().getTime();
        if (res.data.showLive && curTime > timeStart) {
          this.tabid = 1;
        }

        //获取统计数据
        this.getStatic();
        this.getStaticGroups();

        //更新用户信息
        let params2 = {};
        params2["skey"] = res.data.skey;
        params2["actId"] = this.actId;
        this.$get(this.$api.userInfo, params2).then((res2) => {
          this.$store.dispatch("setUserInfo", res2.data);
          this.userInfo = res2.data;
          if (this.userInfo.actAdminLevel == 3) {
            this.getGroupLists();
          } else if (
            this.userInfo.actAdminLevel == 1 ||
            this.userInfo.actAdminLevel == 2
          ) {
            this.getDynamicLists();
          }
        });
      });
    },

    //tab切换
    changeTab(id) {
      if (id != this.tabid && !this.loading) {
        if (this.tabFixed) {
          let mainoffsetTop = document.querySelector("#main").offsetTop;
          $(".scrollContainer").animate({ scrollTop: mainoffsetTop + 5 }, 500);
        }
        this.tabid = id;
        this.getStatic();
        this.getStaticGroups();
        if (this.bizId) {
          this.getBizStaticLine();
        }

        if (
          this.userInfo.actAdminLevel == 1 ||
          this.userInfo.actAdminLevel == 2
        ) {
          this.loading = false;
          this.isDataEnd = false;
          this.isNoData = false;
          this.page = 1;
          this.dynamic.list = [];
          this.getDynamicLists();
        }
      }
    },

    //获取统计数据
    getStatic() {
      let params = {};
      params["orgId"] = this.orgId;
      params["actId"] = this.actId;
      params["type"] = this.tabid;
      this.$get(this.$api.bizStatic, params).then((res) => {
        this.myStatic = res.data;
      });
    },

    //根据分组id获取统计数据
    getStaticGroups() {
      let params = {};
      params["orgId"] = this.orgId;
      params["actId"] = this.actId;
      params["type"] = this.tabid;
      if (this.groupsId || this.bizId) {
        params["groupsId"] = this.bizId ? this.bizId : this.groupsId;
      }
      this.$get(this.$api.bizStaticGroups, params).then((res) => {
        this.staticGroups = res.data;
      });
    },

    //拉取数据
    fetchData: function () {
      if (this.userInfo.actAdminLevel == "3") {
        //门店列表
        this.getGroupLists();
      } else if (
        this.userInfo.actAdminLevel == "1" ||
        this.userInfo.actAdminLevel == "2"
      ) {
        //动态
        this.getDynamicLists();
      }
    },

    //获取大区列表
    getGroupLists() {
      let that = this;
      if (!that.isNoData && !that.isDataEnd && !that.loading) {
        that.loading = true;
        let params = {};
        params["orgId"] = that.orgId;
        params["actId"] = that.actId;
        if (that.groupsId) {
          params["groupsId"] = that.groupsId;
        }
        params["page"] = that.page;
        params["size"] = that.size;
        that.$get(that.$api.bizGroupLists, params).then((res) => {
          that.loading = false;
          that.page = that.page + 1;
          that.list =
            res.data.lists && res.data.lists.length > 0
              ? that.list.concat(res.data.lists)
              : that.list;
          if (res.data.total == 0) {
            that.isNoData = true;
          } else if (that.list.length == res.data.total) {
            that.isDataEnd = true;
          }
        });
      }
    },

    //选择机构
    pickGroup(index) {
      if (this.list[index].ifBiz) {
        this.bizId = this.list[index].groupsId;
        this.group = this.group + this.list[index].title + " ";
        this.loading = false;
        this.isDataEnd = false;
        this.isNoData = false;
        this.page = 1;
        this.list = [];
        this.getBizStaticLine();
      } else {
        this.groupsId = this.list[index].groupsId;
        this.group = this.group + this.list[index].title + " ";
        this.loading = false;
        this.isDataEnd = false;
        this.isNoData = false;
        this.page = 1;
        this.list = [];
        this.getGroupLists();
      }
      this.getStaticGroups();
    },

    //获取门店曲线图数据
    getBizStaticLine() {
      $("#lineChart").hide();
      let that = this;
      let params = {};
      params["orgId"] = that.orgId;
      params["actId"] = that.actId;
      params["bizId"] = that.bizId;
      params["type"] = that.tabid;
      that.$get(that.$api.bizStaticLine, params).then((res) => {
        $("#lineChartBox").show();
        $("#lineChart").show();
        that.lineChartData = res.data;
        let time = [];
        if (
          that.tabid == 0 &&
          that.lineChartData.xAxisData &&
          that.lineChartData.xAxisData.length > 0
        ) {
          that.lineChartData.xAxisData.forEach((item) => {
            let date = new Date(item);
            let month = date.getMonth() + 1;
            if (month < 10) {
              month = "0" + month;
            }
            let date1 = date.getDate();
            if (date1 < 10) {
              date1 = "0" + date1;
            }
            time.push(month + "." + date1);
          });
        }
        if (
          that.tabid == 1 &&
          that.lineChartData.xAxisData &&
          that.lineChartData.xAxisData.length > 0
        ) {
          that.lineChartData.xAxisData.forEach((item) => {
            let date = new Date(item);
            let hour = date.getHours();
            if (hour < 10) {
              hour = "0" + hour;
            }
            let minutes = date.getMinutes();
            if (minutes < 10) {
              minutes = "0" + minutes;
            }
            time.push(hour + ":" + minutes);
          });
        }
        that.lineChart = Echarts.init(that.$refs.lineChart);
        let option = {
          tooltip: {
            trigger: "axis",
          },
          legend: {
            x: "left",
            top: "bottom",
            data: res.data.legendData,
          },
          grid: {
            top: "4%",
            left: "2%",
            right: "3%",
            bottom: "11%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            boundaryGap: false,
            data: time,
          },
          yAxis: {
            type: "value",
          },
          series: res.data.seriesData,
        };
        // 4.传入数据
        that.lineChart.setOption(option);
      });
    },

    //获取实时动态
    getDynamicLists() {
      let that = this;
      if (!that.isNoData && !that.isDataEnd && !that.loading) {
        that.loading = true;
        let params = {};
        params["orgId"] = that.orgId;
        params["actId"] = that.actId;
        params["type"] = that.tabid;
        params["page"] = that.page;
        params["size"] = that.size;
        that.$get(that.$api.bizDynamicLists, params).then((res) => {
          that.loading = false;
          that.page = that.page + 1;
          let list = res.data.lists;
          if (list && list.length > 0) {
            list.forEach((item) => {
              let date = new Date(item.time);
              let month = date.getMonth() + 1;
              if (month < 10) {
                month = "0" + month;
              }
              let date1 = date.getDate();
              if (date1 < 10) {
                date1 = "0" + date1;
              }
              let hour = date.getHours();
              if (hour < 10) {
                hour = "0" + hour;
              }
              let minutes = date.getMinutes();
              if (minutes < 10) {
                minutes = "0" + minutes;
              }
              item.time2 = month + "." + date1 + " " + hour + ":" + minutes;
            });
            that.dynamic.list = that.dynamic.list.concat(list);
          }
          if (res.data.total == 0) {
            that.isNoData = true;
          } else if (that.dynamic.list.length == res.data.total) {
            that.isDataEnd = true;
          }
        });
      }
    },

    //获取机构详情
    getOrg() {
      let params = {};
      params["orgId"] = this.orgId;
      this.$get(this.$api.getOrg, params).then((res) => {
        this.orgInfo = res.data;
      });
    },
  },
  watch: {
    //监听路由地址的改变
    $route: {
      immediate: true,
      handler() {
        if (this.$route.query.vnum) {
          this.tabid = 0;
          this.loading = false;
          this.isDataEnd = false;
          this.isNoData = false;
          this.page = 1;
          this.list = [];
          this.userInfo = {};
          this.dynamic = {
            title: '实时<span class="color-ff9d47">动态</span>',
            list: [],
          };
          this.lineChartData = {
            xAxisData: [],
          };
          this.lineChart = null;
          this.groupsId = "";
          this.bizId = "";
          this.myStatic = {
            mine: {},
          };
          this.staticGroups = "";
          this.getAct();
        }
      },
    },
  },
  // beforeRouteEnter(to, from, next) {
  //   if (from.name == "login") {
  //     to.meta.keepAlive = true;
  //   } else {
  //     to.meta.keepAlive = false;
  //   }
  //   next();
  // },
  // beforeRouteLeave(to, from, next) {
  //   if (to.name == "sticUsers") {
  //     from.meta.keepAlive = true;
  //   } else {
  //     from.meta.keepAlive = false;
  //   }
  //   next(); //用户离开
  // },
};
</script>
