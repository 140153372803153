const Config = {};

Config.key = 'Aj51@#KSUX88';//加密key

if (process.env.NODE_ENV == "development") {
    // Config.baseURL = "https://cloud.dev.haodian.cn/syt/";
    // Config.appId = 'wxf098745a02ce32b7',//授权登录公众号appId
    // Config.template_id = 'zS57DfMViRBWCq4DVdMJ8uSuUz26eoWde2AmwyiNsNc'; //公众号一次性订阅消息模板ID
    Config.baseURL = "https://cloud.haodian.cn/syt/";
    Config.appId = 'wxf098745a02ce32b7',//授权登录公众号appId
    Config.template_id = 'zS57DfMViRBWCq4DVdMJ8uSuUz26eoWde2AmwyiNsNc'; //公众号一次性订阅消息模板ID
} else if (process.env.NODE_ENV == "t") {
    Config.baseURL = "https://cloud.dev.haodian.cn/syt/";
    Config.appId = 'wxf098745a02ce32b7',//授权登录公众号appId
    Config.template_id = 'zS57DfMViRBWCq4DVdMJ8uSuUz26eoWde2AmwyiNsNc'; //公众号一次性订阅消息模板ID
} else if (process.env.NODE_ENV == "production") {
    // Config.baseURL = "https://cloud.dev.haodian.cn/syt/";
    // Config.appId = 'wxf098745a02ce32b7',//授权登录公众号appId
    // Config.template_id = 'zS57DfMViRBWCq4DVdMJ8uSuUz26eoWde2AmwyiNsNc'; //公众号一次性订阅消息模板ID
    
    
    Config.baseURL = "https://cloud.haodian.cn/syt/";
    Config.appId = 'wxf098745a02ce32b7',//授权登录公众号appId
    Config.template_id = 'zS57DfMViRBWCq4DVdMJ8uSuUz26eoWde2AmwyiNsNc'; //公众号一次性订阅消息模板ID

    // Config.appId = 'wxbdec8558931efffd',//授权登录公众号appId
    // Config.template_id = 'DO0B9IYYub1d0oNvy9czzGbe6_1EU8PQmnLEoDOcmXA'; //公众号一次性订阅消息模板ID
}

module.exports = Config;