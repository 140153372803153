<template>
  <div class="scrollContainer font-12" ref="scrollContainer">
    <div
      class="container relative bg-no-repeat bg-size-fullwidth bg-pos-top pb50"
      style="
        background-image: url(https://qs.haodian.cn/web/images/project/H5-ShiYu/mine-bg2.jpg);
      "
    >
      <div class="pt50 pl30 pr30 text-center" @click="renewaAuth()">
        <img
          class="bds-1-fff imgsize-120X120 ulib-r750"
          :src="userInfo.headimg"
          alt="#"
        />
        <div class="pt10 font-36 text-center">{{ userInfo.nickname }}</div>
        <div class="mt40">
          <img
            class="block wp100"
            src="https://qs.haodian.cn/web/images/project/H5-ShiYu/mine-theme.png?v=230609"
            alt="尊享您的直播好礼"
          />
        </div>
      </div>
      <div
        class="bg-fff ml30 mr30 pt20 pb20 pl40 pr40 ulib-r20 box-shadow-darkGray"
      >
        <router-link
          :to="{ path: '/ucenter/orders?skey=' + skey }"
          class="block relative pt30 pb30 bbs-1-eee last-b-none"
        >
          <span class="font-32">我的权益</span>
          <i
            class="absolute box-middle right-0 iconfont icon-gengduo font-22"
          ></i>
        </router-link>
        <router-link
          :to="{ path: '/ucenter/win?skey=' + skey }"
          class="block relative pt30 pb30 bbs-1-eee last-b-none"
        >
          <span class="font-32">我的抽奖</span>
          <i
            class="absolute box-middle right-0 iconfont icon-gengduo font-22"
          ></i>
        </router-link>
        <a
          :href="actInfo.serviceLink"
          class="block relative pt30 pb30 bbs-1-eee last-b-none"
          v-if="actInfo.serviceLink"
        >
          <span class="font-32">联系客服</span>
          <i
            class="absolute box-middle right-0 iconfont icon-gengduo font-22"
          ></i>
        </a>
      </div>
    </div>
    <backHome></backHome>
  </div>
</template>
<script>
import { wexinShare } from "../../assets/js/utils/wexinShare.js";
import Config from "../../config.js";
import backHome from "../../components/backHome";
export default {
  components: { backHome },
  data() {
    return {
      skey: this.$route.query.skey,
      toPath: "", //授权用户信息返回时使用
      userInfo: {},
      actInfo: {},
    };
  },

  created: function () {
    this.getWxSign();
    this.getUserInfo();
    this.getActivity();
  },

  mounted: function () {},
  methods: {
    //获取微信签名数据
    getWxSign() {
      let params = {};
      params["skey"] = this.$store.getters.getSkey;
      params["url"] = window.location.href;
      this.$get(this.$api.wxSign, params).then((res) => {
        let obj = {
          appId: res.data.signPackage.appid,
          nonceStr: res.data.signPackage.noncestr,
          signature: res.data.signPackage.signature,
          timestamp: res.data.signPackage.timestamp,
        };

        let shareData = {
          title: res.data.title, // 分享标题
          desc: res.data.desc,
          link:
            "https://" +
            document.domain +
            "/act?skey=" +
            this.$store.getters.getSkey,
          imgUrl: res.data.img, // 分享图标
        };
        //引用
        wexinShare(obj, shareData);
      });
    },

    //更新用户信息
    getUserInfo() {
      let params = {};
      params["skey"] = this.skey;
      this.$get(this.$api.userInfo, params).then((res) => {
        this.userInfo = res.data;
        this.$store.dispatch("setUserInfo", res.data);
      });
    },

    //获取活动信息
    getActivity() {
      let params = {};
      params["skey"] = this.skey;
      this.$get(this.$api.activity, params).then((res) => {
        this.actInfo = res.data;
      });
    },

    //更新用户信息
    renewaAuth() {
      sessionStorage.setItem("userUedirectPath", this.toPath);
      let redirectURL = encodeURIComponent(
        window.location.href.split("&code")[0]
      ); //获取地址
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${Config.appId}&redirect_uri=${redirectURL}&response_type=code&scope=snsapi_userinfo&state=syauthuserinfo#wechat_redirect`;
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      //这里的vm指的就是vue实例，可以用来当做this使用
      vm.toPath = to.fullPath;
      vm.fromPath = from.path;
    });
  },
};
</script>
