<template>
  <div
    class="scrollContainer font-12 wp100 overflowhidden"
    ref="scrollContainer"
  >
    <div class="poster2">
      <div class="detail-banner relative p-swiper">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide bg-size-cover"
              v-for="(item, index) in list"
              :key="index"
            >
              <div class="relative">
                <img
                  class="wp100 block"
                  style="pointer-events: none"
                  :src="item.img"
                />
                <div
                  class="absolute left-0 right-0 bottom-0 mb30 text-center font-22 color-fff"
                >
                  {{ posterTip }}
                </div>
                <img
                  class="absolute right-0 bottom-0 mr40 mb80 imgsize-160X160"
                  style="pointer-events: none"
                  :src="'data:image/png;base64,' + item.code"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="swiper-pagination ulib-rl750 font-22"></div>
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>

    <!-- <div class="bts-1-eee inner30">
      <div class="text-center">分享文案</div>
      <div
        class="mt30 relative bds-1-eee inner30 pr200 ulib-r20 font-28"
        v-for="(item, index) in shareTx"
        :key="index"
      >
        <span>{{ item }}</span>
        <a
          class="absolute right-0 box-middle mr30 inline-block pl30 pr30 line-height-17 bg-eee ulib-r750 J_copy"
          @click="copy"
          :data-clipboard-text="item"
          >复制</a
        >
      </div>
    </div> -->

    <div @click="showPoster">
      <div style="height: 70px"></div>
      <div
        class="fixed left-0 bottom-0 right-0 bg-000-op80 pt20 pb20 pl30 pr30 text-center z-index-4"
      >
        <button
          class="wp100 bg-fff border-none pt30 pb30 font-32 color-1a1a1a ulib-r10"
        >
          生成海报
        </button>
      </div>
    </div>

    <div
      class="msg fn-hide"
      :style="msgisShowPoster ? 'display:block' : 'display:none'"
    >
      <div class="msgBgPoster" @click="closePoster"></div>
      <div class="msgMain">
        <i @click="closePoster" class="posterClose" v-if="posterSrc"
          ><span></span
        ></i>
        <div class="msgPoster">
          <div class="postercon overflowhidden ulib-r20">
            <div style="display: none">
              <canvas id="canvas"></canvas>
            </div>
            <div v-if="posterSrc">
              <img class="wp100 ulib-r20" :src="posterSrc" alt="" />
            </div>
          </div>
          <div
            class="pt30 pb10 text-center text-center font-30 color-fff"
            v-if="posterSrc"
          >
            <img
              class="inline-block imgsize-42X42 text-middle"
              src="https://qs.haodian.cn/web/images/project/H5-ShiYu/icon-finger.png"
              alt=""
            />
            <span class="ml10 text-middle">长按图片保存到手机发送给好友</span>
          </div>
        </div>
      </div>
    </div>
    <backHome></backHome>
  </div>
</template>
<script>
import Swiper from "swiper";
import Clipboard from "clipboard";
import backHome from "../../components/backHome";
import { wexinShare } from "../../assets/js/utils/wexinShare.js";
import $ from "jquery";
export default {
  components: { backHome },
  data() {
    return {
      skey: this.$route.query.skey,
      list: [],
      msgisShowPoster: false,
      canW: 750,
      canH: 0,
      activeIndex: 0,
      posterSrc: "",
      posterTip: "",
      // shareTx: [
      //   "分享文案内容1分享文案内容1分享文案内容1分享文案内容1分享文案内容1分享文案内容1分享文案内容1",
      //   "分享文案内容2",
      //   "分享文案内容3",
      // ],
    };
  },

  created: function () {
    this.getShare();
    this.getWxSign();
  },
  mounted() {},
  methods: {
    //获取分享海报信息
    getShare() {
      let params = {};
      params["skey"] = this.skey;
      this.$get(this.$api.share, params).then((res) => {
        this.list = res.data.imgLists;
        this.posterTip = res.data.posterTip;
        setTimeout(() => {
          this.myswiper();
        }, 100);
      });
    },

    //获取微信签名数据
    getWxSign() {
      let params = {};
      params["skey"] = this.$store.getters.getSkey;
      params["url"] = window.location.href;
      this.$get(this.$api.wxSign, params).then((res) => {
        let obj = {
          appId: res.data.signPackage.appid,
          nonceStr: res.data.signPackage.noncestr,
          signature: res.data.signPackage.signature,
          timestamp: res.data.signPackage.timestamp,
        };

        let shareData = {
          title: res.data.title, // 分享标题
          desc: res.data.desc,
          link:
            "https://" +
            document.domain +
            "/act?skey=" +
            this.$store.getters.getSkey,
          imgUrl: res.data.img, // 分享图标
        };
        //引用
        wexinShare(obj, shareData);
      });
    },

    //轮播
    myswiper() {
      let that = this;
      new Swiper(".p-swiper .swiper-container", {
        //loop: true,
        autoHeight: true,
        pagination: {
          el: ".swiper-pagination",
          type: "fraction",
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        on: {
          slideChangeTransitionEnd: function () {
            that.activeIndex = this.activeIndex;
          },
        },
      });
    },

    //复制文字
    copy() {
      let clipboard = new Clipboard(".J_copy");
      clipboard.on("success", () => {
        this.$toast({
          message: "复制成功！",
          duration: 1500,
        });
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 释放内存
        clipboard.destroy();
      });
    },

    showPoster: function () {
      this.posterSrc = "";
      this.draw();
    },
    closePoster: function () {
      this.msgisShowPoster = false;
    },

    //海报生成
    draw() {
      let that = this;
      that.$toast.loading({
        message: "生成中...",
      });
      that.canH =
        (that.list[that.activeIndex].height /
          that.list[that.activeIndex].width) *
        that.canW;
      $("#canvas").attr({
        width: that.canW,
        height: that.canH,
      });
      let canvas = document.getElementById("canvas");
      let ctx = canvas.getContext("2d");
      let imgBg = new Image();
      imgBg.setAttribute("crossOrigin", "Anonymous"); //如果二维码图片域名跨域，则保留此代码
      imgBg.src = that.list[that.activeIndex].img;
      imgBg.onload = function () {
        ctx.drawImage(
          imgBg,
          0,
          0,
          that.list[that.activeIndex].width,
          that.list[that.activeIndex].height,
          0,
          0,
          that.canW,
          that.canH
        );
        let codeimg = new Image();
        codeimg.setAttribute("crossOrigin", "Anonymous"); //如果二维码图片域名跨域，则保留此代码
        codeimg.src =
          "data:image/png;base64," + that.list[that.activeIndex].code;
        codeimg.onload = function () {
          ctx.fillStyle = "#fff";
          ctx.textAlign = "center";
          ctx.font = "22px Georgia";
          ctx.fillText(that.posterTip, that.canW / 2, that.canH - 34);
          ctx.drawImage(codeimg, that.canW - 200, that.canH - 240, 160, 160);
          setTimeout(function () {
            that.posterSrc = canvas.toDataURL("image/jpg");
            that.msgisShowPoster = true;
            that.$toast.clear();
          }, 200);
        };
      };
    },
  },
};
</script>
<style lang="scss" scoped>
@import url("../../assets/css/swiper.css");
</style>
