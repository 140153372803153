<template>
  <div
    class="scrollContainer font-12"
    id="scrollContainer"
    ref="scrollContainer"
  >
    <div class="container pb130">
      <router-link class="block relative" :to="{ path: '/act?skey=' + skey }">
        <div
          class="absolute box-center-middle pt15 pb15 pl30 pr20 bg-000-op80 font-30 color-fff ulib-r750"
        >
          进入活动<i class="iconfont icon-gengduo font-22"></i>
        </div>
        <img class="block imgsize-750X340" :src="theme"
      /></router-link>
      <div id="main" class="inner30">
        <div v-if="tabFixed">
          <div class="height-60"></div>
          <div
            class="fixed top-0 left-0 right-0 z-index-10 bg-fff pl30 pr30 pt20 pb20"
          >
            <div
              class="relative height-70 ml50 mr50 fn-flex text-center"
              v-if="tab.length < 5"
            >
              <div
                class="fn-flex-item relative tab-menu2 pt10 pb10"
                :class="[kpi == item.value ? 'font-36 active' : 'font-28']"
                v-for="(item, index) in tab"
                :key="index"
              >
                <a
                  class="relative inline-block"
                  :class="[kpi == item.value ? '' : 'pt10']"
                  @click="changeTab(item.value, index)"
                  href="javascript:"
                  >{{ item.title }}</a
                >
              </div>
            </div>
            <div
              class="relative height-70 space-nowrap text-center scroll-x"
              v-else
            >
              <div
                class="inline-block relative tab-menu2 pt10 pb10 pl25 pr25"
                :class="[kpi == item.value ? 'font-36 active' : 'font-28']"
                v-for="(item, index) in tab"
                :key="index"
              >
                <a
                  class="relative inline-block"
                  :class="[kpi == item.value ? '' : 'pt10']"
                  @click="changeTab(item.value, index)"
                  href="javascript:"
                  >{{ item.title }}</a
                >
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div
            class="relative height-70 ml50 mr50 fn-flex text-center"
            v-if="tab.length < 5"
          >
            <div
              class="fn-flex-item relative tab-menu2 pt10 pb10"
              :class="[kpi == item.value ? 'font-36 active' : 'font-28']"
              v-for="(item, index) in tab"
              :key="index"
            >
              <a
                class="relative inline-block"
                :class="[kpi == item.value ? '' : 'pt10']"
                @click="changeTab(item.value, index)"
                href="javascript:"
                >{{ item.title }}</a
              >
            </div>
          </div>
          <div
            class="relative height-70 space-nowrap text-center scroll-x"
            v-else
          >
            <div
              class="inline-block relative tab-menu2 pt10 pb10 pl25 pr25"
              :class="[kpi == item.value ? 'font-36 active' : 'font-28']"
              v-for="(item, index) in tab"
              :key="index"
            >
              <a
                class="relative inline-block"
                :class="[kpi == item.value ? '' : 'pt10']"
                @click="changeTab(item.value, index)"
                href="javascript:"
                >{{ item.title }}</a
              >
            </div>
          </div>
        </div>

        <div
          class="mt30 bg-fff7f1 pt15 pb15 overflowhidden text-center font-22 color-ff9d47 ulib-r20"
          v-if="kpi == 'watch'"
        >
          温馨提示：直播结束后6小时同步数据
        </div>

        <div
          class="mt30 relative bg-fff inner40 pr30 overflowhidden box-shadow-lightGray ulib-r20"
        >
          <div class="pb20 fn-clear text-center">
            <b class="font-40 text-italic" v-html="panelTitle"></b>
            <!-- <div class="fn-fr">
              <select
                class="select-more box-shadow-lightGray ulib-r10 font-22"
                @change="handleChange($event, 'group')"
              >
                <option
                  :value="index"
                  v-for="(item, index) in filters.typeLists"
                  :key="index"
                >
                  {{ item.title }}
                </option>
              </select>
              <select
                class="select-more box-shadow-lightGray ulib-r10 font-22"
                @change="handleChange($event, 'date')"
              >
                <option
                  :value="index"
                  v-for="(item, index) in filters.dayLists2"
                  :key="index"
                >
                  {{ item }}
                </option>
              </select>
            </div> -->
          </div>
          <div class="fn-flex">
            <select
              class="fn-flex-item select-more box-shadow-lightGray ulib-r10 font-22 mr20"
              @change="handleChange($event, 'group')"
            >
              <option
                :value="index"
                v-for="(item, index) in filters.typeLists"
                :key="index"
              >
                {{ item.title }}
              </option>
            </select>
            <select
              class="fn-flex-item select-more box-shadow-lightGray ulib-r10 font-22"
              @change="handleChange($event, 'date')"
            >
              <option
                :value="index"
                v-for="(item, index) in filters.dayLists2"
                :key="index"
              >
                {{ item }}
              </option>
            </select>
            <select
              class="fn-flex-item select-more box-shadow-lightGray ulib-r10 font-22 ml20"
              @change="handleChange($event, 'goods')"
              v-if="kpi == 'allOrder'"
            >
              <option
                :value="index"
                v-for="(item, index) in goodsList"
                :key="index"
              >
                {{ item.title }}
              </option>
            </select>
          </div>
          <div class="pr10" v-if="list.length > 0">
            <div class="height-500 relative overflowhidden">
              <div id="pieChartBox" ref="pieChartBox" class="height-500"></div>
            </div>
            <div
              class="font-32 color-666"
              v-for="(item, index) in list"
              :key="index"
            >
              <div class="inner20 fn-clear ulib-r20">
                <div class="fn-fl block wp70 text-nowrap">
                  <img
                    class="imgsize-42X42 text-middle"
                    v-if="index < 3"
                    :src="
                      'https://qs.haodian.cn/web/images/project/H5-ShiYu/icon-ranking-' +
                      parseInt(index + 1) +
                      '.png'
                    "
                    alt="#"
                  />
                  <div
                    class="inline-block relative imgsize-32X32 ml5 mr5 mb5 bg-ccc text-middle ulib-r750 font-0 line-height-11"
                    v-else
                  >
                    <span
                      class="absolute box-center-middle text-bold font-18 color-fff"
                      >{{ index + 1 }}</span
                    >
                  </div>
                  <span class="text-middle">{{ item.title }}</span>
                  <!-- <span
                    class="inline-block pl10 pr10 line-height-15 text-middle bg-fe9538 font-22 color-fff ulib-r750"
                    v-if="item.tip"
                    >{{ item.tip }}</span
                  > -->
                  <!-- <i
                    class="iconfont icon-gengduo text-middle font-24 color-666"
                  ></i> -->
                </div>
                <div class="fn-fr">{{ item.num }}</div>
              </div>
            </div>
            <div
              class="gengduo block absolute bottom-0 box-center pl10 text-center font-22 color-fff"
              v-if="!(isDataEnd || isNoData)"
              @click="pushmore"
            >
              查看更多<i class="iconfont icon-gengduo"></i>
            </div>
          </div>
          <div class="pt100 pb100 text-center font-32 color-666" v-else>
            {{ page == 2 ? "暂时无数据" : "" }}
          </div>
        </div>
      </div>
    </div>

    <navBar name="ranking" :orgId="orgId" :actId="actId"></navBar>
  </div>
</template>
<script>
import navBar from "../../components/navBar";
import { hiddenShare } from "../../assets/js/utils/wexinShare.js";
let Echarts = require("echarts/lib/echarts"); //基础实例 注意不要使用import
require("echarts/lib/chart/pie");
require("echarts/lib/component/tooltip");
require("echarts/lib/component/legend");
require("echarts/lib/component/grid");
import $ from "jquery";
export default {
  components: { navBar },
  data() {
    return {
      orgId: this.$route.query.orgId,
      actId: this.$route.query.actId,
      theme: "",
      skey: "",
      tabFixed: false,
      tab: [],
      panelTitle: '<span class="color-ff9d47">浏览</span>排行',
      kpiIndex: 0,
      kpi: "browse", //浏览-browse,预约-subscribe,观看-watch,订单-allOrder
      filters: "",
      goodsList: [],
      loading: false,
      isDataEnd: false,
      isNoData: false,
      page: 1,
      size: 20,
      list: [],
      dateIndex: 0,
      groupIndex: 0,
      goodsIndex: 0,
      pieChartBox: null,
    };
  },

  created: function () {
    this.getHiddenWxSign();
    this.getAct();
    this.getStaticFilters();
    this.getItemLists();
  },
  mounted: function () {
    window.addEventListener("scroll", this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  methods: {
    //获取微信签名数据
    getHiddenWxSign() {
      let params = {};
      params["url"] = window.location.href;
      this.$get(this.$api.wxSign, params).then((res) => {
        let obj = {
          appId: res.data.signPackage.appid,
          nonceStr: res.data.signPackage.noncestr,
          signature: res.data.signPackage.signature,
          timestamp: res.data.signPackage.timestamp,
        };
        hiddenShare(obj);
      });
    },

    //判断导航是否需要吸顶
    handleScroll() {
      let scrollTop = document.querySelector("#scrollContainer").scrollTop;
      let mainoffsetTop = document.querySelector("#main").offsetTop;
      if (scrollTop > mainoffsetTop) {
        this.tabFixed = true;
      } else {
        this.tabFixed = false;
      }
    },

    //获取活动信息
    getAct() {
      let params = {};
      params["orgId"] = this.orgId;
      params["actId"] = this.actId;
      this.$get(this.$api.bizAct, params).then((res) => {
        window.document.title = res.data.title;
        if (res.data.banner) {
          this.theme = res.data.banner;
        }
        this.skey = res.data.skey;
        this.$store.dispatch("setSkey", res.data.skey);

        if (!res.data.showLive) {
          this.tab = [
            {
              value: "browse",
              title: "浏览",
            },
            {
              value: "beforeOrder",
              title: "留资",
            },
            {
              value: "allOrder",
              title: "订单",
            },
          ];
        } else {
          this.tab = [
            {
              value: "browse",
              title: "浏览",
            },
            {
              value: "subscribe",
              title: "预约",
            },
            {
              value: "watch",
              title: "观看",
            },
            {
              value: "allOrder",
              title: "订单",
            },
          ];
        }

        // //更新用户信息
        // let params2 = {};
        // params2["skey"] = res.data.skey;
        // params2["actId"] = this.actId;
        // this.$get(this.$api.userInfo, params2).then((res2) => {
        //   this.$store.dispatch("setUserInfo", res2.data);
        //   this.userInfo = res2.data;
        // });
      });
    },

    //获取统计筛选条件
    getStaticFilters() {
      let params = {};
      params["orgId"] = this.orgId;
      params["actId"] = this.actId;
      this.$get(this.$api.bizStaticFilters, params).then((res) => {
        let dayLists2 = ["日期"];
        if (res.data.dayLists && res.data.dayLists.length > 0) {
          res.data.dayLists.forEach((item) => {
            let date = new Date(item);
            let year = date.getFullYear();
            let month = date.getMonth() + 1;
            if (month < 10) {
              month = "0" + month;
            }
            let date1 = date.getDate();
            if (date1 < 10) {
              date1 = "0" + date1;
            }
            dayLists2.push(year + "-" + month + "-" + date1);
          });
        }

        this.filters = res.data;
        this.filters.dayLists2 = dayLists2;

        //获取统计排行榜
        this.getStaticLists();
      });
    },

    //获取商品列表
    getItemLists() {
      let params = {};
      params["actId"] = this.actId;
      params["page"] = 1;
      params["size"] = 100;
      this.$get(this.$api.itemLists, params).then((res) => {
        this.goodsList =
          res.data.lists && res.data.lists.length > 0 ? res.data.lists : [];
      });
    },

    //获取统计排行榜
    getStaticLists() {
      let that = this;
      if (!that.isNoData && !that.isDataEnd && !that.loading) {
        that.loading = true;
        let params = {};
        params["orgId"] = that.orgId;
        params["actId"] = that.actId;
        params["kpi"] = that.kpi;
        params["type"] = that.filters.typeLists[that.groupIndex].type;
        if (that.filters.typeLists[that.groupIndex].value) {
          params["typeValue"] = that.filters.typeLists[that.groupIndex].value;
        }
        if (that.kpi == "allOrder") {
          params["itemId"] = that.goodsList[that.goodsIndex].itemId;
        }
        if (that.dateIndex > 0) {
          params["day"] = that.filters.dayLists[that.dateIndex - 1];
        }
        if (that.page == 1) {
          params["chart"] = 1;
        }
        params["page"] = that.page;
        params["size"] = that.size;
        that.$get(that.$api.bizStaticLists, params).then((res) => {
          that.loading = false;
          that.page = that.page + 1;
          that.list =
            res.data.lists && res.data.lists.length > 0
              ? that.list.concat(res.data.lists)
              : that.list;
          if (res.data.total == 0) {
            that.isNoData = true;
          } else if (that.list.length == res.data.total) {
            that.isDataEnd = true;
          }

          //处理图标
          if (that.page == 2 && res.data.pieChart.length > 0) {
            setTimeout(function () {
              $("#pieChartBox").show();
              //2.初始化
              that.pieChartBox = Echarts.init(that.$refs.pieChartBox);
              //3.配置数据
              let option = {
                tooltip: {
                  trigger: "item",
                  formatter: "{b}: {c} ({d}%)",
                },
                series: [
                  {
                    name: "Access From",
                    type: "pie",
                    bottom: "12%",
                    radius: ["25%", "50%"],
                    label: {
                      show: false,
                      position: "center",
                    },
                    labelLine: {
                      fontSize: 5,
                      length: 10,
                    },
                    label: {
                      formatter: "{b|  {b}  }{abg|}\n{hr|}\n{c|  {c}({d}%)  }",
                      backgroundColor: "#F6F8FC",
                      borderColor: "#8C8D8E",
                      borderWidth: 1,
                      borderRadius: 4,
                      rich: {
                        hr: {
                          borderColor: "#8C8D8E",
                          width: "100%",
                          borderWidth: 1,
                          height: 0,
                        },
                        b: {
                          color: "#4C5058",
                          fontSize: 10,
                          lineHeight: 20,
                          align: "center",
                        },
                        c: {
                          color: "#4C5058",
                          fontSize: 8,
                          lineHeight: 20,
                          align: "center",
                        },
                      },
                    },
                    data: res.data.pieChart,
                  },
                ],
              };
              // 4.传入数据
              that.pieChartBox.setOption(option);
            }, 100);
          }
        });
      }
    },

    //tab切换
    changeTab(value, index) {
      if (value != this.kpi && !this.loading) {
        if (this.tabFixed) {
          let mainoffsetTop = document.querySelector("#main").offsetTop;
          $(".scrollContainer").animate({ scrollTop: mainoffsetTop + 5 }, 500);
        }
        this.kpi = value;
        this.kpiIndex = index;
        this.panelTitle =
          '<span class="color-ff9d47">' + this.tab[index].title + "</span>排行";
        $("#pieChartBox").hide();
        this.loading = false;
        this.isDataEnd = false;
        this.isNoData = false;
        this.page = 1;
        this.list = [];
        this.getStaticLists();
      }
    },

    //选择条件
    handleChange(e, key) {
      switch (key) {
        case "date":
          if (this.dateIndex != e.target.value) {
            this.dateIndex = e.target.value;
            $("#pieChartBox").hide();
            this.loading = false;
            this.isDataEnd = false;
            this.isNoData = false;
            this.page = 1;
            this.list = [];
            this.getStaticLists();
          }
          break;
        case "group":
          if (this.groupIndex != e.target.value) {
            this.groupIndex = e.target.value;
            $("#pieChartBox").hide();
            this.loading = false;
            this.isDataEnd = false;
            this.isNoData = false;
            this.page = 1;
            this.list = [];
            this.getStaticLists();
          }
          break;
        case "goods":
          if (this.goodsIndex != e.target.value) {
            this.goodsIndex = e.target.value;
            $("#pieChartBox").hide();
            this.loading = false;
            this.isDataEnd = false;
            this.isNoData = false;
            this.page = 1;
            this.list = [];
            this.getStaticLists();
          }
          break;
      }
    },

    //查看更多
    pushmore() {
      let url =
        "/stic/rankMore?orgId=" +
        this.orgId +
        "&actId=" +
        this.actId +
        "&kpi=" +
        this.kpi +
        "&type=" +
        this.filters.typeLists[this.groupIndex].type +
        "&itemId=" +
        this.goodsList[this.goodsIndex].itemId;

      if (this.filters.typeLists[this.groupIndex].value) {
        url =
          url + "&typeValue=" + this.filters.typeLists[this.groupIndex].value;
      }
      if (this.dateIndex > 0) {
        url =
          url +
          "&day=" +
          encodeURIComponent(this.filters.dayLists[this.dateIndex - 1]);
      }

      url =
        url +
        "&panelTitle=" +
        '<span class="color-ff9d47">' +
        this.tab[this.kpiIndex].title +
        "</span>排行";

      this.$router.push({ path: url });
    },
  },
};
</script>
