const apiList = {
    userInfo: "getUserInfo",//获取用户基本信息
    token: "getUkey",//微信code获取token
    sms: "getSms",//获取验证码
    login: "login",//手机验证码登录
    updateUser: "updateUser",//微信code更新用户信息
    reFreshToken: "reFreshToken",//续期token
    itemLists: "getItemLists",//获取商品列表
    
    bizOrgLists: "biz/getOrgLists",//获取机构列表
    bizActLists: "biz/getActLists",//获取活动列表列表
    bizAct: "biz/getAct",//获取活动信息
    bizGroupLists: "biz/getGroupLists",//获取分组列表
    bizStatic: "biz/getStatic",//获取统计数据
    bizStaticGroups: "biz/getStaticGroups",//根据分组id获取统计数据
    bizStaticLine: "biz/getBizStaticLine",//获取门店曲线图数据
    bizDynamicLists: "biz/getDynamicLists",//获取门店动态
    bizStaticFilters: "biz/getStaticFilters",//获取统计筛选条件  
    bizStaticLists: "biz/getStaticLists",//获取统计排行榜
    bizKpiLists: "biz/getKpiLists",//获取kpi数据列表
    bizOrderLists: "biz/getOrderLists",//获取订单列表
    bizOrder: "biz/getOrder",//获取订单详情
    bizChcekData: "biz/postChcekData",//核销订单
    bizUserLists: "biz/getUserLists",//获取门店顾问列表
    bizCusTag: "biz/getCusTag",//获取顾问分配头部和标签列表
    bizCustomerLists: "biz/getCustomerLists",//客户列表
    bizCustomer: "biz/putCustomer",//更新客户信息
    bizAllotCustomer: "biz/postAllotCustomer",//分配客户
    postUser: "biz/postUser",//新增顾问
    putUser: "biz/putUser",//修改顾问信息
    putUserStatus: "biz/putUserStatus",//修改顾问状态
    getOrg: "biz/getOrg",//获取机构详情

    activity: "getActivity",//获取活动信息
    activityEvent: "getActivityEvent",//获取活动轮播
    wxSign: "getWxSign",//获取微信签名数据
    share: "getShare",//获取分享海报信息
    subscribe: "postSubscribe",//保存订阅信息

    kpiData: "postKpiData",//增加kpi数据
    item: "getItem",//获取商品详情
    appoint: "postAppoint",//活动手机号预约
    smsItem: "getSmsItem",//获取商品下单验证码
    orderLists: "getOrderLists",//获取用户订单
    postItem: "postItem",//商品下单获取微信支付信息
    getOrder: "getOrder",//获取订单详情
    putOrderAddress: "putOrderAddress",//修改订单地址
    getWuliu: "getWuliu",//获取订单物流信息

    drawCode: "draw/code",//验证登录码
    drawWin_num: "draw/win_num",//中奖人数
    drawInfo: "draw/info",//获取抽奖人
    drawWin: "draw/win",//更新中奖人
   
  }

module.exports = apiList;