<template>
  <div
    class="bottom-opt pt5 bg-1a1a1a ulib-r750 text-center color-fff"
    @click="backHome"
  >
    <div class="absolute box-center-middle">
      <i class="iconfont icon-shouye text-middle font-36"></i>
      <div class="font-22">首页</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BackHome",
  components: {},
  props: [],
  data() {
    return {
      flag: true,
      nav: [],
    };
  },
  methods: {
    backHome() {
      if (this.flag) {
        this.flag = false;
        this.$router.back();
        // setTimeout(() => {
        //   this.flag = true;
        // }, 1000);
      }
    },
  },
};
</script>
