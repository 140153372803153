<template>
  <div class="scrollContainer font-12" ref="scrollContainer">
    <div
      class="container relative bg-no-repeat bg-size-fullwidth bg-pos-top pb150"
    >
      <div class="inner30 fn-flex">
        <div class="fn-flex-item wp50 mr20">
          <div class="mt10 text-nowrap">
            <img
              class="text-middle imgsize-40X40 ulib-r750"
              :src="bizLogo"
              alt="#"
            />
            <span class="text-middle font-28">{{ bizName }}</span>
          </div>
        </div>
        <div
          class="fn-flex-item wp50 relative pt10 pb10 pl50 pr20 bg-ddd ulib-r750"
        >
          <div
            class="absolute box-middle left-0 font-24 color-999 pl15 pr10 brs-1-999"
          >
            <i class="iconfont icon-sousuo"></i>
          </div>
          <input
            class="ipt-seach wp90 bg-ddd bds-1-ddd font-22 ulib-r750"
            type="search"
            placeholder="请输入顾问姓名"
            v-model="keyword"
            @input="searchHandler"
          />
        </div>
      </div>
      <div
        class="relative bg-fff ml30 mr30 inner30 ulib-r20 box-shadow-darkGray"
        style="min-height: 75vh"
      >
        <div>
          <!--顾问列表-->
          <div
            class="relative mb30 bg-f9 pt20 pb20 pl30 pr200 ulib-r20"
            v-for="(item, index) in list"
            :key="index"
          >
            <div>
              <div class="font-28">{{ item.uname }}·{{ item.mobile }}</div>
              <div class="mt10 font-22 color-999" v-if="item.nickname">
                <img
                  class="text-middle imgsize-30X30 ulib-r750"
                  :src="item.headimg"
                  alt="#"
                />
                <span class="text-middle">{{ item.nickname }}</span>
              </div>
              <div class="mt10 font-22 color-999" v-else>暂未绑定微信</div>
            </div>
            <div class="absolute box-middle right-0 mr30 space-nowrap font-26">
              <a
                class="text-middle"
                href="javascript:void(0);"
                v-if="!item.nickname"
                @click="showForm(index)"
                >修改</a
              >
              <span class="text-middle font-22 pl5 pr5" v-if="!item.nickname"
                >|</span
              >
              <a
                class="text-middle"
                href="javascript:void(0);"
                @click="showDel(index)"
                >删除</a
              >
              <span class="text-middle font-22 pl5 pr5">|</span>
              <a
                class="text-middle color-ffa85a"
                href="javascript:void(0);"
                v-if="item.status == 1"
                @click="showAble(index)"
                >启用</a
              >
              <a
                class="text-middle color-ffa85a"
                href="javascript:void(0);"
                v-else-if="item.status == 0"
                @click="showAble(index)"
                >禁用</a
              >
            </div>
          </div>
          <!--end活动列表-->
        </div>
        <mugenscroll
          :handler="fetchData"
          :should-handle="!loading"
          scroll-container="scrollContainer"
        >
          <div class="pt100 pb100 text-center color-ccc" v-if="isNoData">
            <span class="text-middle font-22">暂无数据</span>
          </div>
          <div class="pt20 pb20 text-center color-ccc" v-else-if="loading">
            <i class="iconfont icon-jiazai text-middle"></i
            ><span class="text-middle font-22">请稍等...</span>
          </div>
          <div
            class="pt20 pb20 text-center font-22 color-ccc"
            v-else-if="isDataEnd && list.length > 10"
          >
            我们是有底线的
          </div>
        </mugenscroll>
      </div>
    </div>

    <div class="fixed left-0 bottom-0 right-0 inner30 pt15 bg-fff z-index-2">
      <div class="pb15 text-center font-22 color-999">
        <i class="iconfont icon-xinxi text-middle"></i>
        <span class="text-middle">绑定微信后，信息无法修改</span>
      </div>
      <a
        class="block bg-1a1a1a pt25 pb25 text-center font-36 color-fff ulib-r10"
        @click="showForm(-1)"
        href="javascript:void(0)"
        >新增顾问</a
      >
    </div>

    <!--新增修改-->
    <div
      class="msg fn-hide"
      :style="isShowForm ? 'display:block' : 'display:none'"
      v-if="isShowForm"
    >
      <div class="msgBg" @click="closeOpt"></div>
      <div class="msgMain">
        <div class="content">
          <div class="word">
            <div class="relative bg-f6 ulib-r10">
              <input
                class="wp100 inner30 font-30 bg-f6 border-none ulib-r10"
                type="text"
                v-model="uname"
                placeholder="请输入顾问姓名"
              />
            </div>
            <div class="mt30 relative bg-f6 ulib-r10">
              <input
                class="wp100 inner30 font-30 bg-f6 border-none ulib-r10"
                type="tel"
                v-model="mobile"
                placeholder="请输入手机号"
              />
            </div>
          </div>
          <div class="mt30">
            <a
              class="block pt25 pb25 bg-1a1a1a text-center font-32 color-fff ulib-r10"
              @click="putUser()"
              v-if="formIndex > -1"
              >修改</a
            >
            <a
              class="block pt25 pb25 bg-1a1a1a text-center font-32 color-fff ulib-r10"
              @click="postUser()"
              v-else
              >新增</a
            >
          </div>
        </div>
      </div>
    </div>

    <!--删除-->
    <div
      class="msg fn-hide"
      :style="isShowDel ? 'display:block' : 'display:none'"
      v-if="isShowDel"
    >
      <div class="msgBg" @click="closeOpt"></div>
      <div class="msgMain">
        <div class="content" style="padding: 0">
          <div class="pt60 pb60 pl40 pr40 text-center font-32">
            确认删除{{ list[delIndex].uname }}"吗?"
          </div>
          <div class="fn-flex bts-1-eee text-center font-28">
            <a
              class="fn-flex-item pt30 pb30"
              href="javascript:void(0);"
              @click="delOpt()"
              >确定</a
            >
            <a
              class="fn-flex-item pt30 pb30 bls-1-eee color-999"
              href="javascript:void(0);"
              @click="closeOpt"
              >取消</a
            >
          </div>
        </div>
      </div>
    </div>

    <!--启用禁用-->
    <div
      class="msg fn-hide"
      :style="isShowAble ? 'display:block' : 'display:none'"
      v-if="isShowAble"
    >
      <div class="msgBg" @click="closeOpt"></div>
      <div class="msgMain">
        <div class="content" style="padding: 0">
          <div class="pt60 pb60 pl40 pr40 text-center font-32">
            {{ list[ableIndex].status == 1 ? "确认启用" : "确认禁用"
            }}{{ list[ableIndex].uname }}"吗?"
          </div>
          <div class="fn-flex bts-1-eee text-center font-28">
            <a
              class="fn-flex-item pt30 pb30"
              href="javascript:void(0);"
              @click="ableOpt()"
              >确定</a
            >
            <a
              class="fn-flex-item pt30 pb30 bls-1-eee color-999"
              href="javascript:void(0);"
              @click="closeOpt"
              >取消</a
            >
          </div>
        </div>
      </div>
    </div>

    <backHome></backHome>
  </div>
</template>
<script>
function _Debounce(fn, t) {
  let delay = t || 500;
  let timer;
  return function () {
    let args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, args);
    }, delay);
  };
}

import backHome from "../../components/backHome";
import mugenscroll from "vue-mugen-scroll";
import { hiddenShare } from "../../assets/js/utils/wexinShare.js";
export default {
  components: { mugenscroll, backHome },
  data() {
    return {
      orgId: this.$route.query.orgId,
      bizName: this.$route.query.bizName,
      bizLogo: this.$route.query.bizLogo,
      keyword: "",
      info: {},
      loading: false,
      isDataEnd: false,
      isNoData: false,
      page: 1, //页数
      size: 20, //每页取多少个数据
      list: [],
      uname: "",
      mobile: "",
      isSubmiting: false,
      isRefresh: false, //是否是刷新列表，用于操作后刷新列表
      isShowForm: false,
      isShowDel: false,
      isShowAble: false,
      formIndex: -1,
      delIndex: -1,
      ableIndex: -1,
    };
  },

  created: function () {
    this.getHiddenWxSign();
    this.getUserLists();
  },

  mounted: function () {},
  methods: {
    //获取微信签名数据
    getHiddenWxSign() {
      let params = {};
      params["url"] = window.location.href;
      this.$get(this.$api.wxSign, params).then((res) => {
        let obj = {
          appId: res.data.signPackage.appid,
          nonceStr: res.data.signPackage.noncestr,
          signature: res.data.signPackage.signature,
          timestamp: res.data.signPackage.timestamp,
        };
        hiddenShare(obj);
      });
    },

    //拉取数据
    fetchData: function () {
      this.getUserLists();
    },

    //顾问列表
    searchHandler: _Debounce(function () {
      this.loading = false;
      this.isDataEnd = false;
      this.isNoData = false;
      this.page = 1;
      this.list = [];
      this.getUserLists();
    }, 500),

    //获取门店顾问列表
    getUserLists() {
      let that = this;
      if (!that.isNoData && !that.isDataEnd && !that.loading) {
        that.loading = true;
        let params = {};
        params["orgId"] = that.orgId;
        params["allUser"] = 1;
        if (that.keyword) {
          params["keyword"] = that.keyword;
        }
        if (that.isRefresh) {
          //刷新列表参数
          that.page = that.page - 1;
          params["page"] = 1;
          params["size"] = that.page * that.size;
        } else {
          params["page"] = that.page;
          params["size"] = that.size;
        }
        that.$get(that.$api.bizUserLists, params).then((res) => {
          that.loading = false;
          that.page = that.page + 1;
          that.isRefresh = false;
          that.list =
            res.data.lists && res.data.lists.length > 0
              ? that.list.concat(res.data.lists)
              : that.list;
          if (res.data.total == 0) {
            that.isNoData = true;
          } else if (that.list.length == res.data.total) {
            that.isDataEnd = true;
          }
        });
      }
    },

    //关闭弹框
    closeOpt: function () {
      this.isShowForm = false;
      this.isShowDel = false;
      this.isShowAble = false;
    },

    //显示新增编辑弹框
    showForm(index) {
      this.formIndex = index;
      this.isShowForm = true;
      if (index > -1) {
        this.uname = this.list[index].uname;
        this.mobile = this.list[index].mobile;
      }
    },

    //提交新增
    postUser() {
      if (this.isSubmiting) return;
      if (!this.uname) {
        this.$toast({
          message: "请输入顾问姓名",
          duration: 1500,
        });
      } else if (!/^1[3456789]\d{9}$/.test(this.mobile)) {
        this.$toast({
          message: "请输入正确手机号",
          duration: 1500,
        });
      } else {
        let that = this;
        that.isSubmiting = true;
        let params = {};
        params["orgId"] = Number(that.orgId);
        params["uname"] = that.uname;
        params["mobile"] = that.mobile;
        that
          .$post(that.$api.postUser, params)
          .then(() => {
            that.isSubmiting = false;
            that.isShowForm = false;
            that.$toast({
              message: "提交成功",
              duration: 1500,
            });
            that.uname = "";
            that.mobile = "";
            that.loading = false;
            that.isDataEnd = false;
            that.isNoData = false;
            that.page = 1;
            that.list = [];
            that.getUserLists();
          })
          .catch(() => {
            that.isSubmiting = false;
          });
      }
    },

    //提交编辑
    putUser() {
      if (this.isSubmiting) return;
      if (!this.uname) {
        this.$toast({
          message: "请输入顾问姓名",
          duration: 1500,
        });
      } else if (!/^1[3456789]\d{9}$/.test(this.mobile)) {
        this.$toast({
          message: "请输入正确手机号",
          duration: 1500,
        });
      } else {
        let that = this;
        that.isSubmiting = true;
        let params = {};
        params["orgId"] = Number(that.orgId);
        params["userId"] = Number(that.list[that.formIndex].userId);
        params["uname"] = that.uname;
        params["mobile"] = that.mobile;
        that
          .$put(that.$api.putUser, params)
          .then(() => {
            that.isSubmiting = false;
            that.isShowForm = false;
            that.$toast({
              message: "提交成功",
              duration: 1500,
            });
            that.uname = "";
            that.mobile = "";
            that.loading = false;
            that.isDataEnd = false;
            that.isNoData = false;
            that.page = 1;
            that.list = [];
            that.getUserLists();
          })
          .catch(() => {
            that.isSubmiting = false;
          });
      }
    },

    //显示启用禁用确认框
    showAble(index) {
      this.ableIndex = index;
      this.isShowAble = true;
    },

    //启用禁用
    ableOpt() {
      let that = this;
      let params = {};
      params["orgId"] = Number(that.orgId);
      params["userId"] = Number(that.list[that.ableIndex].userId);
      params["status"] = Number(that.list[that.ableIndex].status == 0 ? 1 : 0);
      that.$put(that.$api.putUserStatus, params).then(() => {
        that.isShowForm = false;
        that.$toast({
          message: "操作成功",
          duration: 1500,
        });
        that.list[that.ableIndex].status =
          that.list[that.ableIndex].status == 0 ? 1 : 0;
        that.isShowAble = false;
      });
    },

    //删除确认框
    showDel(index) {
      this.delIndex = index;
      this.isShowDel = true;
    },

    //删除
    delOpt() {
      let that = this;
      let params = {};
      params["orgId"] = Number(that.orgId);
      params["userId"] = Number(that.list[that.delIndex].userId);
      params["status"] = -1;
      that.$put(that.$api.putUserStatus, params).then(() => {
        that.isShowDel = false;
        that.$toast({
          message: "删除成功",
          duration: 1500,
        });
        that.isRefresh = true;
        that.loading = false;
        that.isDataEnd = false;
        that.isNoData = false;
        that.list = [];
        that.getUserLists();
      });
    },
  },
};
</script>
