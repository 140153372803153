<template>
  <div class="scrollContainer font-12" ref="scrollContainer">
    <div
      class="container relative bg-repeat-y bg-size-fullwidth bg-pos-center"
      style="
        background-image: url(https://qs.haodian.cn/web/images/project/H5-ShiYu/loginbg2.jpg?v=221008);
      "
    >
      <div class="absolute box-middle left-0 right-0 pl80 pr80 pb220">
        <div class="color-fff">
          <div class="font-52">手机号登录</div>
          <div class="mt20 font-28">欢迎使用好店云-私域直播系统</div>
        </div>
        <div>
          <div class="relative mt50 bg-fff-op15 ulib-r750">
            <input
              class="wp100 pt30 pb30 pl40 pr20 bg-transparent border-none font-32 color-fff placeholder-color-ccc"
              type="tel"
              v-model="telPhone"
              placeholder="请输入手机号"
            />
          </div>
          <div class="relative mt50 bg-fff-op15 ulib-r750">
            <input
              class="wp100 pt30 pb30 pl40 pr40 bg-transparent border-none font-32 color-fff placeholder-color-ccc"
              type="number"
              oninput="if(value.length>4)value=value.slice(0,4)"
              v-model="vCode"
              placeholder="请输入验证码"
            />
            <div
              class="absolute right-0 box-middle mr30 font-32 color-fff"
              @click="getSms()"
            >
              {{ codeTx }}
            </div>
          </div>
          <button
            class="mt50 wp100 bg-fff-op80 border-none pt30 pb30 font-32 color-333 ulib-r750"
            @click="login()"
          >
            立即登录
          </button>
        </div>
      </div>
      <div
        class="absolute bottom-0 left-0 right-0 pl40 pr40 pb80 text-center color-fff"
      >
        <div class="font-22">powered by haodian.cn</div>
      </div>
    </div>
  </div>
</template>
<script>
import Config from "../../config.js";
import { hiddenShare } from "../../assets/js/utils/wexinShare.js";
var Timer;
export default {
  data() {
    return {
      toPath: "", //授权用户信息返回时使用
      fromPath: "",
      telPhone: "",
      vCode: "",
      codeState: true,
      codeTx: "获取验证码",
      isSubmiting: false,
    };
  },

  created: function () {
    if (this.$store.getters.getIsAdminLogin) {
      this.$router.replace("/index");
    }

    this.getHiddenWxSign();

    //授权用户信息后重新提交登录信息
    let logindata = sessionStorage.getItem("logindata")
      ? JSON.parse(sessionStorage.getItem("logindata"))
      : {};
    if (sessionStorage.getItem("isauth") && logindata.telPhone) {
      this.telPhone = logindata.telPhone;
      this.vCode = logindata.vCode;
      sessionStorage.setItem("logindata", "");
      this.login();
    }
  },

  mounted() {},
  methods: {
    //获取微信签名数据
    getHiddenWxSign() {
      let params = {};
      params["url"] = window.location.href;
      this.$get(this.$api.wxSign, params).then((res) => {
        let obj = {
          appId: res.data.signPackage.appid,
          nonceStr: res.data.signPackage.noncestr,
          signature: res.data.signPackage.signature,
          timestamp: res.data.signPackage.timestamp,
        };
        hiddenShare(obj);
      });
    },

    //验证码刷新倒计时
    countDownTime(time) {
      let that = this;
      if (time > 1) {
        time--;
        this.codeState = false;
        this.codeTx = time + "s后重新获取";
        Timer = setTimeout(function () {
          that.countDownTime(time);
        }, 1000);
      } else {
        clearTimeout(Timer);
        that.codeState = true;
        that.codeTx = "获取验证码";
      }
    },

    //获取验证码
    getSms() {
      if (!this.codeState) return;
      if (!/^1[3456789]\d{9}$/.test(this.telPhone)) {
        this.$toast({
          message: "请输入正确手机号",
          duration: 1500,
        });
      } else {
        let params = {};
        params["type"] = "login";
        params["mobile"] = this.telPhone;
        this.$get(this.$api.sms, params).then(() => {
          //按钮倒计时
          this.countDownTime(61);
        });
      }
    },

    //登录
    login() {
      if (this.isSubmiting) return;
      if (!/^1[3456789]\d{9}$/.test(this.telPhone)) {
        this.$toast({
          message: "请输入正确手机号",
          duration: 1500,
        });
      } else if (!this.vCode) {
        this.$toast({
          message: "请输入验证码",
          duration: 1500,
        });
      } else {
        if (!sessionStorage.getItem("isauth")) {
          let logindata = {
            telPhone: this.telPhone,
            vCode: this.vCode,
          };
          sessionStorage.setItem("logindata", JSON.stringify(logindata));
          //sessionStorage.setItem("userUedirectPath", window.location.href); //hash
          sessionStorage.setItem("userUedirectPath", this.toPath); //history

          let redirectURL = encodeURIComponent(
            window.location.href.split("&code")[0]
          ); //获取地址
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${Config.appId}&redirect_uri=${redirectURL}&response_type=code&scope=snsapi_userinfo&state=syauthuserinfo#wechat_redirect`;
        } else {
          let that = this;
          that.isSubmiting = true;
          let params = {};
          params["mobile"] = that.telPhone;
          params["code"] = that.vCode;
          that
            .$get(that.$api.login, params)
            .then((res) => {
              console.log(res);
              that.isSubmiting = false;
              that.$store.dispatch("setToken", res.data.token);
              that.$store.dispatch(
                "setExpires",
                new Date().getTime() + res.data.expiresTime * 1000
              );
              that.$store.dispatch("setIsAdminLogin", true);
              that.$toast({
                message: "登录成功！",
                duration: 1500,
              });
              that.getUserInfo();
            })
            .catch(() => {
              that.isSubmiting = false;
            });
        }
      }
    },

    //更新用户信息
    getUserInfo() {
      let that = this;
      that.$get(that.$api.userInfo, {}).then((res) => {
        that.$store.dispatch("setUserInfo", res.data);
        that.$get(that.$api.bizOrgLists, {}).then((res2) => {
          setTimeout(function () {
            if (res2.data.total == 1) {
              that.$router.replace("/biz?orgId=" + res2.data.lists[0].orgId);
            } else {
              that.$router.replace("/index");
            }
          }, 1500);
        });

        //因为设计权限不能直接返回
        // setTimeout(function () {
        //   if (that.fromPath != "/") {
        //     that.$router.go(-1);
        //   } else {
        //     that.$router.replace("/index");
        //   }
        // }, 1500);
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      //这里的vm指的就是vue实例，可以用来当做this使用
      vm.toPath = to.fullPath;
      vm.fromPath = from.path;
    });
  },
};
</script>

<style lang="scss"></style>
