var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"scrollContainer",staticClass:"scrollContainer font-12"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"pt30 pl30 pr30 fn-clear"},[_c('a',{staticClass:"block fn-fl pt10 pb10 pl20 pr20 bg-ccc font-28 ulib-r750",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.quit()}}},[_c('i',{staticClass:"iconfont icon-tuichu text-middle"}),_c('span',{staticClass:"ml5 text-middle"},[_vm._v("退出")])]),(_vm.orgtotal > 1)?_c('router-link',{staticClass:"block fn-fr pt10 pb10 pl20 pr20 bg-333 font-28 color-fff ulib-r750",attrs:{"to":{ path: '/index' }}},[_c('i',{staticClass:"iconfont icon-qiehuan1 text-middle"}),_c('span',{staticClass:"ml5 text-middle"},[_vm._v("切换机构")])]):_vm._e()],1),_c('div',{staticClass:"inner30 pt0"},[_vm._l((_vm.list),function(item,index){return _c('router-link',{key:index,staticClass:"block mt30 pb30 bg-fff overflowhidden box-shadow-lightGray ulib-r20",attrs:{"to":{
          path: '/stic?orgId=' + _vm.orgId + '&actId=' + item.actId,
        }}},[_c('img',{staticClass:"imgsize-690X310",attrs:{"src":item.banner,"alt":"#"}}),_c('div',{staticClass:"mt20 pl30 pr30 font-30 color-333"},[_vm._v(_vm._s(item.title))]),_c('div',{staticClass:"mt10 pl30 pr30 font-24 color-999"},[_vm._v(" "+_vm._s(item.timeStart)+" 至 "+_vm._s(item.timeEnd)+" ")])])}),_c('mugenscroll',{attrs:{"handler":_vm.fetchData,"should-handle":!_vm.loading,"scroll-container":"scrollContainer"}},[(_vm.isNoData)?_c('div',{staticClass:"pt100 pb100 text-center color-ccc"},[_c('span',{staticClass:"text-middle font-22"},[_vm._v("暂无数据")])]):(_vm.loading)?_c('div',{staticClass:"pt20 pb20 text-center color-ccc"},[_c('i',{staticClass:"iconfont icon-jiazai text-middle"}),_c('span',{staticClass:"text-middle font-22"},[_vm._v("请稍等...")])]):(_vm.isDataEnd && _vm.list.length > 10)?_c('div',{staticClass:"pt20 pb20 text-center font-22 color-ccc"},[_vm._v(" 我们是有底线的 ")]):_vm._e()])],2)]),(_vm.isBiz)?_c('router-link',{staticClass:"bottom-opt bg-1a1a1a ulib-r750 text-center color-fff",attrs:{"to":{
      path:
        '/user?orgId=' +
        _vm.orgId +
        '&bizName=' +
        _vm.bizName +
        '&bizLogo=' +
        _vm.bizLogo,
    }}},[_c('div',{staticClass:"absolute box-center-middle line-height-13 font-22"},[_c('div',[_vm._v("顾问")]),_c('div',[_vm._v("管理")])])]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }