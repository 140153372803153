import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
// import Qs from 'qs'
import store from './store';
import {Toast} from 'vant';
import Config from './config.js';
let stringEncrypt = require('./assets/js/utils/stringEncrypt');
import {checkStatus} from './utils.js';
import api from './api';

Vue.use(Toast);


let Loading = {
    count: 0,
    start: function () {
        Toast.loading({
            message: '加载中...',
            forbidClick: true
        });
    },
    end: function () {
        Toast.clear();
    },
    show: function () {
        if (this.count === 0) {
            this.start();
        }
        this.count++;
    },
    hide: function () {
        if (this.count <= 0) return;
        this.count--;
        if (this.count === 0) {
            this.end();
        }
    }
}

// 是否正在刷新的标志
window.isRefreshing = false;

// 存储请求的数组
let cacheRequestArr = [];

// 将所有的请求都push到数组中,其实数组是[function(token){}, function(token){},...]
function cacheRequestArrHandle(cb) {
    console.log('进cacheRequestArrHandle');
    console.log(cb);
    cacheRequestArr.push(cb);
}
// 数组中的请求得到新的token之后自执行，用新的token去重新发起请求
function afreshRequest(token) {
    console.log('进afreshRequest');
    cacheRequestArr.map(cb => cb(token));
    cacheRequestArr = [];
}

// 判断token是否即将过期  //1正常 /2即将过期  /3已经过期或其他情况
function tokenExpired() {
    let curTime = new Date().getTime();
    let expiresTime = Number(store.state.expires) - curTime;
    // 30分钟内即将过期
    console.log('离过期还有'+expiresTime+'毫秒');
    console.log('离过期还有'+expiresTime/60000+'分钟');
    if (expiresTime >= 30*60*1000) {
		return 1
	}else if(expiresTime >= 0 && expiresTime < 30*60*1000){
        return 2
    }
    return 3;
}

const axiosService = axios.create({
    baseURL: Config.baseURL,
    timeout: 100000, 
});

axiosService.interceptors.request.use(config => {
    console.log('请求...')
    if(config.url=="draw/code"||config.url=="draw/win_num"||config.url=="draw/info"||config.url=="draw/win"){
        config.headers.common['Authorization'] = store.getters.getToken;
        config.headers = Object.assign({
            'Content-Type': 'text/html;charset=UTF-8'
        }, config.headers);
        return config
    }else{
        if (store.getters.getToken) {
            let expiresType = tokenExpired();
            console.log(expiresType);
            if (expiresType==1) {
                Loading.show();
                //正常流程
                // if (config.headers.showLoading !== false) {
                //     Loading.show();
                // }
                //config.headers['Authorization'] = store.getters.getToken;
                config.headers.common['Authorization'] = store.getters.getToken;
                config.headers = Object.assign({
                    'Content-Type': 'text/html;charset=UTF-8'
                }, config.headers);
                // const contentType = config.headers['Content-Type'];
                // if (contentType) {
                //     config.data = Qs.stringify(config.data);
                // }
                console.log('config');
                console.log(config);
                return config
            }else if (expiresType==2) {
                // 快过期了
                if (!window.isRefreshing) {
                    window.isRefreshing = true;
                    console.log('正在刷新');
                    let orgParams = {};
                    orgParams["nonce_str"] = stringEncrypt.randomString();
                    let serectParams = {
                        ...orgParams,
                        sign: stringEncrypt.toEncrypt(orgParams)
                    }
                    console.log(orgParams);
                    console.log(serectParams);
                    //请求续期接口
                    console.log('请求续期接口')
    
    
                    axios.defaults.headers.common['Authorization'] = store.getters.getToken
                    axios({
                        url:Config.baseURL+api.reFreshToken,
                        params:serectParams
                    }).then(res => {
                        if (res.data.code == 200) {
                            store.commit("setToken",res.data.data.token);
                            store.commit("setExpires",new Date().getTime()+res.data.data.expiresTime*1000);
                            config.headers.common['Authorization'] = store.getters.getToken;
                            afreshRequest(store.getters.getToken)
                        }else{
                            Toast({
                                message: res.data.msg,
                                duration: 1500,
                            });
                            store.dispatch('delToken').then(() => {
                                location.reload() // 为了重新实例化vue-router对象 避免bug
                            })
                        }
                    })
                    .catch(err => {
                        console.log('refreshToken err =>' + err);
                        store.dispatch('delToken').then(() => {
                            location.reload() // 为了重新实例化vue-router对象 避免bug
                        })
                    }).finally(() => {
                        window.isRefreshing = false;
                    })
                    // 下面这段代码一定要写，不然第一个请求的接口带过去的token还是原来的，要将第一个请求也缓存起来
                    let retry = new Promise((resolve) => {
                        cacheRequestArrHandle((token) => {
                            //config.headers['Authorization'] = store.getters.getToken; // token为刷新完成后传入的token
                            config.headers.common['Authorization'] = token;
                            // 将请求挂起
                            resolve(config)
                        })
                    })
                    return retry;
                } else {
                    let retry = new Promise((resolve) => {
                        cacheRequestArrHandle((token) => {
                            //config.headers['Authorization'] = store.getters.getToken; // token为刷新完成后传入的token
                            config.headers.common['Authorization'] = token;
                            // 将请求挂起
                            resolve(config)
                        })
                    })
                    return retry;
                }   
            }else if (expiresType==3) {
                //已经过期或其他情况
                store.dispatch('delToken').then(() => {
                    location.reload() // 为了重新实例化vue-router对象 避免bug
                })
            }
        }
        else{
            //跳转到微信授权页面
            let redirectURL = encodeURIComponent(
                (window.location.href).split("&code")[0]
            ); //获取地址
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${Config.appId}&redirect_uri=${redirectURL}&response_type=code&scope=snsapi_base&state=syauthbase#wechat_redirect`
        }
    }
}, error => {
    Loading.hide();
    return Promise.reject(error);
});


axiosService.interceptors.response.use(response => {

    //token 存在 还返回10001，这个时候就要强制
    if (response.data.code == 10001) {
        store.dispatch('delToken').then(() => {
            location.reload() // 为了重新实例化vue-router对象 避免bug
        })
      } else{
        if (response.data.code==200){
            return response;
        }else{
            Toast(response.data.msg);
            return Promise.reject(response.data);
        }
    }

    // if (response.status === 200) {
    //     if (response.data.status == 200 || response.data.status == 1) {
    //         return response;
    //     } else {
    //         Toast(response.data.msg);
    //         return Promise.reject(response.data)
    //     }
    // }
    // return response;
}, error => {
    Loading.hide();
    if (Object.prototype.toString.call(error) === "[object Object]") {
        return Promise.reject(checkStatus(error.response));
    } else {
        return Promise.reject(checkStatus(error));
    }
});

/**
 * 封装get请求
 * @param url 配置
 * @param config 配置
 * @returns {Promise}
 */

export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        let orgParams = Object.prototype.toString.call(params) == "[object Object]" ? params : {};
        orgParams["nonce_str"] = stringEncrypt.randomString();
        let serectParams = {
            ...orgParams,
            sign: stringEncrypt.toEncrypt(orgParams)
        }
        axiosService.get(url, {  
            params: serectParams 
        }).then(response => {
            resolve(response.data);
        })
        .catch(err => {
            reject(err);
        })

    })
}


/**
 * 封装post请求
 * @param url 配置
 * @param config 配置
 * @returns {Promise}
 */
export function post(url, params = {}) {
    return new Promise((resolve, reject) => {
        let orgParams = Object.prototype.toString.call(params) == "[object Object]" ? params : {};
        orgParams["nonce_str"] = stringEncrypt.randomString();
        let md5Params = Object.assign({}, orgParams);
        for (let key in md5Params) {
            if (
                typeof md5Params[key] == "number" &&
                md5Params[key].toString().indexOf(".") != -1
            ) {
                md5Params[key] = parseInt(md5Params[key]);
            }
        }
        let serectParams = {
            ...orgParams,
            sign: stringEncrypt.toEncrypt(md5Params)
        }
        axiosService.post(url, serectParams).then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err)
            })
    })
}


/**
 * 封装PUT请求
 * @param url
 * @param data
 * @returns {Promise}
 */
export function put(url, params = {}) {
    return new Promise((resolve, reject) => {
        let orgParams = Object.prototype.toString.call(params) == "[object Object]" ? params : {};
        orgParams["nonce_str"] = stringEncrypt.randomString();
        let md5Params = Object.assign({}, orgParams);
        for (let key in md5Params) {
            if (
                typeof md5Params[key] == "number" &&
                md5Params[key].toString().indexOf(".") != -1
            ) {
                md5Params[key] = parseInt(md5Params[key]);
            }
        }
        let serectParams = {
            ...orgParams,
            sign: stringEncrypt.toEncrypt(md5Params)
        }
        axiosService.put(url, serectParams).then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err)
            })
    })
}





/*
 * 封装PATCH请求
 * @param url
 * @param data
 * @
 * @returns {Promise}
 */
export function patch(url, params = {}) {
    return new Promise((resolve, reject) => {
        let orgParams = Object.prototype.toString.call(params) == "[object Object]" ? params : {};
        orgParams["nonce_str"] = stringEncrypt.randomString();
        let md5Params = Object.assign({}, orgParams);
        for (let key in md5Params) {
            if (
                typeof md5Params[key] == "number" &&
                md5Params[key].toString().indexOf(".") != -1
            ) {
                md5Params[key] = parseInt(md5Params[key]);
            }
        }
        let serectParams = {
            ...orgParams,
            sign: stringEncrypt.toEncrypt(md5Params)
        }
        axiosService.patch(url, serectParams).then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err)
            })
    })
}

Vue.use(VueAxios, axiosService);
export {
    axiosService
}