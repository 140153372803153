<template>
  <div class="scrollContainer font-12" ref="scrollContainer">
    <div class="container pt100" v-if="used == 1">
      <div
        class="relative bg-fff ml30 mr30 inner30 ulib-r20 box-shadow-darkGray"
      >
        <div
          class="absolute top-0 right-0 bg-fe9538 pt10 pb10 pl20 pr20 font-22 color-fff ulib-rbl20 ulib-rtr20"
        >
          {{ iswin == 1 ? "抽奖" : "订单" }}
        </div>
        <img
          class="absolute top--60 box-center bds-4-fff imgsize-120X120 ulib-r750 z-index-1"
          :src="info.headimg"
          alt="#"
        />
        <div class="pt50 font-28 text-center color-999">
          {{ info.nickname }}
        </div>
        <div class="pt50">
          <div class="relative pl70 bg-f6 ulib-r10">
            <i
              class="absolute box-middle left-0 ml30 iconfont icon-wode font-30 color-999"
            ></i>
            <div class="pt25 pb25 font-30 bg-f6 ulib-r10">
              {{ customerName }}
            </div>
          </div>
          <div class="mt30 relative pl70 bg-f6 ulib-r10">
            <i
              class="absolute box-middle left-0 ml30 iconfont icon-shouji font-30 color-999"
            ></i>
            <div class="pt25 pb25 font-30 bg-f6 ulib-r10">{{ tel }}</div>
          </div>
          <template v-if="info.ifAddress">
            <div class="mt30 relative bg-f6 ulib-r10">
              <div class="pl30 pr30 pt25 pb25 font-30 bg-f6 ulib-r10">
                {{ region }}
              </div>
            </div>
            <div class="mt30 relative pl70 bg-f6 ulib-r10">
              <i
                class="absolute box-middle left-0 ml30 iconfont icon-dizhi1 font-30 color-999"
              ></i>
              <div class="pt25 pb25 font-30 bg-f6 ulib-r10">{{ address }}</div>
            </div>
          </template>
          <div class="relative mt30 pt30 bts-1-eee fn-clear">
            <img class="fn-fl imgsize-180X90 ulib-r5" :src="info.img" alt="#" />
            <div class="pl200">
              <div class="text-nowrap font-32 line-height-13">
                <template v-if="info.grade">
                  <span class="text-middle color-fe9538">{{ info.grade }}</span>
                  <span class="text-middle">·</span>
                </template>
                <span class="text-middle">{{ info.name }}</span>
              </div>
              <div class="mt15 font-22 color-999">{{ info.time }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="pl30 pr30">
        <button
          class="mt40 wp100 bg-999 border-none pt30 pb30 font-32 color-fff ulib-r10"
        >
          已核销
        </button>
      </div>

      <div
        class="absolute bottom-0 left-0 right-0 pb100 pl30 pr30 text-center font-22 color-999 line-height-20"
      >
        <div>温馨提示：核销只能使用一次，遇到问题及时联系私域通顾问</div>
      </div>
    </div>
    <div class="container pt100" v-else>
      <div
        class="relative bg-fff ml30 mr30 inner30 ulib-r20 box-shadow-darkGray"
      >
        <div
          class="absolute top-0 right-0 bg-fe9538 pt10 pb10 pl20 pr20 font-22 color-fff ulib-rbl20 ulib-rtr20"
        >
          {{ iswin == 1 ? "抽奖" : "订单" }}
        </div>
        <img
          class="absolute top--60 box-center bds-4-fff imgsize-120X120 ulib-r750 z-index-1"
          :src="info.headimg"
          alt="#"
        />
        <div class="pt50 font-28 text-center color-999">
          {{ info.nickname }}
        </div>
        <div class="pt50">
          <div class="relative pl70 bg-f6 ulib-r10">
            <i
              class="absolute box-middle left-0 ml30 iconfont icon-wode font-30 color-999"
            ></i>
            <input
              class="wp100 pt25 pb25 font-30 bg-f6 border-none ulib-r10"
              type="text"
              v-model="customerName"
              placeholder="请输入姓名"
            />
          </div>
          <div class="mt30 relative pl70 bg-f6 ulib-r10">
            <i
              class="absolute box-middle left-0 ml30 iconfont icon-shouji font-30 color-999"
            ></i>
            <input
              class="wp100 pt25 pb25 font-30 bg-f6 border-none ulib-r10"
              type="tel"
              v-model="tel"
              placeholder="请输入手机号"
            />
          </div>
          <template v-if="info.ifAddress">
            <div class="mt30 relative bg-f6 ulib-r10">
              <input
                id="region"
                class="wp100 inner30 font-28 bg-f6 border-none ulib-r10"
                type="text"
                readonly=""
                v-model="region"
                placeholder="选择省/市/区"
              />
              <input id="regionvalue" type="hidden" v-model="regionvalue" />
            </div>
            <div class="mt30 relative pl70 bg-f6 ulib-r10">
              <i
                class="absolute box-middle left-0 ml30 iconfont icon-dizhi1 font-30 color-999"
              ></i>
              <input
                class="wp100 pt25 pb25 font-28 bg-f6 border-none ulib-r10"
                type="text"
                v-model="address"
                placeholder="请输入详细地址"
              />
            </div>
          </template>
          <div class="relative mt30 pt30 bts-1-eee fn-clear">
            <img class="fn-fl imgsize-180X90 ulib-r5" :src="info.img" alt="#" />
            <div class="pl200">
              <div class="text-nowrap font-32 line-height-13">
                <template v-if="info.grade">
                  <span class="text-middle color-fe9538">{{ info.grade }}</span>
                  <span class="text-middle">·</span>
                </template>
                <span class="text-middle">{{ info.name }}</span>
              </div>
              <div class="mt15 font-22 color-999">{{ info.time }}</div>
            </div>
          </div>
        </div>
      </div>

      <div class="pl30 pr30">
        <button
          class="mt40 wp100 bg-1a1a1a border-none pt30 pb30 font-32 color-fff ulib-r10"
          @click="postChcekData()"
        >
          立即提交核销
        </button>
      </div>

      <div
        class="absolute bottom-0 left-0 right-0 pb100 pl30 pr30 text-center font-22 color-999 line-height-20"
      >
        <div>温馨提示：核销只能使用一次，遇到问题及时联系私域通顾问</div>
      </div>
    </div>
  </div>
</template>
<script>
import { hiddenShare } from "../../assets/js/utils/wexinShare.js";
import $ from "jquery";
export default {
  data() {
    return {
      orgId: this.$route.query.orgId,
      actId: this.$route.query.actId,
      orderId: this.$route.query.orderId,
      iswin: this.$route.query.iswin,
      used: this.$route.query.used,
      info: {},
      customerName: "",
      tel: "",
      region: "",
      regionvalue: "",
      address: "",
      isSubmiting: false,
    };
  },

  created: function () {
    this.getHiddenWxSign();
    this.getOrder();

    let LArea1 = document.createElement("script");
    LArea1.type = "text/javascript";
    LArea1.src = "https://qs.haodian.cn/web/javascript/LArea/js/LAreaData1.js";
    document.getElementsByTagName("head")[0].appendChild(LArea1);
    let LArea2 = document.createElement("script");
    LArea2.type = "text/javascript";
    LArea2.src = "https://qs.haodian.cn/web/javascript/LArea/js/LAreaData2.js";
    document.getElementsByTagName("head")[0].appendChild(LArea2);
    let LArea = document.createElement("script");
    LArea.type = "text/javascript";
    LArea.src = "https://qs.haodian.cn/web/javascript/LArea/js/LArea.js";
    document.getElementsByTagName("head")[0].appendChild(LArea);
  },

  mounted: function () {},
  methods: {
    //获取微信签名数据
    getHiddenWxSign() {
      let params = {};
      params["url"] = window.location.href;
      this.$get(this.$api.wxSign, params).then((res) => {
        let obj = {
          appId: res.data.signPackage.appid,
          nonceStr: res.data.signPackage.noncestr,
          signature: res.data.signPackage.signature,
          timestamp: res.data.signPackage.timestamp,
        };
        hiddenShare(obj);
      });
    },

    //获取基础信息
    getOrder() {
      let that = this;
      let params = {};
      params["orgId"] = that.orgId;
      params["actId"] = that.actId;
      params["orderId"] = that.orderId;
      if (that.iswin) {
        params["iswin"] = that.iswin;
      }
      that.$get(that.$api.bizOrder, params).then((res) => {
        that.info = res.data;
        that.customerName = res.data.customerName;
        that.tel = res.data.tel;
        that.region = res.data.region;
        that.address = res.data.address;

        //选择省市初始化
        if (res.data.ifAddress) {
          setTimeout(function () {
            let area = new LArea();
            area.init({
              trigger: "#region", //触发选择控件的文本框，同时选择完毕后name属性输出到该位置
              valueTo: "#regionvalue", //选择完毕后id属性输出到该位置
              keys: {
                id: "value",
                name: "text",
              }, //绑定数据源相关字段 id对应valueTo的value属性输出 name对应trigger的value属性输出
              type: 2,
              data: [provs_data, citys_data, dists_data],
            });

            if (that.region != "") {
              for (var i in provs_data) {
                if (provs_data[i].text == that.region.split(",")[0]) {
                  var x = i;
                  var city = citys_data[provs_data[i].value];
                  for (var j in city) {
                    if (city[j].text == that.region.split(",")[1]) {
                      var y = j;
                      var district = dists_data[city[j].value];
                      for (var k in district) {
                        if (district[k].text == that.region.split(",")[2]) {
                          var z = k;
                        }
                      }
                    }
                  }
                }
              }
              area.value = [x, y, z]; //控制初始位置，注意：该方法并不会影响到input的value
            } else {
              area.value = [12, 1, 1]; //控制初始位置，注意：该方法并不会影响到input的value
            }
            area.success = function () {
              that.regionvalue = $("#regionvalue").val();
            };
          }, 200);
        }
      });
    },

    //核销订单
    postChcekData() {
      if (this.isSubmiting) return;
      if (!this.customerName) {
        this.$toast({
          message: "请输入姓名",
          duration: 1500,
        });
      } else if (!/^1[3456789]\d{9}$/.test(this.tel)) {
        this.$toast({
          message: "请输入正确手机号",
          duration: 1500,
        });
      } else if (this.info.ifAddress && (!this.region || !this.address)) {
        this.$toast({
          message: "请输入地址",
          duration: 1500,
        });
      } else {
        let that = this;
        that.isSubmiting = true;
        let params = {};
        params["orgId"] = Number(that.orgId);
        params["actId"] = Number(that.actId);
        params["orderId"] = Number(that.orderId);
        params["custome_name"] = that.customerName;
        params["tel"] = that.tel;
        if (that.iswin) {
          params["iswin"] = Number(that.iswin);
        }
        if (that.info.ifAddress) {
          params["region"] = that.region;
          params["address"] = that.address;
        }
        that
          .$post(that.$api.bizChcekData, params)
          .then(() => {
            that.isSubmiting = false;
            that.$toast({
              message: "提交成功",
              duration: 1500,
            });
            setTimeout(function () {
              that.$router.go(-1);
            }, 1500);
          })
          .catch(() => {
            that.isSubmiting = false;
          });
      }
    },
  },
};
</script>
<style>
@import "../../assets/css/LArea.css";
</style>
