<template>
  <div class="scrollContainer font-12" ref="scrollContainer">
    <div>
      <div class="height-165"></div>
      <div class="fixed top-0 left-0 right-0 z-index-10 bg-f6 pl30 pr30">
        <div class="pt20 pb20">
          <div
            class="relative height-70 ml50 mr50 fn-flex text-center"
            v-if="tab.length < 4"
          >
            <div
              class="fn-flex-item relative tab-menu2 pt10 pb10"
              :class="[used == item.id ? 'font-36 active' : 'font-28']"
              v-for="(item, index) in tab"
              :key="index"
            >
              <a
                class="relative inline-block"
                :class="[used == item.id ? '' : 'pt10']"
                @click="changeTab(item.id)"
                href="javascript:"
                >{{ item.title }}</a
              >
            </div>
          </div>
          <div
            class="relative height-70 space-nowrap text-center scroll-x"
            v-else
          >
            <div
              class="inline-block relative tab-menu2 pt10 pb10 pl25 pr25"
              :class="[used == item.id ? 'font-36 active' : 'font-28']"
              v-for="(item, index) in tab"
              :key="index"
            >
              <a
                class="relative inline-block"
                :class="[used == item.id ? '' : 'pt10']"
                @click="changeTab(item.id)"
                href="javascript:"
                >{{ item.title }}</a
              >
            </div>
          </div>
        </div>
        <div class="pb20 fn-clear">
          <div class="fn-fl relative wp70 pl40 pr150 height-60 bg-fff ulib-r20">
            <i
              class="absolute left-0 box-middle iconfont icon-sousuo ml20 font-24 color-ddd"
            ></i>
            <input
              class="ipt-seach height-55 inp-sty wp100 border-none font-22 ulib-r10"
              type="search"
              placeholder="请输入查找姓名/预留手机号"
              v-model="keyword"
            />
            <button
              class="absolute right-0 box-middle bg-1a1a1a border-none height-60 pl20 pr20 font-22 color-fff ulib-r20"
              @click="searchHandler"
            >
              搜索
            </button>
          </div>
          <select
            class="fn-fr select-more2 height-60 ulib-r10 font-28"
            @change="changePrize"
          >
            <option :value="item.id" v-for="item in prizeList" :key="item.id">
              {{ item.title }}
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="container pb130">
      <div class="inner30 pt0">
        <router-link
          class="block relative mt30 pl30 pr30 pb30 bg-fff ulib-r20"
          :class="used == 1 ? 'op70' : ''"
          v-for="(item, index) in list"
          :key="index"
          :to="{
            path:
              '/order/apply?orgId=' +
              orgId +
              '&actId=' +
              actId +
              '&orderId=' +
              item.orderId +
              '&iswin=' +
              iswin +
              '&used=' +
              used,
          }"
        >
          <div
            class="absolute top-0 right-0 bg-fe9538 pt10 pb10 pl20 pr20 font-22 color-fff ulib-rbl20 ulib-rtr20"
          >
            {{ item.cate }}
          </div>
          <div class="bbs-1-eee pt20 pb20">
            <div>
              <img
                class="text-middle imgsize-35X35 ulib-r750 bds-4-fff"
                :src="item.headimg"
                alt="#"
              />
              <span class="text-middle font-22 color-999">{{
                item.nickname
              }}</span>
              <span class="text-middle font-22 color-999">·</span>
              <span class="text-middle font-22">{{ item.customerName }}</span>
            </div>
            <div class="mt10 fn-clear font-22 color-999">
              <a :href="'tel:' + item.tel" class="fn-fl"
                ><i class="iconfont icon-shouji"></i>{{ item.tel }}</a
              >
              <div class="fn-fr wp70 text-nowrap" v-if="item.address">
                <i class="iconfont icon-dizhi1"></i>{{ item.address }}
              </div>
            </div>
          </div>
          <div class="relative mt20 fn-clear">
            <img class="fn-fl imgsize-180X90 ulib-r5" :src="item.img" alt="#" />
            <div class="pl200">
              <div class="text-nowrap font-32 line-height-13">
                <template v-if="item.grade">
                  <span class="text-middle color-fe9538">{{ item.grade }}</span>
                  <span class="text-middle">·</span>
                </template>
                <span class="text-middle">{{ item.name }}</span>
              </div>
              <div class="mt15 font-22 color-999">{{ item.time }}</div>
            </div>
            <i
              class="absolute right-0 box-middle iconfont icon-yishiyong font-130 color-aaa"
              v-if="used == 1"
            ></i>
          </div>
          <div
            class="bg-eee mt20 pt10 pb10 pl15 pr15 ulib-rtr20 ulib-rbl20 ulib-rbr20 font-22 color-666"
            v-if="item.success"
          >
            {{ item.success }}
          </div>
          <div
            class="bg-fcecde mt20 pt10 pb10 pl15 pr15 ulib-rtr20 ulib-rbl20 ulib-rbr20 font-22 color-fe9538"
            v-if="item.fail"
          >
            {{ item.fail }}
          </div>
        </router-link>
        <mugenscroll
          :handler="fetchData"
          :should-handle="!loading"
          scroll-container="scrollContainer"
        >
          <div class="pt100 pb100 text-center color-ccc" v-if="isNoData">
            <span class="text-middle font-22">暂无数据</span>
          </div>
          <div class="pt20 pb20 text-center color-ccc" v-else-if="loading">
            <i class="iconfont icon-jiazai text-middle"></i
            ><span class="text-middle font-22">请稍等...</span>
          </div>
          <div
            class="pt20 pb20 text-center font-22 color-ccc"
            v-else-if="isDataEnd && list.length > 5"
          >
            我们是有底线的
          </div>
        </mugenscroll>
      </div>
    </div>

    <navBar name="exchange" :orgId="orgId" :actId="actId"></navBar>
  </div>
</template>
<script>
function Debounce(fn, t) {
  let delay = t || 500;
  let timer;
  return function () {
    let args = arguments;
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, args);
    }, delay);
  };
}
import mugenscroll from "vue-mugen-scroll";
import navBar from "../../components/navBar";
import { hiddenShare } from "../../assets/js/utils/wexinShare.js";
import $ from "jquery";
export default {
  components: { mugenscroll, navBar },
  data() {
    return {
      orgId: this.$route.query.orgId,
      actId: this.$route.query.actId,
      keyword: "",
      tab: [
        {
          id: "",
          title: "待核销",
        },
        {
          id: "1",
          title: "已核销",
        },
      ],
      used: "",
      prizeList: [
        {
          id: "",
          title: "订单",
        },
        {
          id: "1",
          title: "抽奖",
        },
      ],
      iswin: "",
      loading: false,
      isDataEnd: false,
      isNoData: false,
      page: 1, //页数
      size: 20, //每页取多少个数据
      list: [],
    };
  },

  created: function () {
    this.getHiddenWxSign();
    this.getOrderLists();
  },

  mounted: function () {},
  methods: {
    //获取微信签名数据
    getHiddenWxSign() {
      let params = {};
      params["url"] = window.location.href;
      this.$get(this.$api.wxSign, params).then((res) => {
        let obj = {
          appId: res.data.signPackage.appid,
          nonceStr: res.data.signPackage.noncestr,
          signature: res.data.signPackage.signature,
          timestamp: res.data.signPackage.timestamp,
        };
        hiddenShare(obj);
      });
    },

    searchHandler: Debounce(function () {
      $(".scrollContainer").animate({ scrollTop: 0 }, 500);
      this.loading = false;
      this.isDataEnd = false;
      this.isNoData = false;
      this.page = 1;
      this.list = [];
      this.getOrderLists();
    }, 250),

    //拉取数据
    fetchData: function () {
      this.getOrderLists();
    },

    //获取订单列表
    getOrderLists() {
      let that = this;
      if (!that.isNoData && !that.isDataEnd && !that.loading) {
        that.loading = true;
        let params = {};
        params["orgId"] = that.orgId;
        params["actId"] = that.actId;
        params["exchange"] = 1;
        if (that.used) {
          params["used"] = that.used;
        }
        if (that.iswin) {
          params["iswin"] = that.iswin;
        }
        if (that.keyword) {
          params["keyword"] = that.keyword;
        }
        params["page"] = that.page;
        params["size"] = that.size;
        that.$get(that.$api.bizOrderLists, params).then((res) => {
          that.loading = false;
          that.page = that.page + 1;
          that.list =
            res.data.lists && res.data.lists.length > 0
              ? that.list.concat(res.data.lists)
              : that.list;
          if (res.data.total == 0) {
            that.isNoData = true;
          } else if (that.list.length == res.data.total) {
            that.isDataEnd = true;
          }
        });
      }
    },

    //tab切换
    changeTab(id) {
      if (id != this.used && !this.loading) {
        $(".scrollContainer").animate({ scrollTop: 0 }, 500);
        this.used = id;
        this.loading = false;
        this.isDataEnd = false;
        this.isNoData = false;
        this.page = 1;
        this.list = [];
        this.getOrderLists();
      }
    },

    //选择奖项
    changePrize(e) {
      if (this.iswin != e.target.value && !this.loading) {
        this.iswin = e.target.value;
        $(".scrollContainer").animate({ scrollTop: 0 }, 500);
        this.loading = false;
        this.isDataEnd = false;
        this.isNoData = false;
        this.page = 1;
        this.list = [];
        this.getOrderLists();
      }
    },
  },
};
</script>
