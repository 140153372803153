import Vue from 'vue';
import Vuex from 'vuex';
import persistedState from 'vuex-persistedstate';//持久化数据存储

Vue.use(Vuex);
const store = new Vuex.Store({
    //准备state -- 用于操存储数据
    state: {
        token:'',
        expires:'',//过期时间
        skey:'',//活动加密参数
        isAdminLogin:false,//是否登录状态
        userInfo:{},//用户信息
    },
    getters: {
        getToken(state) {
            return state.token;
        },
        getExpires(state) {
            return state.expires;
        },
        getSkey(state) {
            return state.skey;
        },
        getIsAdminLogin(state) {
            return state.isAdminLogin;
        },
        getUserInfo(state) {
            return state.userInfo;
        },
    },
    //准备mutations -- 用于操作数据（state）
    mutations: {
        setToken(state, token) {
            state.token = token;
        },
        delToken(state) {
            state.token = '';
        },
        setExpires(state,expires) {
            state.expires = expires;
        },
        delExpires(state) {
            state.expires = '';
        },
        setSkey(state,skey) {
            state.skey = skey;
        },
        delSkey(state) {
            state.skey = '';
        },
        setIsAdminLogin(state,isAdminLogin) {
            state.isAdminLogin = isAdminLogin;
        },
        setUserInfo(state,userInfo) {
            state.userInfo = userInfo;
        },
        delUserInfo(state) {
            state.userInfo = '';
        },
    },
    //vuex的执行顺序是，this.$store.dispatch()触发 actions中的方法，acitons中的方法触发mutations中的方法，mutations中的方法操作state中的数据。
    //准备actions -- 用于响应组件中的动作
    actions: {
        setToken(context, token) {
            context.commit('setToken', token);
        },
        delToken(context) {
            context.commit('delToken');
        },
        setExpires(context, expires) {
            context.commit('setExpires', expires);
        },
        delExpires(context) {
            context.commit('delExpires');
        },
        setSkey(context, skey) {
            context.commit('setSkey', skey);
        },
        delSkey(context) {
            context.commit('delSkey');
        },
        setIsAdminLogin(context,isAdminLogin){
            context.commit('setIsAdminLogin', isAdminLogin); 
        },
        setUserInfo(context,userInfo){
            context.commit('setUserInfo', userInfo); 
        },
        delUserInfo(context){
            context.commit('delUserInfo');
        },
    },
    plugins: [persistedState()]
});
export default store;
