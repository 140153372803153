import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';
import Config from './config.js';
let stringEncrypt = require('./assets/js/utils/stringEncrypt');
import axios from 'axios';
import {get,put}  from './https'; 
import api from './api';
import {Toast} from 'vant';

import Index from './views/index/index.vue';
import Login from './views/login/index.vue';
import Biz from './views/biz/index.vue';
import Act from './views/act/index.vue';
import ActShare from './views/act/share.vue';
import Customer from './views/customer/index.vue';
import ItemDetail from './views/item/detail.vue';
import ItemAgreement from './views/item/agreement.vue';
import Order from './views/order/index.vue';
import OrderExchange from './views/order/exchange.vue';
import OrderApply from './views/order/apply.vue';
import Stic from './views/stic/index.vue';
import SticRanking from './views/stic/ranking.vue';
import SticRankMore from './views/stic/rankMore.vue';
import SticUsers from './views/stic/users.vue';
import Ucenter from './views/ucenter/index.vue';
import UcenterDetail from './views/ucenter/detail.vue';
import UcenterOrders from './views/ucenter/orders.vue';
import UcenterWin from './views/ucenter/win.vue';
import User from './views/user/index.vue';
import Draw from './views/draw/index.vue';


Vue.use(VueRouter);

const router = new VueRouter({
    mode:'history',//hash
    routes: [
        {
            path: '/',
            redirect: '/login',
            meta: {
                title: "登录",
                keepAlive: false,
                bdbg:'#fff',
                admin: false,
                actAdmin: false,
            }
        },
        {
            path: '/index',
            component: Index,
            name: "index",
            meta: {
                title: "选择机构",
                keepAlive: false,
                bdbg:'#fff',
                admin: true,
                actAdmin: false,
            }
        },
        {
            path: '/login',
            component: Login,
            name: "login",
            meta: {
                title: "登录",
                keepAlive: false,
                bdbg:'#fff',
                admin: false,
                actAdmin: false,
            }
        },
        {
            path: '/biz',
            component: Biz,
            name: "biz",
            meta: {
                title: "活动列表",
                keepAlive: false,
                bdbg:'#f6f6f6',
                admin: true,
                actAdmin: false,
            }
        },
        {
            path: '/act',
            component: Act,
            name: "act",
            meta: {
                title: "私域直播",
                keepAlive: false,
                bdbg:'#fff',
                admin: false,
                actAdmin: false,
            }
        },
        {
            path: '/act/share',
            component: ActShare,
            name: "share",
            meta: {
                title: "生成海报",
                keepAlive: false,
                bdbg:'#fff',
                admin: true,
                actAdmin: true,
            }
        },
        {
            path: '/customer',
            component: Customer,
            name: "customer",
            meta: {
                title: "客户管理",
                keepAlive: false,
                bdbg:'#fff',
                admin: true,
                actAdmin: true,
            }
        },
        {
            path: '/item/detail',
            component: ItemDetail,
            name: "itemDetail",
            meta: {
                title: "商品详情",
                keepAlive: false,
                bdbg:'#f6f6f6',
                admin: false,
                actAdmin: false,
            }
        },
        {
            path: '/item/agreement',
            component: ItemAgreement,
            name: "itemAgreement",
            meta: {
                title: "免责条款",
                keepAlive: false,
                bdbg:'#f6f6f6',
                admin: false,
                actAdmin: false,
            }
        },
        {
            path: '/order',
            component: Order,
            name: "order",
            meta: {
                title: "订单",
                keepAlive: false,
                bdbg:'#f6f6f6',
                admin: true,
                actAdmin: true,
            }
        },
        {
            path: '/order/exchange',
            component: OrderExchange,
            name: "exchange",
            meta: {
                title: "中奖名单",
                keepAlive: false,
                bdbg:'#f6f6f6',
                admin: true,
                actAdmin: true,
            }
        },
        {
            path: '/order/apply',
            component: OrderApply,
            name: "apply",
            meta: {
                title: "中奖核销",
                keepAlive: false,
                bdbg:'#f6f6f6',
                admin: true,
                actAdmin: true,
            }
        },
        {
            path: '/stic',
            component: Stic,
            name: "stic",
            meta: {
                title: "数据统计",
                keepAlive: false,
                bdbg:'#fff',
                admin: true,
                actAdmin: false,
            }
        },
        {
            path: '/stic/ranking',
            component: SticRanking,
            name: "sticRanking",
            meta: {
                title: "数据排行",
                keepAlive: false,
                bdbg:'#fff',
                admin: true,
                actAdmin: true,
            }
        },
        {
            path: '/stic/rankMore',
            component: SticRankMore,
            name: "sticRankMore",
            meta: {
                title: "数据排行",
                keepAlive: false,
                bdbg:'#f6f6f6',
                admin: true,
                actAdmin: true,
            }
        },
        {
            path: '/stic/users',
            component: SticUsers,
            name: "sticUsers",
            meta: {
                title: "数据排行",
                keepAlive: false,
                bdbg:'#f6f6f6',
                admin: true,
                actAdmin: true,
            }
        },
        {
            path: '/ucenter',
            component: Ucenter,
            name: "ucenter",
            meta: {
                title: "我的",
                keepAlive: false,
                bdbg:'#f6f6f6',
                admin: false,
                actAdmin: false,
            }
        },
        {
            path: '/ucenter/detail',
            component: UcenterDetail,
            name: "ucenterDetail",
            meta: {
                title: "我的购车权益",
                keepAlive: false,
                bdbg:'#f6f6f6',
                admin: false,
                actAdmin: false,
            }
        },
        {
            path: '/ucenter/orders',
            component: UcenterOrders,
            name: "ucenterOrders",
            meta: {
                title: "我的权益",
                keepAlive: false,
                bdbg:'#f6f6f6',
                admin: false,
                actAdmin: false,
            }
        },
        {
            path: '/ucenter/win',
            component: UcenterWin,
            name: "ucenterWin",
            meta: {
                title: "我的奖品",
                keepAlive: false,
                bdbg:'#f6f6f6',
                admin: false,
                actAdmin: false,
            }
        },
        {
            path: '/user',
            component: User,
            name: "user",
            meta: {
                title: "顾问管理",
                keepAlive: false,
                bdbg:'#f6f6f6',
                admin: true,
                actAdmin: false,
            }
        },
        {
            path: '/draw',
            component: Draw,
            name: "draw",
            meta: {
                title: "私域直播抽奖",
                keepAlive: false,
                bdbg:'#fff',
                admin: false,
                actAdmin: false,
            }
        },
        {
            path: '*',
            redirect: '/login',
            meta: {
                title: "登录",
                keepAlive: false,
                bdbg:'#fff',
                admin: false,
                actAdmin: false,
            }
        },
    ],
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {
                x: 0,
                y: 0
            }
        }
    }
});

// 解析url参数并获取code
function getUrlParam(key) {
    let href = window.location.href;
    let url = href.split("?");
    if(url.length <= 1){
       return "";
    }
    let params = url[1].split("&");
    for(let i=0; i<params.length; i++){
        let param = params[i].split("=");
       if(key == param[0]){
          return param[1];
       }
    }
}

let num = 0

// 路由变化时
router.beforeEach((to, from, next) => {

    num = num +1
    console.log(num);
    console.log(store.getters.getToken);
    console.log(store.getters.getExpires);

    if (to.name == 'draw') {
        next()
    }else{
        //判断是否微信环境
        //let isMobile = window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i); // 是否手机端
        let isWx = /micromessenger/i.test(navigator.userAgent); // 是否微信
        let isComWx = /wxwork/i.test(navigator.userAgent); // 是否企业微信
        if (!isWx&&!isComWx) { // 手机端微信
            //跳转到微信授权页面
            sessionStorage.setItem('redirectPath', to.fullPath)
            let redirectURL = encodeURIComponent(
                (
                "https://" +
                document.domain +
                //'/#' +  //hash
                to.fullPath
                ).split("&code")[0]
            ); //获取地址
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${Config.appId}&redirect_uri=${redirectURL}&response_type=code&scope=snsapi_base&state=syauthbase#wechat_redirect`
            return;
        }

        let state = getUrlParam("state") || '';

        // token已存在,而且不是授权用户信息，直接进入页面
        if (store.getters.getToken&&state.indexOf("syauthuserinfo") == -1) {
            // 修复iOS版微信分享HTML5 History兼容性问题
            let u = navigator.userAgent;
            let IOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
            if(to.meta.admin){
                if(to.meta.actAdmin){
                    if(store.getters.getUserInfo.actIsAdmin&&store.getters.getIsAdminLogin){
                        window.document.title = to.meta.title;
                        if (IOS && to.path !== location.pathname) {
                            location.assign(to.fullPath)
                        } else {
                            next()
                        }
                    }else{
                        window.document.title = '登录';
                        if (IOS && to.path !== location.pathname) {
                            location.assign('/login')
                        } else {
                            next({
                                path: '/login'
                            });
                        }
                    }
                }else{
                    if(store.getters.getUserInfo.isAdmin&&store.getters.getIsAdminLogin){
                        window.document.title = to.meta.title;
                        if (IOS && to.path !== location.pathname) {
                            location.assign(to.fullPath)
                        } else {
                            next()
                        }
                    }else{
                        window.document.title = '登录';
                        if (IOS && to.path !== location.pathname) {
                            location.assign('/login')
                        } else {
                            next({
                                path: '/login'
                            });
                        }
                    }
                }
            }else{
                window.document.title = to.meta.title;
                if (IOS && to.path !== location.pathname&&!(to.path.indexOf("/ucenter/orders") != -1&&from.path.indexOf("/item/detail") != -1)) {
                    location.assign(to.fullPath)
                } else {
                    next()
                }
            }
            return
        }
        let code = getUrlParam("code") || ''; //获取url 上面的code
        if (sessionStorage.getItem("isauth")&&state.indexOf("syauthuserinfo") != -1) {
            next()
        }else if(code&&(state.indexOf("syauthbase") != -1 || state.indexOf("syauthuserinfo") != -1)){
            console.log('返回的code和state')
            console.log(state.indexOf("syauthbase") != -1)
            console.log(state.indexOf("syauthuserinfo") != -1)
            //snsapi_base微信静默授权
            if(state.indexOf("syauthbase") != -1){
                let orgParams = {};
                orgParams["code"] = code;
                orgParams["nonce_str"] = stringEncrypt.randomString();
                let serectParams = {
                    ...orgParams,
                    sign: stringEncrypt.toEncrypt(orgParams)
                }
                //console.log(Config.baseURL+api.token+'?code='+serectParams.code+'&nonce_str='+serectParams.nonce_str+'&sign='+serectParams.sign)
                axios({
                    url:Config.baseURL+api.token,
                    params:serectParams
                }).then(res => {
                    if (res.data.code == 200) {
                        store.commit("setToken",res.data.data.token);
                        store.commit("setExpires",new Date().getTime()+res.data.data.expiresTime*1000);
                        get(api.userInfo, {}).then(res2 => {
                            store.commit("setUserInfo",res2.data);
                            if(to.meta.admin){
                                if(to.meta.actAdmin){
                                    if(res2.data.actIsAdmin&&store.getters.getIsAdminLogin){
                                        window.document.title = to.meta.title;
                                        router.replace(sessionStorage.getItem('redirectPath')) //history
                                        //window.location.href = "https://" + document.domain + '/#'+ sessionStorage.getItem('redirectPath') //hash
                                    }else{
                                        window.document.title = '登录';
                                        router.replace('/login') //history
                                        //window.location.href = "https://" + document.domain + '/#/login'  //hash
                                    }
                                }else{
                                    if(res2.data.isAdmin&&store.getters.getIsAdminLogin){
                                        window.document.title = to.meta.title;
                                        router.replace(sessionStorage.getItem('redirectPath'))  //history
                                        //window.location.href = "https://" + document.domain + '/#'+ sessionStorage.getItem('redirectPath')  //hash
                                    }else{
                                        window.document.title = '登录';
                                        router.replace('/login') //history
                                        //window.location.href = "https://" + document.domain + '/#/login' //hash
                                    }
                                }
                            }else{
                                window.document.title = to.meta.title;
                                router.replace(sessionStorage.getItem('redirectPath')) //history
                                //window.location.href = "https://" + document.domain + '/#'+ sessionStorage.getItem('redirectPath') //hash
                            }
                        });

                        // store.dispatch("setToken",res.data.data.token);
                        // store.dispatch("setExpires",new Date().getTime()+res.data.data.expiresTime*1000);

                        // let redirectPath = sessionStorage.getItem('redirectPath')
                        // if (redirectPath) {
                        //     sessionStorage.setItem('redirectPath', '')
                        //     window.document.title = to.meta.title;
                        //     next({ path: redirectPath })
                        // } else {
                        //     window.document.title = to.meta.title;
                        //     let upath = getUrlParam("state");
                        //     let npath = upath.replace('liche168#','');
                        //     console.log(upath);
                        //     console.log(npath);
                        //     //next({ path: npath })
                        //     next()
                        // }
                    }else{
                        Toast({
                            message: res.data.msg,
                            duration: 1500,
                        });
                        // store.dispatch('delToken').then(() => {
                        //     location.reload() // 为了重新实例化vue-router对象 避免bug
                        // })
                    }
                })
            }else if(state.indexOf("syauthuserinfo") != -1){//snsapi_userinfo微信授权用户信息

                // axios.defaults.headers.common['Authorization'] = store.getters.getToken;
                // let orgParams2 = {};
                // orgParams2["code"] = code;
                // orgParams2["nonce_str"] = stringEncrypt.randomString();
                // let serectParams2 = {
                //     ...orgParams2,
                //     sign: stringEncrypt.toEncrypt(orgParams2)
                // }
                // console.log(serectParams2);
                // axios.put(Config.baseURL+api.updateUser,serectParams2).then(res => {
                //     console.log(res);
                // });
                put(api.updateUser, {code: code }).then( () => {
                    get(api.userInfo, {}).then(res2 => {
                        sessionStorage.setItem('isauth', true)
                        store.commit("setUserInfo",res2.data);     
                        router.replace(sessionStorage.getItem('userUedirectPath')) //history
                        //window.location.href = sessionStorage.getItem('userUedirectPath'); //hash
                    }).catch(() => {
                        setTimeout(function () {
                            router.replace(sessionStorage.getItem('userUedirectPath')) //history
                            //window.location.href = sessionStorage.getItem('userUedirectPath'); //hash
                        }, 1500);
                    });
                }).catch(() => {
                    setTimeout(function () {
                        router.replace(sessionStorage.getItem('userUedirectPath')) //history
                        //window.location.href = sessionStorage.getItem('userUedirectPath'); //hash
                    }, 1500);
                })
            }


            // get('decodeCode', {code: code }).then(res => {
            //     store.dispatch("setToken",res.token);
            //     let redirectPath = sessionStorage.getItem('redirectPath')
            //     if (redirectPath) {
            //         sessionStorage.setItem('redirectPath', '')
            //         next({ path: redirectPath })
            //     } else {
            //         next()
            //     }
            // });
        }else{
            //跳转到微信授权页面
            sessionStorage.setItem('redirectPath', to.fullPath)
            let redirectURL = encodeURIComponent(
                (
                "https://" +
                document.domain +
                //'/#' +  //hash
                to.fullPath
                ).split("&code")[0]
            ); //获取地址
            window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${Config.appId}&redirect_uri=${redirectURL}&response_type=code&scope=snsapi_base&state=syauthbase#wechat_redirect`
        }
    }

});


// router.beforeEach((to, from, next) => {

//     console.log(to);
//     window.document.title = to.meta.title;
//     if (!!to.meta.auth) {
//         let token = store.getters.getToken //获取用户登录时生成的token
//         if (token) {
//             next(); //如果token存在，说明用户已经登录，调用next方法，不妨碍用户继续浏览
//         } else {
//             if (to.path !== '/login') { //如果用户没有登录浏览别的页面，则强制跳转到登录页面
//                 next({
//                     path: '/login'
//                 });
//             } else {
//                 next();
//             }
//         }
//     } else {
//         next();
//     }
// });


export default router;


