/** 
 * date:2019-10-17
 * author: lien
 * desc: import StringEncrypt from '文件路径'; StringEncrypt.toEncrypt(this.data.dd,'xmfish')
 */
let md5 = require('../lib/md5');
let Config = require('../../../config');

/** 
 * utf-8加密
*/
function encodeUtf8(text) {
  const code = encodeURIComponent(text);
  const bytes = [];
  for (var i = 0; i < code.length; i++) {
      const c = code.charAt(i);
      if (c === '%') {
          const hex = code.charAt(i + 1) + code.charAt(i + 2);
          const hexVal = parseInt(hex, 16);
          bytes.push(hexVal);
          i += 2;
      } else bytes.push(c.charCodeAt(0));
  }
  return bytes;
}

/** 
 * utf-8解密
*/
function decodeUtf8(bytes) {
  var encoded = "";
  for (var i = 0; i < bytes.length; i++) {
      encoded += '%' + bytes[i].toString(16);
  }
  return decodeURIComponent(encoded);
}


/** 
 * 生成从minNum到maxNum的随机数
 */
function randomNum(minNum, maxNum) {
  switch (arguments.length) {
    case 1:
      return parseInt(Math.random() * minNum + 1, 10);
      break;
    case 2:
      return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
      break;
    default:
      return 0;
      break;
  }
}

/** 
 * 随机加密串
 */
function randomString(len) {
  len = len || 32;
  let $chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'; /****默认去掉了容易混淆的字符oOLl,9gq,Vv,Uu,I1****/
  let maxPos = $chars.length;
  let pwd = '';
  let newLen = randomNum(10, len)

  for (let i = 0; i < newLen; i++) {
    pwd += $chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return pwd;
}

/** 
 * 获取数据
 */
let getData = (DATA) => {
  return DATA
}

/** 
 * 删除空值
 */
let delEmptyValue = (DATA) => {
  let data = getData(DATA)
  let newData = {}
  for (let i in data) {
    if (data[i] !== '' && Object.prototype.toString.call(data[i]) != "[object Array]" && Object.prototype.toString.call(data[i]) != "[object Object]") {
      newData[i] = data[i]
    }
  }
  return newData
}

/** 
 * 按ascii码从小到大排序
 */
let sortByAscii = (DATA) => {
  let obj = {
    ...delEmptyValue(DATA)
  }
  let arr = new Array();
  let num = 0;
  for (let i in obj) {
    arr[num] = i;
    num++;
  }
  let sortArr = arr.sort();
  //let sortObj = {};    //完成排序值
  let str = ''; //自定义排序字符串
  for (let i in sortArr) {
    str += sortArr[i] + '=' + obj[sortArr[i]] + '&';
    //sortObj[sortArr[i]] = obj[sortArr[i]];
  }
  //去除两侧字符串
  let char = '&'
  str = str.replace(new RegExp('^\\' + char + '+|\\' + char + '+$', 'g'), '');

  return str
  //return sortObj
}

/** 
 * 拼接字符串
 */
let combineUrl = (DATA) => {
  let data = sortByAscii(DATA)
  let str = data + '&key=' + Config.key
  // console.log("发送的字符串："+str)
  return str
}

/** 
 * 加密字符串
 */
let toEncrypt = (DATA) => {
  // let secretKey = randomString()
  let data = combineUrl(DATA)
  return md5.hex_md5(data);
}

module.exports = {
  toEncrypt,
  randomString
}