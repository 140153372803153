import Vue from 'vue';
import 'amfe-flexible/index.js';
import Vuelidate from 'vuelidate';
import infiniteScroll from 'vue-infinite-scroll';
import App from './App.vue';
import router from './router';
import store from './store';
import api from './api';
import { Toast } from "vant";
import config from './config.js';
import './assets/css/normalize.css';
import './assets/css/common.scss';
import './assets/iconfont/iconfont.css';
import {axiosService,get,post,put,patch}  from './https'; 
import BaiduMap from 'vue-baidu-map';

Vue.config.productionTip = false;
Vue.config.ignoredElements = ["wx-open-launch-weapp", "wx-open-subscribe"];//访问微信小程序

Vue.prototype.$axios = axiosService;
Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$put = put;
Vue.prototype.$patch = patch;
Vue.prototype.$api = api;
Vue.prototype.$toast = Toast;
Vue.prototype.$config = config;

Vue.use(Vuelidate);
Vue.use(infiniteScroll)
Vue.use(BaiduMap, {ak:'AAvX1r8MMqigGpq4GhClew4a4XPcuyH3'})

Toast.setDefaultOptions({ duration: 1500 });
Toast.setDefaultOptions('loading', { forbidClick: true,duration: 500 });

//store.dispatch('delToken')
// store.dispatch("setToken",'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJCYXNlQ2xhaW1zIjp7Im9wZW5pZCI6Im9qaHkzNWxPeDA2LUVDekM4T0dTT0tlaHNKVTQiLCJ1bmlvbmlkIjoiJSFzKFx1MDAzY25pbFx1MDAzZSkifSwiZXhwIjoxNjgxMzU3NDEzLCJpc3MiOiLlpb3lupfkupEiLCJuYmYiOjE2ODEyNzAwMTN9.cmqx-X5-zeIDHMAs8abyPSNIGpdUWOfXp584h1kiA7A');
// store.dispatch("setExpires",1686455014760);

// store.dispatch("setToken",'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJCYXNlQ2xhaW1zIjp7ImFkbWluX21vYmlsZSI6IiIsIm9wZW5pZCI6Im9FeWFtNWowQ1pTVXYyUjBfcXNQX0p3ajNkcFUiLCJ1bmlvbmlkIjoiIn0sImV4cCI6MTY4NjYzODUzMSwiaXNzIjoi5aW95bqX5LqRIiwibmJmIjoxNjg2NTUxMTMxfQ.Oaft_RZGiqmiCD3vZGC1vVreOxOuPu-fRNjuF8BzY40');
// store.dispatch("setExpires",1686638527728);

// store.dispatch("setToken",'');
// store.dispatch("setExpires",'');

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
});