<template>
  <div class="scrollContainer font-12" ref="scrollContainer">
    <div>
      <div class="height-80"></div>
      <div
        class="fixed top-0 left-0 right-0 z-index-10 bg-fff pl30 pr30 pt20 pb20"
      >
        <div
          class="relative height-70 ml50 mr50 fn-flex text-center"
          v-if="info.tab.length < 4"
        >
          <div
            class="fn-flex-item relative tab-menu2 pt10 pb10"
            :class="[tabId == item.id ? 'font-36 active' : 'font-28']"
            v-for="(item, index) in info.tab"
            :key="index"
          >
            <a
              class="relative inline-block"
              :class="[tabId == item.id ? '' : 'pt10']"
              @click="changeTab(item.id)"
              href="javascript:"
              >{{ item.title }}</a
            >
          </div>
        </div>
        <div
          class="relative height-70 space-nowrap text-center scroll-x"
          v-else
        >
          <div
            class="inline-block relative tab-menu2 pt10 pb10 pl25 pr25"
            :class="[tabId == item.id ? 'font-36 active' : 'font-28']"
            v-for="(item, index) in info.tab"
            :key="index"
          >
            <a
              class="relative inline-block"
              :class="[tabId == item.id ? '' : 'pt10']"
              @click="changeTab(item.id)"
              href="javascript:"
              >{{ item.title }}</a
            >
          </div>
        </div>
      </div>
    </div>
    <div class="container pt40 pb130">
      <div class="pt20 pb30 ml30 mr30 bg-fff box-shadow-darkGray ulib-r20">
        <div class="relative ml30 pt10 height-60">
          <div class="pt10 font-30" v-if="tabId == 0">合计 {{ total }} 人</div>
          <select
            class="height-60 select-more bg-f6 ulib-r10 font-28"
            @change="changeEmployee"
            v-if="userInfo.actAdminLevel == 1 && (tabId == 1 || tabId == 2)"
          >
            <option
              :value="item.userId"
              v-for="item in siftEmployeeList"
              :key="item.userId"
              :selected="employeeId == item.userId"
            >
              {{ item.uname }}
            </option>
          </select>
          <select
            class="height-60 select-more bg-f6 ulib-r10 font-28"
            @change="changeVisitTab"
            v-if="tabId == 2"
          >
            <option
              :value="item.id"
              v-for="item in siftVisitTab"
              :key="item.id"
              :selected="tagId == item.id"
            >
              {{ item.title }}
            </option>
          </select>
          <a
            class="block absolute box-middle right-0 pt10 pb10 pl55 pr10 bg-ff9d47 ulib-rl750"
            @click="showAllotMultiple"
            v-if="userInfo.actAdminLevel == 1 && tabId == 0"
          >
            <div
              class="inline-block absolute left-0 box-middle ml15 imgsize-32X32 bg-fff ulib-r750"
            >
              <i
                class="absolute box-center-middle iconfont icon-fenpei font-22 color-ff9d47"
              ></i>
            </div>
            <span class="line-height-11 font-28 color-fff">统一分配</span>
          </a>
          <div
            class="absolute box-middle right-0 pt10 pr30 font-30"
            v-if="tabId == 1 || tabId == 2"
          >
            合计 {{ total }} 人
          </div>
        </div>
        <div
          class="block relative mt30 ml30 mr30 pl30 pr30 pt45 pb45 bg-f6 ulib-r20"
          :class="item.status == 2 ? 'op70' : ''"
          v-for="(item, index) in list"
          :key="index"
        >
          <div
            class="absolute top-0 right-0 bg-fe9538 pt8 pb5 pl20 pr20 font-22 color-fff ulib-rbl20 ulib-rtr20"
            v-if="item.isAppoint || item.isWechat || item.isRights"
          >
            <span class="text-middle" v-if="item.isAppoint">已预约</span>
            <span class="text-middle" v-if="item.isAppoint && item.isWechat"
              >·</span
            >
            <span class="text-middle" v-if="item.isWechat">已加微信</span>
            <span
              class="text-middle"
              v-if="
                (item.isWechat && item.isRights) ||
                (!item.isWechat && item.isAppoint && item.isRights)
              "
              >·</span
            >
            <span class="text-middle" v-if="item.isRights">已领权益</span>
          </div>
          <div class="relative pr150">
            <div class="font-32">
              <span class="text-middle">{{ item.customerName }}</span>
              <span class="text-middle">·</span>
              <a class="text-middle" :href="'tel:' + item.tel">
                <span class="text-middle">{{ item.tel }} </span>
                <i class="text-middle iconfont icon-tel font-24"></i>
              </a>
            </div>
            <div class="mt20" v-if="item.status != 0">
              <div class="font-22">
                <span class="text-middle color-999">顾问</span>
                <span class="text-middle color-999">·</span>
                <img
                  class="text-middle imgsize-35X35 ulib-r750 bds-4-fff"
                  :src="item.headimg"
                  alt="#"
                />
                <span class="text-middle">{{ item.employeeName }}</span>
                <div
                  class="inline-block text-middle bg-fe9538 pt5 pb0 pl15 pr15 font-18 color-fff ulib-r750"
                  v-if="item.grade"
                >
                  <span class="text-middle">{{ item.grade }}</span>
                </div>
              </div>
            </div>
            <a
              class="block absolute right-0 box-middle pt8 pb5 pl20 pr20 bg-333 font-28 color-fff ulib-r750"
              v-if="userInfo.actAdminLevel == 1 && item.status == 0"
              @click="showAllotSingle(index)"
              >分配</a
            >
            <a
              class="block absolute right-0 box-middle pt8 pb5 pl20 pr20 bg-333 font-28 color-fff ulib-r750"
              v-if="item.status == 1"
              @click="showFollowUp(index)"
              >跟进</a
            >
            <a
              class="block absolute right-0 box-middle pt10 pb10 pl20 pr20 bg-333 font-28 color-fff ulib-r750"
              v-if="item.status == 2"
              @click="showFollowUp(index)"
              >修改</a
            >
          </div>
          <div
            class="mt20 pt10 pb10 pl15 pr15 ulib-rtr20 ulib-rbl20 ulib-rbr20 font-22 color-fe9538"
            :class="item.status == 2 ? 'bg-eee' : 'bg-fcecde'"
            v-if="item.tip"
          >
            {{ item.tip }}
          </div>
        </div>
        <mugenscroll
          :handler="fetchData"
          :should-handle="!loading"
          scroll-container="scrollContainer"
        >
          <div class="pt100 pb100 text-center color-ccc" v-if="isNoData">
            <span class="text-middle font-22">暂无数据</span>
          </div>
          <div class="pt20 pb20 text-center color-ccc" v-else-if="loading">
            <i class="iconfont icon-jiazai text-middle"></i
            ><span class="text-middle font-22">请稍等...</span>
          </div>
          <div
            class="pt20 pb20 text-center font-22 color-ccc"
            v-else-if="isDataEnd && list.length > 5"
          >
            我们是有底线的
          </div>
        </mugenscroll>
      </div>
    </div>

    <navBar name="customer" :orgId="orgId" :actId="actId"></navBar>

    <!--跟进-->
    <div
      class="msg fn-hide"
      :style="msgisShowFollowUp ? 'display:block' : 'display:none'"
      v-if="msgisShowFollowUp"
    >
      <div class="msgBg" @click="msgisShowFollowUp = false"></div>
      <div class="msgMain">
        <div class="content">
          <div class="word pl20 pr20">
            <div class="pt10 pb10 fn-clear">
              <div class="fn-fl font-36">客户标签</div>
              <div class="fn-fr" v-if="list[followIndex].isWechat == 1">
                <i
                  class="iconfont text-middle font-32"
                  :class="isWechat == 1 ? 'icon-checked' : 'icon-check'"
                ></i
                ><span class="font-28 text-middle">已添加微信</span>
              </div>
              <div class="fn-fr" @click="addwechat" v-else>
                <i
                  class="iconfont text-middle font-32"
                  :class="isWechat == 1 ? 'icon-checked' : 'icon-check'"
                ></i
                ><span class="font-28 text-middle">已添加微信</span>
              </div>
            </div>
            <a
              class="block mt20 pt20 pb20 text-center font-28 ulib-r20"
              :class="
                followId == item.id
                  ? 'bg-fff bds-2-1a1a1a'
                  : 'bg-f6 bds-2-fff color-999'
              "
              @click="followId = item.id"
              v-for="(item, index) in info.visitTab"
              :key="index"
              >{{ item.title }}</a
            >
          </div>
          <div class="opt mt20 pl20 pr20 fn-flex">
            <a
              class="fn-flex-item bg-fff bds-2-1a1a1a mr15 pt20 pb20 text-center font-32 ulib-r750"
              @click="msgisShowFollowUp = false"
              href="javascript:"
              >取消</a
            >
            <a
              class="fn-flex-item bg-1a1a1a ml15 pt20 pb20 text-center font-32 color-fff ulib-r750"
              @click="putCustomer"
              href="javascript:"
              >保存</a
            >
          </div>
        </div>
      </div>
    </div>

    <!--单个分配-->
    <div
      class="msg fn-hide"
      :style="msgisShowAllotSingle ? 'display:block' : 'display:none'"
      v-if="msgisShowAllotSingle"
    >
      <div class="msgBg" @click="msgisShowAllotSingle = false"></div>
      <div class="msgMain">
        <div class="content">
          <div class="word pl20 pr20">
            <div class="pt10 pb10 text-center font-36">选择顾问</div>
            <div
              class="mt20 inner30 pt10 bds-1-eee ulib-r20 max-height-500 scroll-y"
            >
              <a
                class="block mt20 inner20 font-28 ulib-r20 fn-clear"
                :class="
                  allotEmployeeId == item.userId
                    ? 'bg-fff bds-2-1a1a1a'
                    : 'bg-f6 bds-2-fff color-999'
                "
                @click="allotEmployeeId = item.userId"
                v-for="(item, index) in employeeList"
                :key="index"
              >
                <span class="fn-fl">{{ item.uname }}</span>
                <i
                  class="fn-fr iconfont text-middle font-32"
                  :class="
                    allotEmployeeId == item.userId
                      ? 'icon-checked'
                      : 'icon-check'
                  "
                ></i>
              </a>
            </div>
          </div>
          <div class="opt mt20 pl20 pr20 fn-flex">
            <a
              class="fn-flex-item bg-fff bds-2-1a1a1a mr15 pt20 pb20 text-center font-32 ulib-r750"
              @click="msgisShowAllotSingle = false"
              href="javascript:"
              >取消</a
            >
            <a
              class="fn-flex-item bg-1a1a1a ml15 pt20 pb20 text-center font-32 color-fff ulib-r750"
              @click="submitAllotSingle"
              href="javascript:"
              >保存</a
            >
          </div>
        </div>
      </div>
    </div>

    <!--统一分配-->
    <div
      class="msg fn-hide"
      :style="msgisShowAllotMultiple ? 'display:block' : 'display:none'"
      v-if="msgisShowAllotMultiple"
    >
      <div class="msgBg" @click="msgisShowAllotMultiple = false"></div>
      <div class="msgMain">
        <div class="content">
          <div class="word pl20 pr20">
            <div class="pt10 pb10 fn-clear">
              <div class="fn-fl font-36">分配方案</div>
              <div class="fn-fr pt5">
                <span class="font-22">待分配总数</span>
                <span class="font-28 color-ff9d47">{{ total }}</span>
              </div>
            </div>
            <div class="mt20 fn-flex text-center">
              <div
                class="fn-flex-item inner20 mr15 font-28 ulib-r20"
                :class="
                  assig_type == 0
                    ? 'bg-fff bds-2-1a1a1a'
                    : 'bg-f6 bds-2-fff color-999'
                "
                @click="customise"
              >
                手动分
              </div>
              <div
                class="fn-flex-item inner20 ml15 font-28 ulib-r20"
                :class="
                  assig_type == 1
                    ? 'bg-fff bds-2-1a1a1a'
                    : 'bg-f6 bds-2-fff color-999'
                "
                @click="average"
              >
                平均分
              </div>
            </div>
            <div
              class="mt20 inner20 pt10 bds-1-eee ulib-r20 max-height-500 scroll-y"
            >
              <div
                class="mt20 inner10 fn-clear"
                v-for="(item, index) in multipleEmployeeList"
                :key="index"
              >
                <span class="fn-fl inline-block pt20 font-32">{{
                  item.uname
                }}</span>
                <div
                  class="fn-fr relative pl50 pr50 bg-fff bds-1-eee ulib-r20 overflowhidden"
                >
                  <div
                    class="block absolute left-0 top-0 bottom-0 bg-f6 w-50"
                    @click="minus(index)"
                  >
                    <i
                      class="absolute box-center-middle iconfont icon-jian font-22"
                    ></i>
                  </div>
                  <input
                    class="w-50 pt10 pb10 bg-transparent border-none text-center font-28"
                    type="number"
                    v-model="item.num"
                    @blur="blurNum(index)"
                    placeholder=""
                  />
                  <div
                    class="block absolute right-0 top-0 bottom-0 bg-f6 w-50"
                    @click="add(index)"
                  >
                    <i
                      class="absolute box-center-middle iconfont icon-jia font-22"
                    ></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="opt mt20 pl20 pr20 fn-flex">
            <div
              class="fn-flex-item bg-fff bds-2-1a1a1a mr15 pt20 pb20 text-center font-32 ulib-r750"
              @click="msgisShowAllotMultiple = false"
              href="javascript:"
            >
              取消
            </div>
            <div
              class="fn-flex-item bg-1a1a1a ml15 pt20 pb20 text-center font-32 color-fff ulib-r750"
              @click="submitAllotMultiple"
              href="javascript:"
            >
              保存
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mugenscroll from "vue-mugen-scroll";
import navBar from "../../components/navBar";
import { hiddenShare } from "../../assets/js/utils/wexinShare.js";
export default {
  components: { mugenscroll, navBar },
  data() {
    return {
      orgId: this.$route.query.orgId,
      actId: this.$route.query.actId,
      employeeList: [],
      info: {
        tab: [],
        visitTab: [],
      },
      tabId: "",
      ActivityId: "0",
      loading: false,
      isDataEnd: false,
      isNoData: false,
      page: 1, //页数
      size: 20, //每页取多少个数据
      list: [],
      total: 0,
      isSubmiting: false,

      employeeId: "",
      siftEmployeeList: [
        {
          userId: "",
          uname: "顾问",
        },
      ],
      tagId: "",
      siftVisitTab: [
        {
          id: "",
          title: "标签",
        },
      ],

      msgisShowFollowUp: false, //是否显示跟踪弹窗
      followIndex: -1,
      followId: "",
      isWechat: 0,

      msgisShowAllotSingle: false, //是否显示分配单个弹窗
      allotEmployeeId: "",

      msgisShowAllotMultiple: false, //是否显示统一分配弹窗
      assig_type: "0", //0手动分 /1平均分
      multipleEmployeeList: [], //顾问分配列表

      userInfo: {},
    };
  },

  created: function () {
    this.userInfo = this.$store.getters.getUserInfo;
    this.getHiddenWxSign();
    this.getCusTag();
    if (this.userInfo.actAdminLevel == 1) {
      this.getUserLists();
    }
  },

  mounted: function () {},
  methods: {
    //获取微信签名数据
    getHiddenWxSign() {
      let params = {};
      params["url"] = window.location.href;
      this.$get(this.$api.wxSign, params).then((res) => {
        let obj = {
          appId: res.data.signPackage.appid,
          nonceStr: res.data.signPackage.noncestr,
          signature: res.data.signPackage.signature,
          timestamp: res.data.signPackage.timestamp,
        };
        hiddenShare(obj);
      });
    },

    //显示跟进
    showFollowUp(index) {
      this.followIndex = index;
      this.followId = "";
      this.msgisShowFollowUp = true;
      this.isWechat = this.list[index].isWechat;
    },

    //添加微信
    addwechat() {
      this.isWechat = this.isWechat == 1 ? 0 : 1;
    },

    //更新客户信息
    putCustomer() {
      if (this.isSubmiting) return;
      if (this.followId == "") {
        this.$toast({
          message: "请选择跟进情况",
          duration: 1500,
        });
      } else {
        let that = this;
        that.isSubmiting = true;
        let params = {};
        params["orgId"] = Number(that.orgId);
        params["actId"] = Number(that.actId);
        params["customerId"] = Number(that.list[that.followIndex].customerId);
        params["tagId"] = Number(that.followId);
        params["isWechat"] = Number(that.isWechat);
        that
          .$put(that.$api.bizCustomer, params)
          .then(() => {
            that.isSubmiting = false;
            that.msgisShowFollowUp = false;
            that.$toast({
              message: "提交成功",
              duration: 1500,
            });
            that.loading = false;
            that.isDataEnd = false;
            that.isNoData = false;
            that.page = 1;
            that.list = [];
            that.getCustomerLists();
          })
          .catch(() => {
            that.isSubmiting = false;
          });
      }
    },

    //显示单个分配
    showAllotSingle(index) {
      this.allotCustomerIndex = index;
      this.allotEmployeeId = "";
      this.msgisShowAllotSingle = true;
    },

    //提交单个分配
    submitAllotSingle() {
      if (this.isSubmiting) return;
      if (this.allotEmployeeId == "") {
        this.$toast({
          message: "请选顾问",
          duration: 1500,
        });
      } else {
        let that = this;
        that.isSubmiting = true;
        let params = {};
        params["orgId"] = Number(that.orgId);
        params["actId"] = Number(that.actId);
        params["type"] = 0;
        params["customerId"] = Number(
          that.list[that.allotCustomerIndex].customerId
        );
        params["userId"] = Number(that.allotEmployeeId);
        that
          .$post(that.$api.bizAllotCustomer, params)
          .then(() => {
            that.isSubmiting = false;
            that.msgisShowAllotSingle = false;
            that.$toast({
              message: "提交成功",
              duration: 1500,
            });
            let list = that.list;
            list.splice(that.allotCustomerIndex, 1);
            that.list = list;
            that.total = that.total - 1;
          })
          .catch(() => {
            that.isSubmiting = false;
          });
      }
    },

    //显示统一分配
    showAllotMultiple() {
      this.msgisShowAllotMultiple = true;
      let employeeList = this.employeeList;
      employeeList.forEach((item) => {
        item.num = 0;
      });
      this.multipleEmployeeList = employeeList;
    },

    //自定义分
    customise() {
      this.assig_type = 0;
      let employeeList = this.employeeList;
      employeeList.forEach((item) => {
        item.num = 0;
      });
      this.multipleEmployeeList = employeeList;
    },

    //平均分
    average() {
      this.assig_type = 1;
      if (this.total > 0 && this.employeeList.length > 0) {
        let num = Math.floor(this.total / this.employeeList.length);
        let remainder = this.total % this.employeeList.length;
        let employeeList = this.employeeList;
        employeeList.forEach((item, index) => {
          if (index < remainder) {
            item.num = num + 1;
          } else {
            item.num = num;
          }
        });
        this.multipleEmployeeList = employeeList;
      }
    },

    //加
    add(index) {
      let curtotal = 0;
      this.employeeList.forEach((item) => {
        curtotal = curtotal + item.num;
      });

      if (curtotal < this.total) {
        let obj = this.multipleEmployeeList[index];
        obj.num = obj.num + 1;
        this.$set(this.multipleEmployeeList, index, obj);
      } else {
        this.$toast({
          message: "已超过可以分配数量",
          duration: 1500,
        });
      }
    },

    //减
    minus(index) {
      if (this.multipleEmployeeList[index].num > 0) {
        let obj = this.multipleEmployeeList[index];
        obj.num = obj.num - 1;
        this.$set(this.multipleEmployeeList, index, obj);
      }
    },

    //输入判断
    blurNum(index) {
      let obj = this.multipleEmployeeList[index];
      obj.num = Math.floor(obj.num);
      if (obj.num < 0) {
        obj.num = 0;
      }
      if (obj.num > 0) {
        let curtotal = 0;
        this.employeeList.forEach((item, i) => {
          if (index != i) {
            curtotal = curtotal + item.num;
          }
        });
        if (curtotal + obj.num > this.total) {
          this.$toast({
            message: "已超过可以分配数量",
            duration: 1500,
          });
          obj.num = this.total - curtotal;
        }
      }
      this.$set(this.multipleEmployeeList, index, obj);
    },

    //提交统一分配
    submitAllotMultiple() {
      if (this.isSubmiting) return;
      let multipleEmployeeList = [];
      this.multipleEmployeeList.forEach((item) => {
        if (item.num > 0) {
          multipleEmployeeList.push({
            userId: Number(item.userId),
            num: Number(item.num),
          });
        }
      });
      if (multipleEmployeeList.length == 0) {
        this.$toast({
          message: "请填写分配数量",
          duration: 1500,
        });
      } else {
        let that = this;
        that.isSubmiting = true;
        let params = {};
        params["orgId"] = Number(that.orgId);
        params["actId"] = Number(that.actId);
        params["type"] = 1;
        params["config"] = multipleEmployeeList;
        that
          .$post(that.$api.bizAllotCustomer, params)
          .then(() => {
            that.isSubmiting = false;
            that.msgisShowAllotMultiple = false;
            that.$toast({
              message: "提交成功",
              duration: 1500,
            });
            that.loading = false;
            that.isDataEnd = false;
            that.isNoData = false;
            that.page = 1;
            that.list = [];
            that.getCustomerLists();
          })
          .catch(() => {
            that.isSubmiting = false;
          });
      }
    },

    //拉取数据
    fetchData() {
      this.getCustomerLists();
    },

    //获取门店顾问列表
    getUserLists() {
      let that = this;
      let params = {};
      params["orgId"] = that.orgId;
      params["page"] = 1;
      params["size"] = 1000;
      that.$get(that.$api.bizUserLists, params).then((res) => {
        that.employeeList =
          res.data.lists && res.data.lists.length > 0 ? res.data.lists : [];
        if (
          that.siftEmployeeList.length == 1 &&
          res.data.lists &&
          res.data.lists.length > 0
        ) {
          that.siftEmployeeList = that.siftEmployeeList.concat(res.data.lists);
        }
      });
    },

    //获取基础信息
    getCusTag() {
      let that = this;
      let params = {};
      params["orgId"] = that.orgId;
      params["actId"] = that.actId;
      that.$get(that.$api.bizCusTag, params).then((res) => {
        that.info = res.data;
        that.tabId = res.data.tab[0].id;
        that.getCustomerLists();
        if (
          that.siftVisitTab.length == 1 &&
          res.data.visitTab &&
          res.data.visitTab.length > 0
        ) {
          that.siftVisitTab = that.siftVisitTab.concat(res.data.visitTab);
        }
      });
    },

    //tab切换
    changeTab(id) {
      if (this.isSubmiting) return;
      if (id != this.tabId && !this.loading) {
        if (id == 0) {
          this.employeeId = "";
          this.tagId = "";
        }

        if (id == 1) {
          this.tagId = "";
        }

        this.tabId = id;
        this.loading = false;
        this.isDataEnd = false;
        this.isNoData = false;
        this.page = 1;
        this.list = [];
        this.getCustomerLists();
      }
    },

    //选项选择顾问
    changeEmployee(e) {
      if (this.employeeId != e.target.value) {
        this.employeeId = e.target.value;
        this.loading = false;
        this.isDataEnd = false;
        this.isNoData = false;
        this.page = 1;
        this.list = [];
        this.getCustomerLists();
      }
    },

    //选项选择标签
    changeVisitTab(e) {
      if (this.tagId != e.target.value) {
        this.tagId = e.target.value;
        this.loading = false;
        this.isDataEnd = false;
        this.isNoData = false;
        this.page = 1;
        this.list = [];
        this.getCustomerLists();
      }
    },

    //客户列表
    getCustomerLists() {
      let that = this;
      if (
        that.tabId > -1 &&
        !that.isNoData &&
        !that.isDataEnd &&
        !that.loading
      ) {
        that.loading = true;
        let params = {};
        params["orgId"] = that.orgId;
        params["actId"] = that.actId;
        params["tabId"] = that.tabId;
        if (that.employeeId) {
          params["employeeId"] = that.employeeId;
        }
        if (that.tagId) {
          params["tagId"] = that.tagId;
        }
        params["page"] = that.page;
        params["size"] = that.size;
        that.$get(that.$api.bizCustomerLists, params).then((res) => {
          that.loading = false;
          that.page = that.page + 1;
          that.list =
            res.data.lists && res.data.lists.length > 0
              ? that.list.concat(res.data.lists)
              : that.list;
          that.total = res.data.total;
          if (res.data.total == 0) {
            that.isNoData = true;
          } else if (that.list.length == res.data.total) {
            that.isDataEnd = true;
          }
        });
      }
    },
  },
};
</script>
