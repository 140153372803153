<template>
  <div class="scrollContainer font-12" ref="scrollContainer">
    <div
      class="container relative bg-no-repeat bg-size-fullwidth bg-pos-top pb40"
      style="
        background-image: url('https://qs.haodian.cn/web/images/project/H5-ShiYu/mine-bg.jpg');
      "
    >
      <div class="relative height-710">
        <div class="pt90 pl100 pr100 fn-clear">
          <div class="fn-fl font-18">订单号 {{ info.sid }}</div>
          <div class="fn-fr font-18">
            <i class="iconfont mr5 icon-time"></i>{{ info.ctime2 }}
          </div>
        </div>
        <div class="uinfo absolute wp100 pr220 pl100">
          <div class="space-nowrap font-28">
            <span>{{ info.uname }}</span
            ><span>·{{ info.mobile }}</span>
          </div>
          <template v-if="info.ifAddress > 0">
            <div
              class="mt10 uinfoadd font-22 ulib-rl750"
              v-if="info.detail"
              @click="showAdd()"
            >
              <i class="iconfont mr5 icon-dizhi"></i>{{ info.region
              }}{{ info.detail }}
            </div>
            <div
              class="mt10 uinfoadd font-22 ulib-rl750"
              v-else
              @click="showAdd()"
            >
              <i class="iconfont mr5 icon-zengjia"></i>(完善收货地址)
            </div>
          </template>
          <img
            class="absolute box-middle right-0 mr80 imgsize-130X100"
            src="https://qs.haodian.cn/web/images/project/H5-ShiYu/icon-hexiao.png"
            alt="已核销"
            v-if="info.ifUsed"
          />
        </div>
        <div
          class="odslogan absolute box-center text-center color-e4bc93 space-nowrap"
        >
          <div class="pb10 bbs-1-e4bc93 font-48">{{ info.slogan }}</div>
          <div class="mt10 font-24">{{ info.validTime }}</div>
        </div>
        <div
          class="absolute logistics box-center bg-e0b78d pt10 pb10 pl15 pr15 ulib-rtr20 ulib-rbl20 ulib-r750 font-22 color-1a1a1a text-nowrap text-center"
          v-if="info.courierNo"
          @click="getWuliu(info.courierNo)"
        >
          您的快递已发出 快递单号:{{ info.courierNo }}>
        </div>
      </div>
      <div
        class="relative bg-fff mb30 ml30 mr30 inner40 pl150 pr220 ulib-r20 box-shadow-darkGray"
        v-if="info.logo"
      >
        <img
          class="absolute left-0 box-middle ml35 imgsize-90X90 ulib-r750 bds-1-eee"
          :src="info.logo"
          :alt="info.custName"
        />
        <div>
          <span class="font-36 text-middle">{{ info.custName }}</span>
          <span
            class="inline-block pl10 pr10 line-height-15 text-middle ulib-r750 font-22 color-fff bg-1a1a1a"
            >专属客服</span
          >
        </div>
        <div class="mt10 font-22 color-999">{{ info.slogan }}</div>
        <a
          class="absolute right-0 box-middle mr30"
          :href="'tel:' + info.custTel"
        >
          <img
            class="imgsize-60X60"
            src="https://qs.haodian.cn/web/images/project/H5-ShiYu/tel.png"
            alt="拨打电话"
          />
        </a>
      </div>
      <div
        class="relative bg-fff mb30 ml30 mr30 inner40 ulib-r20 box-shadow-darkGray"
      >
        <div class="pb20 text-center">
          <div class="inline-block relative pl10 pr10 introTitle font-36">
            <span class="relative z-index-1">权益说明</span>
          </div>
        </div>
        <div
          class="line-height-17 font-28 color-666 text-break space-pre-line rich-text-img editorDefault"
          v-html="info.content"
        ></div>
      </div>
    </div>
    <div
      class="msg fn-hide"
      :style="isShowAdd ? 'display:block' : 'display:none'"
      v-if="isShowAdd"
    >
      <div class="msgBg" @click="isShowAdd = !isShowAdd"></div>
      <div class="msgMain">
        <div class="content">
          <div class="word text-center">
            <div class="mt15 font-22 color-999">
              请输入您的地址，我们会第一时间为您发出！
            </div>
            <div class="mt30 relative bg-f6 ulib-r10">
              <input
                id="region"
                class="wp100 inner30 font-28 bg-f6 border-none ulib-r10"
                type="text"
                readonly=""
                v-model="region"
                placeholder="选择省/市/区"
              />
              <input id="regionvalue" type="hidden" v-model="regionvalue" />
            </div>
            <div class="mt30 relative bg-f6 ulib-r10">
              <input
                class="wp100 inner30 font-28 bg-f6 border-none ulib-r10"
                type="text"
                v-model="address"
                placeholder="请输入详细地址"
              />
            </div>
          </div>
          <div class="mt30">
            <a
              class="block pt25 pb25 bg-1a1a1a text-center font-32 color-fff ulib-r10"
              @click="putOrderAddress()"
              >确认</a
            >
          </div>
          <div class="mt30 text-center">
            <a class="font-24 text-underline" @click="isShowAdd = !isShowAdd"
              >不了，看完直播再说></a
            >
          </div>
        </div>
      </div>
    </div>

    <!--快递-->
    <div
      class="msg fn-hide"
      :style="msgisShowExpress ? 'display:block' : 'display:none'"
      v-if="msgisShowExpress"
    >
      <div class="msgBg" @click="msgisShowExpress = false"></div>
      <div class="msgMain">
        <div class="content">
          <div class="pt20 pb20 fn-clear">
            <div class="fn-fl font-36">{{ expressInfo.expName }}</div>
            <div class="fn-fr mt10 font-24">
              <span>{{ expressInfo.number }}</span>
              <a
                class="J_copy"
                @click="copy"
                :data-clipboard-text="expressInfo.number"
                >复制</a
              >
            </div>
          </div>
          <div class="pl20 pr20 max-height-500 scroll-y">
            <div class="orderDtail-log mt40 relative">
              <div
                class="orderDtail-log-item pl40 relative"
                v-for="(item, index) in expressInfo.list"
                :key="index"
              >
                <div class="orderDtail-log-content font-22">
                  <div :class="index == 0 ? 'color-ff9d47' : ''">
                    {{ item.time }}
                  </div>
                  <div class="color-999 text-break">{{ item.status }}</div>
                </div>
                <span
                  class="absolute orderDtail-log-line2 z-index-0"
                  v-if="index != expressInfo.list.length - 1"
                ></span>
                <span class="absolute orderDtail-log-dot z-index-1"></span>
              </div>
            </div>
          </div>
          <div class="opt pb30 pl20 pr20">
            <a
              class="bg-1a1a1a ml15 pt20 pb20 pl60 pr60 text-center font-32 color-fff ulib-r750"
              @click="msgisShowExpress = false"
              href="javascript:"
              >知道了</a
            >
          </div>
        </div>
      </div>
    </div>

    <backHome></backHome>
  </div>
</template>
<script>
import Clipboard from "clipboard";
import backHome from "../../components/backHome";
import $ from "jquery";
import { wexinShare } from "../../assets/js/utils/wexinShare.js";
export default {
  components: { backHome },
  data() {
    return {
      skey: this.$route.query.skey,
      orderId: this.$route.query.orderId,
      info: {},
      region: "",
      regionvalue: "",
      address: "",
      isShowAdd: false,
      isSubmiting: false,
      msgisShowExpress: false,
      expressInfo: "",
    };
  },

  created: function () {
    this.getWxSign();
    this.getOrder();

    let LArea1 = document.createElement("script");
    LArea1.type = "text/javascript";
    LArea1.src = "https://qs.haodian.cn/web/javascript/LArea/js/LAreaData1.js";
    document.getElementsByTagName("head")[0].appendChild(LArea1);
    let LArea2 = document.createElement("script");
    LArea2.type = "text/javascript";
    LArea2.src = "https://qs.haodian.cn/web/javascript/LArea/js/LAreaData2.js";
    document.getElementsByTagName("head")[0].appendChild(LArea2);
    let LArea = document.createElement("script");
    LArea.type = "text/javascript";
    LArea.src = "https://qs.haodian.cn/web/javascript/LArea/js/LArea.js";
    document.getElementsByTagName("head")[0].appendChild(LArea);
  },

  mounted: function () {},
  methods: {
    //获取微信签名数据
    getWxSign() {
      let params = {};
      params["skey"] = this.$store.getters.getSkey;
      params["url"] = window.location.href;
      this.$get(this.$api.wxSign, params).then((res) => {
        let obj = {
          appId: res.data.signPackage.appid,
          nonceStr: res.data.signPackage.noncestr,
          signature: res.data.signPackage.signature,
          timestamp: res.data.signPackage.timestamp,
        };

        let shareData = {
          title: res.data.title, // 分享标题
          desc: res.data.desc,
          link:
            "https://" +
            document.domain +
            "/act?skey=" +
            this.$store.getters.getSkey,
          imgUrl: res.data.img, // 分享图标
        };
        //引用
        wexinShare(obj, shareData);
      });
    },

    //复制文字
    copy() {
      let clipboard = new Clipboard(".J_copy");
      clipboard.on("success", () => {
        this.$toast({
          message: "复制成功！",
          duration: 1500,
        });
        // 释放内存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        // 释放内存
        clipboard.destroy();
      });
    },

    //获取订单详情
    getOrder() {
      let params = {};
      params["skey"] = this.skey;
      params["orderId"] = this.orderId;
      this.$get(this.$api.getOrder, params).then((res) => {
        this.info = res.data;
        let date = new Date(res.data.ctime);
        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        if (month < 10) {
          month = "0" + month;
        }
        let date1 = date.getDate();
        if (date1 < 10) {
          date1 = "0" + date1;
        }
        let hour = date.getHours();
        if (hour < 10) {
          hour = "0" + hour;
        }
        let minutes = date.getMinutes();
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        this.info.ctime2 =
          year + "." + month + "." + date1 + " " + hour + ":" + minutes;

        this.region = res.data.region;
        this.address = res.data.detail;
      });
    },

    //获取订单详情
    getWuliu(express) {
      let params = {};
      params["skey"] = this.skey;
      params["express"] = express;
      this.$get(this.$api.getWuliu, params).then((res) => {
        this.msgisShowExpress = true;
        this.expressInfo = res.data;
      });
    },

    showAdd: function () {
      this.isShowAdd = true;
      let that = this;
      //选择省市初始化
      setTimeout(function () {
        let area = new LArea();
        area.init({
          trigger: "#region", //触发选择控件的文本框，同时选择完毕后name属性输出到该位置
          valueTo: "#regionvalue", //选择完毕后id属性输出到该位置
          keys: {
            id: "value",
            name: "text",
          }, //绑定数据源相关字段 id对应valueTo的value属性输出 name对应trigger的value属性输出
          type: 2,
          data: [provs_data, citys_data, dists_data],
        });

        if (that.region != "") {
          for (var i in provs_data) {
            if (provs_data[i].text == that.region.split(",")[0]) {
              var x = i;
              var city = citys_data[provs_data[i].value];
              for (var j in city) {
                if (city[j].text == that.region.split(",")[1]) {
                  var y = j;
                  var district = dists_data[city[j].value];
                  for (var k in district) {
                    if (district[k].text == that.region.split(",")[2]) {
                      var z = k;
                    }
                  }
                }
              }
            }
          }
          area.value = [x, y, z]; //控制初始位置，注意：该方法并不会影响到input的value
        } else {
          //area.value=[12,1,1];//控制初始位置，注意：该方法并不会影响到input的value
        }
        area.success = function () {
          that.regionvalue = $("#regionvalue").val();
        };
      }, 200);
    },

    //提交地址
    putOrderAddress() {
      if (this.isSubmiting) return;
      if (!this.region) {
        this.$toast({
          message: "请选择所在地区",
          duration: 1500,
        });
      } else if (!this.address) {
        this.$toast({
          message: "请输入详细地址",
          duration: 1500,
        });
      } else {
        let that = this;
        that.isSubmiting = true;
        let params = {};
        params["skey"] = that.skey;
        params["orderId"] = Number(that.orderId);
        params["region"] = this.region;
        params["address"] = this.address;
        that
          .$put(that.$api.putOrderAddress, params)
          .then(() => {
            that.isSubmiting = false;
            that.$toast({
              message: "提交成功",
              duration: 1500,
            });
            that.isShowAdd = false;
            that.getOrder();
          })
          .catch(() => {
            that.isSubmiting = false;
          });
      }
    },
  },
};
</script>
