<template>
  <div class="scrollContainer font-12" ref="scrollContainer">
    <div class="container relative">
      <div
        class="relative bg-fff mt30 mb30 ml30 mr30 inner40 ulib-r20 box-shadow-darkGray"
      >
        <div class="mt10 pb20 text-center">
          <div class="inline-block relative pl10 pr10 introTitle font-36">
            <span class="relative z-index-1">{{ info.protocolTitle }}</span>
          </div>
        </div>
        <div
          class="line-height-20 font-28 color-666 text-break rich-text-img"
          v-html="info.protocolContent"
        ></div>
      </div>
    </div>

    <backHome></backHome>
  </div>
</template>
<script>
import { hiddenShare } from "../../assets/js/utils/wexinShare.js";
import backHome from "../../components/backHome";
export default {
  components: { backHome },
  data() {
    return {
      skey: this.$route.query.skey,
      info: {},
    };
  },

  created: function () {
    this.getItem();
    this.getHiddenWxSign();
  },

  mounted: function () {},
  methods: {
    //获取微信签名数据
    getHiddenWxSign() {
      let params = {};
      params["url"] = window.location.href;
      this.$get(this.$api.wxSign, params).then((res) => {
        let obj = {
          appId: res.data.signPackage.appid,
          nonceStr: res.data.signPackage.noncestr,
          signature: res.data.signPackage.signature,
          timestamp: res.data.signPackage.timestamp,
        };
        hiddenShare(obj);
      });
    },

    //获取商品详情
    getItem() {
      let params = {};
      params["skey"] = this.skey;
      this.$get(this.$api.item, params).then((res) => {
        this.info = res.data;
      });
    },
  },
};
</script>
