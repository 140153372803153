<template>
  <div class="scrollContainer font-12" ref="scrollContainer">
    <div
      class="container relative bg-no-repeat bg-size-fullwidth bg-pos-top pt30 pb50"
    >
      <div
        class="relative bg-fff ml30 mr30 inner30 ulib-r20 box-shadow-darkGray"
        style="min-height: 90vh"
      >
        <div class="pt10 pb20 fn-clear text-center">
          <b class="font-40 text-italic" v-html="panelTitle"></b>
        </div>
        <div class="fn-flex pb20">
          <select
            class="fn-flex-item select-more box-shadow-lightGray ulib-r10 font-22 mr20"
            @change="handleChange($event, 'group')"
          >
            <option
              :value="index"
              v-for="(item, index) in filters.typeLists"
              :key="index"
              :selected="groupIndex == index"
            >
              {{ item.title }}
            </option>
          </select>
          <select
            class="fn-flex-item select-more box-shadow-lightGray ulib-r10 font-22"
            @change="handleChange($event, 'date')"
          >
            <option
              :value="index"
              v-for="(item, index) in filters.dayLists2"
              :key="index"
              :selected="dateIndex == index"
            >
              {{ item }}
            </option>
          </select>
          <select
            class="fn-flex-item select-more box-shadow-lightGray ulib-r10 font-22 ml20"
            @change="handleChange($event, 'goods')"
            v-if="kpi == 'allOrder'"
          >
            <option
              :value="index"
              v-for="(item, index) in goodsList"
              :key="index"
              :selected="goodsIndex == index"
            >
              {{ item.title }}
            </option>
          </select>
        </div>
        <div
          class="font-32 color-666"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="inner20 fn-clear ulib-r20">
            <div class="fn-fl block wp70 text-nowrap">
              <img
                class="imgsize-42X42 text-middle"
                v-if="index < 3"
                :src="
                  'https://qs.haodian.cn/web/images/project/H5-ShiYu/icon-ranking-' +
                  parseInt(index + 1) +
                  '.png'
                "
                alt="#"
              />
              <div
                class="inline-block relative imgsize-32X32 ml5 mr5 mb5 bg-ccc text-middle ulib-r750 font-0 line-height-11"
                v-else
              >
                <span
                  class="absolute box-center-middle text-bold font-18 color-fff"
                  >{{ index + 1 }}</span
                >
              </div>
              <span class="text-middle">{{ item.title }}</span>
              <!-- <span
                    class="inline-block pl10 pr10 line-height-15 text-middle bg-fe9538 font-22 color-fff ulib-r750"
                    v-if="item.tip"
                    >{{ item.tip }}</span
                  > -->
              <!-- <i
                class="iconfont icon-gengduo text-middle font-24 color-666"
              ></i> -->
            </div>
            <div class="fn-fr">{{ item.num }}</div>
          </div>
        </div>
        <mugenscroll
          :handler="fetchData"
          :should-handle="!loading"
          scroll-container="scrollContainer"
        >
          <div class="pt100 pb100 text-center color-ccc" v-if="isNoData">
            <span class="text-middle font-22">暂无数据</span>
          </div>
          <div class="pt20 pb20 text-center color-ccc" v-else-if="loading">
            <i class="iconfont icon-jiazai text-middle"></i
            ><span class="text-middle font-22">请稍等...</span>
          </div>
          <div
            class="pt20 pb20 text-center font-22 color-ccc"
            v-else-if="isDataEnd && list.length > 10"
          >
            我们是有底线的
          </div>
        </mugenscroll>
      </div>
    </div>
    <backHome></backHome>
  </div>
</template>
<script>
import backHome from "../../components/backHome";
import mugenscroll from "vue-mugen-scroll";
import { hiddenShare } from "../../assets/js/utils/wexinShare.js";
export default {
  components: { mugenscroll, backHome },
  data() {
    return {
      panelTitle: this.$route.query.panelTitle,
      orgId: this.$route.query.orgId,
      actId: this.$route.query.actId,
      kpi: this.$route.query.kpi,
      type: this.$route.query.type,
      itemId: this.$route.query.itemId,
      typeValue: this.$route.query.typeValue ? this.$route.query.typeValue : "",
      day: this.$route.query.day
        ? decodeURIComponent(this.$route.query.day)
        : "",

      loading: false,
      isDataEnd: false,
      isNoData: false,
      page: 1,
      size: 20,
      list: [],

      filters: "",
      goodsList: [],
      dateIndex: 0,
      groupIndex: 0,
      goodsIndex: 0,
    };
  },

  created: function () {
    this.getHiddenWxSign();
    this.getStaticLists();
    this.getStaticFilters();
    if (this.kpi == "allOrder") {
      this.getItemLists();
    }
  },

  mounted: function () {},
  methods: {
    //获取微信签名数据
    getHiddenWxSign() {
      let params = {};
      params["url"] = window.location.href;
      this.$get(this.$api.wxSign, params).then((res) => {
        let obj = {
          appId: res.data.signPackage.appid,
          nonceStr: res.data.signPackage.noncestr,
          signature: res.data.signPackage.signature,
          timestamp: res.data.signPackage.timestamp,
        };
        hiddenShare(obj);
      });
    },

    //拉取数据
    fetchData: function () {
      this.getStaticLists();
    },

    //获取统计筛选条件
    getStaticFilters() {
      let params = {};
      params["orgId"] = this.orgId;
      params["actId"] = this.actId;
      this.$get(this.$api.bizStaticFilters, params).then((res) => {
        let dayLists2 = ["日期"];
        res.data.dayLists.forEach((item) => {
          let date = new Date(item);
          let year = date.getFullYear();
          let month = date.getMonth() + 1;
          if (month < 10) {
            month = "0" + month;
          }
          let date1 = date.getDate();
          if (date1 < 10) {
            date1 = "0" + date1;
          }
          dayLists2.push(year + "-" + month + "-" + date1);
        });

        this.filters = res.data;
        this.filters.dayLists2 = dayLists2;

        if (this.type && res.data.typeLists && res.data.typeLists.length > 0) {
          res.data.typeLists.forEach((item, index) => {
            if (this.type == item.type) {
              this.groupIndex = index;
            }
          });
        }

        if (this.day && res.data.dayLists && res.data.dayLists.length > 0) {
          res.data.dayLists.forEach((item, index) => {
            if (this.day == item) {
              this.dateIndex = index + 1;
            }
          });
        }
      });
    },

    //获取商品列表
    getItemLists() {
      let params = {};
      params["actId"] = this.actId;
      params["page"] = 1;
      params["size"] = 100;
      this.$get(this.$api.itemLists, params).then((res) => {
        this.goodsList =
          res.data.lists && res.data.lists.length > 0 ? res.data.lists : [];
        if (this.itemId && res.data.lists && res.data.lists.length > 0) {
          res.data.lists.forEach((item, index) => {
            if (this.itemId == item.itemId) {
              this.goodsIndex = index;
            }
          });
        }
      });
    },

    //获取统计排行榜
    getStaticLists() {
      let that = this;
      if (!that.isNoData && !that.isDataEnd && !that.loading) {
        that.loading = true;
        let params = {};
        params["orgId"] = that.orgId;
        params["actId"] = that.actId;
        params["kpi"] = that.kpi;
        params["type"] = that.type;
        if (that.typeValue) {
          params["typeValue"] = that.typeValue;
        }
        if (that.kpi == "allOrder") {
          params["itemId"] = that.itemId;
        }
        if (that.day) {
          params["day"] = that.day;
        }
        params["page"] = that.page;
        params["size"] = that.size;
        that.$get(that.$api.bizStaticLists, params).then((res) => {
          that.loading = false;
          that.page = that.page + 1;
          that.list =
            res.data.lists && res.data.lists.length > 0
              ? that.list.concat(res.data.lists)
              : that.list;
          if (res.data.total == 0) {
            that.isNoData = true;
          } else if (that.list.length == res.data.total) {
            that.isDataEnd = true;
          }
        });
      }
    },

    //选择条件
    handleChange(e, key) {
      switch (key) {
        case "date":
          if (this.dateIndex != e.target.value) {
            this.dateIndex = e.target.value;
            if (e.target.value > 0) {
              this.day = this.filters.dayLists[e.target.value - 1];
            } else {
              this.day = "";
            }
            this.loading = false;
            this.isDataEnd = false;
            this.isNoData = false;
            this.page = 1;
            this.list = [];
            this.getStaticLists();
          }
          break;
        case "group":
          if (this.groupIndex != e.target.value) {
            this.groupIndex = e.target.value;
            this.type = this.filters.typeLists[e.target.value].type;
            if (this.filters.typeLists[e.target.value].value) {
              this.typeValue = this.filters.typeLists[e.target.value].value;
            } else {
              this.typeValue = "";
            }
            this.loading = false;
            this.isDataEnd = false;
            this.isNoData = false;
            this.page = 1;
            this.list = [];
            this.getStaticLists();
          }
          break;
        case "goods":
          if (this.goodsIndex != e.target.value) {
            this.goodsIndex = e.target.value;
            this.itemId = this.goodsList[e.target.value].itemId;
            this.loading = false;
            this.isDataEnd = false;
            this.isNoData = false;
            this.page = 1;
            this.list = [];
            this.getStaticLists();
          }
          break;
      }
    },
  },
};
</script>
