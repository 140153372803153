<template>
  <div class="scrollContainer font-12" ref="scrollContainer">
    <div>
      <div class="height-80"></div>
      <div
        class="fixed top-0 left-0 right-0 z-index-10 bg-f6 pl30 pr30 pt20 pb20 fn-clear"
      >
        <div class="fn-fl relative height-70 space-nowrap text-center">
          <div
            class="inline-block relative tab-menu2 pt10 pb10 pl25 pr25"
            :class="[iswin == item.id ? 'font-36 active' : 'font-28']"
            v-for="(item, index) in tab"
            :key="index"
          >
            <a
              class="relative inline-block"
              :class="[iswin == item.id ? '' : 'pt10']"
              @click="changeTab(item.id)"
              href="javascript:"
              >{{ item.title }}</a
            >
            <div
              class="absolute top-0 right-0 mt10 pl5 pr5 bg-ff0000 font-22 color-fff ulib-r750"
            >
              {{ item.num }}
            </div>
          </div>
        </div>
        <select
          class="fn-fr mt5 height-60 select-more2 ulib-r10 font-28"
          @change="handleChange($event, 'goods')"
        >
          <option
            :value="index"
            v-for="(item, index) in goodsList"
            :key="index"
            :selected="goodsIndex == index"
          >
            {{ item.title }}
          </option>
        </select>
      </div>
    </div>
    <div class="container pb130">
      <div class="inner30 pt0">
        <div
          class="block mt30 pl30 pr30 pb30 bg-fff ulib-r20"
          :class="item.success ? 'op70' : ''"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="bbs-1-eee pt20 pb20">
            <div>
              <img
                class="text-middle imgsize-35X35 ulib-r750 bds-4-fff"
                :src="item.headimg"
                alt="#"
              />
              <span class="text-middle font-22 color-999">{{
                item.nickname
              }}</span>
              <span class="text-middle font-22 color-999">·</span>
              <span class="text-middle font-22">{{ item.customerName }}</span>
            </div>
            <div class="mt10 fn-clear font-22 color-999">
              <a :href="'tel:' + item.tel" class="fn-fl"
                ><i class="iconfont icon-shouji"></i>{{ item.tel }}</a
              >
              <div class="fn-fr wp70 text-nowrap" v-if="item.address">
                <i class="iconfont icon-dizhi1"></i>{{ item.address }}
              </div>
            </div>
          </div>
          <div class="relative mt20 fn-clear">
            <img class="fn-fl imgsize-180X90 ulib-r5" :src="item.img" alt="#" />
            <div class="pl200">
              <div class="text-nowrap font-32 line-height-13">
                <template v-if="item.grade">
                  <span class="text-middle color-fe9538">{{ item.grade }}</span>
                  <span class="text-middle">·</span>
                </template>
                <span class="text-middle">{{ item.name }}</span>
              </div>
              <div class="mt15 font-22 color-999">{{ item.time }}</div>
            </div>
            <i
              class="absolute right-0 box-middle iconfont icon-yishiyong font-130 color-aaa"
              v-if="item.success"
            ></i>
          </div>
          <div
            class="bg-eee mt20 pt10 pb10 pl15 pr15 ulib-rtr20 ulib-rbl20 ulib-rbr20 font-22 color-666"
            v-if="item.success"
          >
            {{ item.success }}
          </div>
          <div
            class="bg-fcecde mt20 pt10 pb10 pl15 pr15 ulib-rtr20 ulib-rbl20 ulib-rbr20 font-22 color-fe9538"
            v-if="item.fail"
          >
            {{ item.fail }}
          </div>
        </div>
        <mugenscroll
          :handler="fetchData"
          :should-handle="!loading"
          scroll-container="scrollContainer"
        >
          <div class="pt100 pb100 text-center color-ccc" v-if="isNoData">
            <span class="text-middle font-22">暂无数据</span>
          </div>
          <div class="pt20 pb20 text-center color-ccc" v-else-if="loading">
            <i class="iconfont icon-jiazai text-middle"></i
            ><span class="text-middle font-22">请稍等...</span>
          </div>
          <div
            class="pt20 pb20 text-center font-22 color-ccc"
            v-else-if="isDataEnd && list.length > 5"
          >
            我们是有底线的
          </div>
        </mugenscroll>
      </div>
    </div>

    <navBar name="win" :orgId="orgId" :actId="actId"></navBar>
  </div>
</template>
<script>
import mugenscroll from "vue-mugen-scroll";
import navBar from "../../components/navBar";
import { hiddenShare } from "../../assets/js/utils/wexinShare.js";
import $ from "jquery";
export default {
  components: { mugenscroll, navBar },
  data() {
    return {
      orgId: this.$route.query.orgId,
      actId: this.$route.query.actId,
      tab: [
        {
          id: "",
          title: "订单列表",
          num: "",
        },
        {
          id: "1",
          title: "中奖列表",
          num: "",
        },
      ],
      iswin: "",
      itemId: "",
      goodsList: [],
      goodsIndex: 0,
      ActivityId: "0",
      loading: false,
      isDataEnd: false,
      isNoData: false,
      page: 1, //页数
      size: 20, //每页取多少个数据
      list: [],
    };
  },

  created: function () {
    this.getHiddenWxSign();
    this.getItemLists();
    this.getOrderLists();
  },

  mounted: function () {},
  methods: {
    //获取微信签名数据
    getHiddenWxSign() {
      let params = {};
      params["url"] = window.location.href;
      this.$get(this.$api.wxSign, params).then((res) => {
        let obj = {
          appId: res.data.signPackage.appid,
          nonceStr: res.data.signPackage.noncestr,
          signature: res.data.signPackage.signature,
          timestamp: res.data.signPackage.timestamp,
        };
        hiddenShare(obj);
      });
    },

    //拉取数据
    fetchData: function () {
      this.getOrderLists();
    },

    //tab切换
    changeTab(id) {
      if (id != this.iswin && !this.loading) {
        $(".scrollContainer").animate({ scrollTop: 0 }, 500);
        this.iswin = id;
        this.loading = false;
        this.isDataEnd = false;
        this.isNoData = false;
        this.page = 1;
        this.list = [];
        this.getOrderLists();
      }
    },

    //获取订单列表
    getOrderLists() {
      let that = this;
      if (!that.isNoData && !that.isDataEnd && !that.loading) {
        that.loading = true;
        let params = {};
        params["orgId"] = that.orgId;
        params["actId"] = that.actId;
        if (that.iswin) {
          params["iswin"] = that.iswin;
        }
        if (that.itemId) {
          params["itemId"] = that.itemId;
        }
        params["page"] = that.page;
        params["size"] = that.size;
        that.$get(that.$api.bizOrderLists, params).then((res) => {
          that.loading = false;
          that.page = that.page + 1;
          that.list =
            res.data.lists && res.data.lists.length > 0
              ? that.list.concat(res.data.lists)
              : that.list;
          if (res.data.total == 0) {
            that.isNoData = true;
          } else if (that.list.length == res.data.total) {
            that.isDataEnd = true;
          }
        });
      }
    },

    //获取商品列表
    getItemLists() {
      let params = {};
      params["actId"] = this.actId;
      params["page"] = 1;
      params["size"] = 100;
      this.$get(this.$api.itemLists, params).then((res) => {
        let goodsList = [
          {
            itemId: "",
            title: "全部",
          },
        ];
        this.goodsList =
          res.data.lists && res.data.lists.length > 0
            ? goodsList.concat(res.data.lists)
            : goodsList;
      });
    },

    //选择条件
    handleChange(e, key) {
      switch (key) {
        case "goods":
          if (this.goodsIndex != e.target.value) {
            $(".scrollContainer").animate({ scrollTop: 0 }, 500);
            this.goodsIndex = e.target.value;
            this.itemId = this.goodsList[e.target.value].itemId;
            this.loading = false;
            this.isDataEnd = false;
            this.isNoData = false;
            this.page = 1;
            this.list = [];
            this.getOrderLists();
          }
          break;
      }
    },
  },
};
</script>
